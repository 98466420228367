import {
  addListener,
  createCookie,
  defer,
  isVariableDefinedNotNull,
  now,
  readCookie,
  removeListener,
  urlParam,
} from '@slideslive/fuse-kit/utils';

import Bookmarks from './bookmarks';
import EmbedInterface from './embed_interface';
import Keyboard from './keyboard';
import LivePlaybackController from './live_playback_controller';
import LivestreamInfoProvider from './livestream_info_provider';
import log from './log';
import PlaybackController from './playback_controller';
import PlayerAnalyticsV2 from './player_analytics_v2';
import PlayerOptions from './player_options';
import Playlists from './playlists';
import PresentationPlaylistLoader from './presentation_playlist_loader';
import PresentationReview from './presentation_review';
import SlidesPlayer from './slides/slides_player';
import UpdateTimer from './update_timer';
import VideoPlayer from './video/video_player';
import VideoKen from './video_ken/video_ken';
import View from './view/view';

export default class Player {
  constructor(id, options = {}) {
    this.element = document.getElementById(id);

    if (!isVariableDefinedNotNull(this.element)) {
      console.warn('PLAYER', 'Player element not found.');
      return;
    }

    this.options = new PlayerOptions(this.element, options);

    this.updateTimer = new UpdateTimer({
      callback: () => this.update(),
      highResCallback: () => this.highResUpdate(),
    });

    this.callbacks = {
      load: new Set(),
      ready: new Set(),
      error: new Set(),
      notRecorded: new Set(),
      play: new Set(),
      pause: new Set(),
      resize: new Set(),
      controlsShow: new Set(),
      controlsHide: new Set(),
      currentSlideChanged: new Set(),
      timeChanged: new Set(),
      togglePlaylist: new Set(),
      toggleBookmark: new Set(),
    };

    this.initProps();
    this.createView();
    this.createEmbedInterface();

    this.view.embed = this.embedInterface.enabled;
    this.embedInterface.sendUpdateSizeRequest();

    if (this.options.autoLoad) {
      defer(() => this.loadWhenVisible());
    } else {
      defer(() => this.initClickToLoad());
    }

    const debugSlidesLivePlayer = urlParam('debug_slideslive_player');
    if (debugSlidesLivePlayer && debugSlidesLivePlayer !== 'false') {
      if (!isVariableDefinedNotNull(window.__debugSlidesLivePlayer)) {
        window.__debugSlidesLivePlayer = [];
      }

      window.__debugSlidesLivePlayer.push(this);
    }
  }

  initProps() {
    this.props = {
      createdAt: now(),

      initialLoadingStartedAt: null,
      presentationInfoReceivedAt: null,
      videoReadyAt: null,
      slidesReadyAt: null,
      readyAt: null,

      presentationMediaSetId: null,
      live: false,
      offline: false,
      guest: true,
      liveFinished: false,
      firstPlay: true,
      playingBeforeSeeking: false,
      seeking: false,
      presentationUpdatedAt: 0,
      nextPresentationLink: null,
      videoKenEnabled: false,
      playlistsLoaded: false,
      startTimeFromServer: null,
      forcedRealVideoRatio: null,
      forcedDisplayVideoRatio: null,

      visibilityChangeListenerId: null,
    };
  }

  createView() {
    this.view = new View(
      this.element,
      {
        locale: this.options.locale,
        maxHeight: this.options.maxHeight,
        maxWidth: this.options.maxWidth,
        fitToViewport: this.options.fitToViewport,
        disableFullscreen: this.options.disableFullscreen,
        hideZoomControls: this.options.hideZoomControls,
        zoomRatio: this.options.zoomRatio,
        zoomHandleColor: this.options.zoomHandleColor,
        zoomSliderColor: this.options.zoomSliderColor,
        showThumbnail: this.options.showThumbnail,
        verticalEnabled: this.options.verticalEnabled,
        verticalEnabledOnMobile: this.options.verticalEnabledOnMobile,
        verticalDisabledWhenWidthGte: this.options.verticalDisabledWhenWidthGte,
        verticalWhenWidthLte: this.options.verticalWhenWidthLte,
        hideBigPlayButton: this.options.hideBigPlayButton,
        allowHiddenControlsWhenPaused: this.options.allowHiddenControlsWhenPaused,
        originalVideoControls: this.options.originalVideoControls,
        mode: this.options.mode,
      },
      {
        clickToLoad: () => this.loadWhenVisible(),
        togglePlayback: () => this.togglePlayback(),
        toggleMute: () => this.toggleMute(),
        toggleSlideMute: () => this.toggleSlideMute(),
        seekToLivePosition: () => this.seekToLivePosition(),
        startSeek: (time) => this.startSeek(time),
        updateSeek: (time) => this.updateSeek(time),
        endSeek: (time) => this.endSeek(time),
        seekTo: (time) => this.seekTo(time),
        setVolume: (value) => (this.volume = value),
        setPlaybackRate: (rate) => (this.playbackRate = rate),
        setVideoQuality: (quality) => (this.videoQuality = quality),
        setPlaybackServer: (server) => (this.playbackServer = server),
        setLiveSlidesVideoQuality: (quality) => (this.liveSlidesVideoQuality = quality),
        setLiveSlidesPlaybackServer: (server) => (this.liveSlidesPlaybackServer = server),
        setSubtitleTrack: (track) => (this.subtitleTrack = track),
        prevSlide: () => this.prevSlide(),
        nextSlide: () => this.nextSlide(),
        syncVideoToSlides: () => this.syncVideoToSlides(),
        syncSlidesToVideo: () => this.syncSlidesToVideo(),
        toggleFullscreen: () => this.toggleFullscreen(),
        progressImageUrl: (time) => {
          if (this.slidesPlayer) {
            return this.slidesPlayer.progressImageUrl(time);
          }

          return null;
        },
        sizeChanged: (size) => {
          if (this.slidesPlayer) {
            this.slidesPlayer.size = size;
          }

          if (this.slidesVideoStreamPlayer) {
            if (size.slidesWidth) {
              this.slidesVideoStreamPlayer.size = {
                w: size.slidesWidth,
                h: size.slidesHeight,
              };
            } else {
              this.slidesVideoStreamPlayer.size = {
                w: 0,
                h: 0,
              };
            }
          }

          if (this.videoPlayer) {
            if (size.videoElementWidth) {
              this.videoPlayer.size = {
                w: size.videoElementWidth,
                h: size.videoElementHeight,
              };
            } else {
              this.videoPlayer.size = {
                w: 0,
                h: 0,
              };
            }
          }

          this.runCallbacks('resize', size);
        },
        playNextPresentation: () => {
          this.analyticsV2.trackPlayNextPresentationRequest(this.props.nextPresentationLink);
          window.location.href = this.props.nextPresentationLink;
        },
        controlsVisibilityChanged: (visible) => {
          if (visible) {
            this.runCallbacks('controlsShow');
          } else {
            this.runCallbacks('controlsHide');
          }
        },
        togglePlaylist: this.togglePlaylist.bind(this),
        addPlaylist: (data) => {
          if (!isVariableDefinedNotNull(this.playlists)) {
            return;
          }

          this.analyticsV2.trackAddPlaylistRequest();
          this.playlists.addPlaylist(data);
        },
        addBookmark: () => {
          if (!isVariableDefinedNotNull(this.bookmarks)) {
            return;
          }

          this.analyticsV2.trackAddBookmarkRequest();
          this.bookmarks.add(this.currentTime);
        },
        updateBookmark: (data) => {
          if (!isVariableDefinedNotNull(this.bookmarks)) {
            return;
          }

          this.analyticsV2.trackUpdateBookmarkRequest();
          this.bookmarks.update(data);
        },
        removeBookmark: (id) => {
          if (!isVariableDefinedNotNull(this.bookmarks)) {
            return;
          }

          this.analyticsV2.trackRemoveBookmarkRequest();
          this.bookmarks.remove(id);
        },
        showTopicsAndTranscript: () => {
          if (this.videoKen) {
            this.videoKen.load(() => {
              this.view.toggleTopicsAndTranscriptLoading(false);
            });
          }

          this.analyticsV2.trackShowTopicsAndTranscriptsRequest();
          this.view.toggleTopicsAndTranscriptLoading(true);
        },
        openReviewNote: (reviewNoteId) => {
          this.analyticsV2.trackOpenReviewNoteRequest();

          const reviewNote = this.presentationReview.findNote(reviewNoteId);
          this.view.openReviewNote(reviewNote);
          this.seekTo(Math.max(0, reviewNote.time_ms - 3000));
        },
        resolveReviewNote: (data) => {
          this.analyticsV2.trackResolveReviewNoteRequest();

          this.presentationReview.resolveNote(data.reviewNoteId, data.commentText);
        },
        sendReviewNoteComment: (data) => {
          this.analyticsV2.trackSendReviewNoteCommentRequest();

          this.presentationReview.commentNote(data.reviewNoteId, data.commentText);
        },
        zoomRatioChanged: (ratio) => {
          if (this.analyticsV2) {
            this.analyticsV2.trackZoomRatioChange(ratio);
          }

          this.embedInterface.sendZoomRatioChanged(ratio);
        },
        syncSlidesVideoStream: () => this.syncSlidesVideoStream(),
      },
    );
  }

  createHotkeys() {
    this.keyboard = new Keyboard(this.element, {
      togglePlayback: () => this.togglePlayback(),
      toggleMute: () => this.toggleMute(),
      prevSlide: () => this.prevSlide(),
      nextSlide: () => this.nextSlide(),
      syncVideoToSlides: () => this.syncVideoToSlides(),
      syncSlidesToVideo: () => this.syncSlidesToVideo(),
      toggleFullscreen: () => this.toggleFullscreen(),
      skipBack: () => this.skipBack(),
      skipForward: () => this.skipForward(),
      addBookmark: () => {
        if (!isVariableDefinedNotNull(this.bookmarks)) {
          return;
        }

        this.analyticsV2.trackAddBookmarkRequest();
        this.bookmarks.add(this.currentTime);
      },
      hideOpenedOverlays: () => this.view.hideOpenedOverlays(),
    });
  }

  initPlaylists() {
    this.playlists = new Playlists(
      { presentationId: this.options.presentationId },
      {
        updatePlaylistsList: (list, presentationPlaylists) =>
          this.view.updatePlaylistsList(list, presentationPlaylists),
        afterAction: (name, added, onlyFrontend = false) => {
          this.view.updatePlaylist(name, added);

          if (!onlyFrontend) {
            this.runCallbacks('togglePlaylist', name, added);
          }
        },
      },
    );
  }

  initBookmarks() {
    this.bookmarks = new Bookmarks(
      { presentationId: this.options.presentationId },
      {
        updateBookmarksList: (list) => this.view.updateBookmarksList(list),
        afterAdded: (id) => {
          this.view.showBookmarksPopup(id);
          this.runCallbacks('toggleBookmark', true);
        },
        afterUpdate: (data, list) => this.view.updateBookmark(data, list),
        afterRemove: (id, list) => {
          this.view.removeBookmark(id, list);
          this.runCallbacks('toggleBookmark', false);
        },
      },
    );
  }

  initPresentationReview({ mediaSetId, videoVersion, slidesVersion }) {
    this.presentationReview = new PresentationReview(
      {
        presentationId: this.options.presentationId,
        mediaSetId,
        videoVersion,
        slidesVersion,
        reviewNotesUrl: this.options.presentationReviewNotesUrl,
        resolveReviewNoteUrl: this.options.resolvePresentationReviewNoteUrl,
        commentReviewNoteUrl: this.options.commentPresentationReviewNoteUrl,
      },
      {
        notesChanged: (notes) => this.view.updateReviewNotes(notes),
      },
    );
  }

  initClickToLoad() {
    this.view.renderClickToLoad();

    if (this.options.thumbnailUrl) {
      this.view.clickToLoadThumbnail = this.options.thumbnailUrl;
    }
  }

  loadWhenVisible() {
    this.view.renderPlayer();
    this.loading = true;

    const callLoad = () => {
      if (typeof requestAnimationFrame === 'function') {
        requestAnimationFrame(() => this.load());
      } else {
        console.warn('SlidesLive Player loading using defer. requestAnimationFrame is not supported.');

        defer(() => this.load());
      }
    };

    if (isVariableDefinedNotNull(document.hidden) && document.hidden) {
      // eslint-disable-next-line no-console
      console.log('SlidesLive Player is hidden. Waiting for visibility change.');

      let loaded = false;
      this.props.visibilityChangeListenerId = addListener(document, 'visibilitychange', () => {
        removeListener(document, { id: this.props.visibilityChangeListenerId });
        this.props.visibilityChangeListenerId = null;

        if (loaded) return;
        loaded = true;

        const visible = document.visibilityState === 'visible';
        if (visible) {
          // eslint-disable-next-line no-console
          console.log('SlidesLive Player is now visible. Loading.');
          callLoad();
        }
      });
    } else {
      callLoad();
    }
  }

  load() {
    this.props.initialLoadingStartedAt = now();

    this.createHotkeys();
    this.createAnalyticsV2();

    this.embedInterface.sendBeforeLoad();

    this.view.firstPlay = this.props.firstPlay;
    this.loading = true;
    this.guest = !(
      isVariableDefinedNotNull(window.gon) &&
      isVariableDefinedNotNull(window.gon.user_email) &&
      isVariableDefinedNotNull(window.gon.user_name)
    );

    if (this.options.offline) {
      this.loadOffline();
    } else {
      this.analyticsV2.createSession();

      this.presentationPlaylistLoader = new PresentationPlaylistLoader({
        presentationId: this.options.presentationId,
        registrationId: this.options.registrationId,
        reviewToken: this.options.reviewToken,
        benBaseUrl: this.options.benBaseUrl,
        cdnOverride: this.options.cdnOverride,
        countryOverride: this.options.countryOverride,
        playerToken: this.options.playerToken,
        m3u8: this.options.m3u8,
      });

      this.presentationPlaylistLoader.loadPresentationInfo({
        fatalError: (error) => this.showError(error),
        retriableError: (error) => this.showError(error),
        presentationInfo: (info) => this.processPresentationInfo(info),
      });
    }

    if (!document.fullscreenEnabled) {
      console.warn(
        'SlidesLive player fullscreen mode is not available. If SlidesLive is embedded check iframe allowfullscreen attribute and fullscreen Feature-Policy on the parent page. For more information go to https://developer.mozilla.org/en-US/docs/Web/API/Document/fullscreenEnabled and https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Feature-Policy/fullscreen.',
      );

      this.view.disableFullscreen();
    }
  }

  loadOffline() {
    log('PLAYER', 'load offline');

    this.offline = true;

    const slides = this.element.getAttribute('data-slides');
    const video = this.element.getAttribute('data-video-path');

    this.mode = this.options.mode;

    if (isVariableDefinedNotNull(this.options.thumbnailUrl) && this.options.thumbnailUrl !== '') {
      this.view.thumbnail = this.options.thumbnailUrl;
    }

    this.playbackController = new PlaybackController({
      loadingChanged: (loading) => {
        this.view.videoLoading = loading;
      },
      stateChanged: (state) => this.updateState(state),
    });

    if (this.mode === 'video_slideshow' || this.mode === 'audio_slideshow' || this.mode === 'video') {
      this.createVideoPlayer({ presentationMediaSetId: 'offline', cmcdEnabled: false });
    }

    if (this.mode === 'video_slideshow' || this.mode === 'audio_slideshow' || this.mode === 'slideshow') {
      this.createSlidesPlayer({ presentationMediaSetId: 'offline' });
    }

    if (this.videoPlayer) {
      this.videoPlayer.loadOffline(video);
    }

    if (!this.slidesPlayer) {
      return;
    }

    this.slidesPlayer.loadOffline(slides);
  }

  processPresentationInfo(info) {
    this.props.presentationInfoReceivedAt = now();

    this.view.updateDebugInfo({ title: info.title });

    this.props.forcedRealVideoRatio = info.video_ratio || null;
    this.props.forcedDisplayVideoRatio = info.display_video_ratio || null;

    this.view.title = info.title;
    this.view.link = `https://slideslive.com/${this.options.presentationId}`;
    this.view.hideTitle = this.options.hideTitle;
    this.view.linkifyTitle = this.options.linkifyTitle;
    this.view.slidesLiveLogoVisible = info.slideslive_logo_visible;
    this.view.linkifySlidesLiveLogo = info.slideslive_logo_linkify;

    if (!this.props.forcedRealVideoRatio && !this.props.forcedDisplayVideoRatio) {
      this.view.realVideoRatio = info.video_aspect_ratio;
      this.view.displayVideoRatio = null;
    } else {
      this.view.realVideoRatio = this.props.forcedRealVideoRatio;
      this.view.displayVideoRatio = this.props.forcedDisplayVideoRatio;
    }

    if (isVariableDefinedNotNull(info.next_presentation)) {
      this.setNextPresentation(info.next_presentation);
    }

    this.cmcdEnabled = info.cmcd_enabled;
    this.live = info.is_live;
    this.presentationUpdatedAt = info.updated_at;
    this.props.startTimeFromServer = info.player_start_time;
    this.props.videoServiceName = info.video_service_name;

    if (isVariableDefinedNotNull(info.thumbnail)) {
      this.view.thumbnail = info.thumbnail;
    }

    this.analyticsV2.trackLoad(this.options.data);

    if (this.live) {
      this.loadLive(info);
    } else {
      this.loadOnDemand(info);
    }
  }

  setNextPresentation(nextPresentationData) {
    if (!isVariableDefinedNotNull(nextPresentationData.id) || !isVariableDefinedNotNull(nextPresentationData.title)) {
      this.props.nextPresentationLink = null;
      return;
    }

    this.props.nextPresentationLink = `https://slideslive.com/${nextPresentationData.id}`;
    this.view.nextPresentationTitle = `${nextPresentationData.title}`;

    if (isVariableDefinedNotNull(nextPresentationData.thumbnail)) {
      this.view.nextPresentationThumbnail = nextPresentationData.thumbnail;
    }
  }

  loadOnDemand(info) {
    const hasMedia = isVariableDefinedNotNull(info.video_service_id) && info.video_service_id !== '';
    if (!hasMedia) {
      this.showNotRecorded();
      return;
    }

    this.mode = info.player_type;
    this.props.presentationMediaSetId = info.media_set_id;

    if (!this.guest) {
      this.initPlaylists();
      this.initBookmarks();
    }

    if (this.options.canReviewPresentation) {
      this.initPresentationReview({
        mediaSetId: info.media_set_id,
        videoVersion: info.video_version,
        slidesVersion: info.slides_version,
      });
    }

    if (isVariableDefinedNotNull(info.video_ken_enabled) && info.video_ken_enabled) {
      this.createVideoKen();
      this.view.topicsAndTranscriptAvailable = true;
    }

    this.playbackController = new PlaybackController({
      loadingChanged: (loading) => {
        this.view.videoLoading = loading;
      },
      stateChanged: (state) => this.updateState(state),
    });

    if (this.mode === 'video_slideshow' || this.mode === 'audio_slideshow' || this.mode === 'video') {
      this.createVideoPlayer({
        accountId: info.account_id,
        presentationMediaSetId: info.media_set_id,
        defaultSubtitlesLanguage: info.default_subtitles_language,
        videoAspectRatio: info.video_aspect_ratio,
        cmcdEnabled: info.cmcd_enabled,
      });
    }

    if (this.mode === 'video_slideshow' || this.mode === 'audio_slideshow' || this.mode === 'slideshow') {
      this.createSlidesPlayer({ cmcdEnabled: info.cmcd_enabled });
    }

    if (this.videoPlayer) {
      const videoServiceData = {};

      if (info.video_cdn_servers) {
        videoServiceData.videoCdnServers = info.video_cdn_servers;
      }

      this.videoPlayer.load(info.video_service_name.toLowerCase(), info.video_service_id, videoServiceData);

      if (info.subtitles) {
        this.videoPlayer.loadSubtitles(info.subtitles);
      }
    }

    if (this.slidesPlayer) {
      this.slidesPlayer.load(info.slides_json_url, info.slides_xml_url, this.options.slidesVideoServiceDataUrl);
    }
  }

  loadLive(info) {
    if (info.streaming.details && info.streaming.details.terminated) {
      this.showLivestreamFinished();
    } else {
      this.createVideoPlayer({
        accountId: info.account_id,
        presentationMediaSetId: 'live',
        cmcdEnabled: info.cmcd_enabled,
      });

      if (info.streaming.details.slides_video) {
        this.createSlidesVideoStreamPlayer({
          accountId: info.account_id,
          presentationMediaSetId: 'live',
          videoAspectRatio: 16 / 9.0,
          cmcdEnabled: info.cmcd_enabled,
        });
      } else {
        this.createSlidesPlayer();
      }

      this.playbackController = new LivePlaybackController(this.videoPlayer, this.slidesVideoStreamPlayer, {
        loadingChanged: (loading) => {
          this.view.videoLoading = loading;
        },
        stateChanged: (state) => this.updateState(state),
        diffChanged: (diff) => (this.view.slidesVideoStreamDiff = diff),
      });

      this.createLivestreamInfoProvider();

      this.mode = 'video_slideshow';

      if (this.slidesPlayer) {
        this.slidesPlayer.load();
      }

      this.livestreamInfoProvider.processLivestreamInfo(info);
      this.livestreamInfoProvider.start();
    }
  }

  createVideoKen() {
    this.videoKen = new VideoKen({
      container: this.view.videoElement,
      presentationId: this.options.presentationId,
    });
  }

  createVideoPlayer({
    accountId = null,
    presentationMediaSetId = null,
    defaultSubtitlesLanguage = null,
    videoAspectRatio = 16 / 9.0,
    cmcdEnabled = false,
  } = {}) {
    log('PLAYER', 'creating video player');

    this.videoPlayer = new VideoPlayer(this.view.videoElement, {
      initial: {
        ratio: videoAspectRatio,
        subtitlesLanguage: defaultSubtitlesLanguage,
      },
      locale: this.options.locale,
      presentationId: this.options.presentationId,
      presentationMediaSetId,
      analyticsUserUuid: this.options.analyticsUserUuid,
      analyticsSessionUuid: this.options.analyticsSessionUuid,
      embed: this.embedInterface.enabled,
      accountId,
      contentType: 'speaker',
      source: this.options.source,
      offline: this.props.offline,
      live: this.props.live,
      autoPlay: this.options.autoPlay,
      defaultStreamQuality: this.options.defaultStreamQuality,
      originalVideoControls: this.options.originalVideoControls,
      cmcdEnabled,

      callbacks: {
        ready: () => {
          this.props.videoReadyAt = now();

          this.readyCheck();
        },

        loadingChanged: (loading) => {
          this.playbackController.updateSpeakerVideoLoading(loading);
        },

        stateChanged: (state) => this.playbackController.updateSpeakerVideoState(state),
        volumeChanged: (volume, muted) => {
          this.view.updateVolume(volume, muted);
          this.embedInterface.sendVolumeChanged(volume, muted);
        },
        ratioChanged: (ratio) => {
          if (!isVariableDefinedNotNull(this.props.forcedRealVideoRatio)) {
            this.view.realVideoRatio = ratio;
          }
        },

        availablePlaybackRatesChanged: (playbackRates) => {
          this.view.updateAvailablePlaybackRates(playbackRates);
          this.embedInterface.sendAvailablePlaybackRatesChanged(playbackRates);
        },
        playbackRateChanged: (rate) => {
          this.view.playbackRate = rate;
          this.playbackController.updatePlaybackRate(rate);
          this.embedInterface.sendPlaybackRateChanged(rate);
        },

        availableQualitiesChanged: (qualities) => this.view.updateAvailableQualities(qualities),
        qualityChanged: (quality) => {
          this.analyticsV2.trackVideoQualityChange(quality);
          this.view.quality = quality;
        },

        availableServersChanged: (servers) => this.view.updateAvailableServers(servers),
        playbackServerChanged: (server) => {
          this.analyticsV2.trackVideoPlaybackServerChange(server);
          this.view.playbackServerIndex = server;
        },

        subtitlesChanged: (tracks) => {
          this.view.updateSubtitles(tracks);
          this.embedInterface.sendAvailableSubtitlesChanged(tracks);
        },
        activeSubtitlesChanged: (track) => {
          this.view.activeSubtitle = track;
          this.embedInterface.sendActiveSubtitlesChanged(track);
        },
        renderSubtitlesWord: (lines) => {
          this.view.renderSubtitlesWord(lines);
        },

        livestreamStartChanged: (started) => {
          this.view.livestreamStarted = started;

          if (started) {
            this.analyticsV2.trackLivestreamStarted();
          } else {
            this.analyticsV2.trackLivestreamNotStarted();
          }
        },
        inLivePositionChanged: (inLivePosition) => (this.view.videoInLivePosition = inLivePosition),
        streamStartChanged: (streamStart) => this.livestreamInfoProvider.updateStreamStartFromVideo(streamStart),

        firstPlayHackChanged: (firstPlayHack) => (this.view.firstPlayHack = firstPlayHack),

        // eslint-disable-next-line no-unused-vars
        timesChanged: (currentTime, duration) =>
          this.updateTime(
            currentTime,
            this.currentStreamTime,
            this.programDateTime,
            this.slidesPlayer && this.slidesPlayer.inSync,
          ),
        seekableChanged: (seekable) => (this.view.seekable = seekable),
        ended: () => {
          this.playbackController.pause();
          this.view.ended = true;

          if (this.props.nextPresentationLink) {
            this.view.showNextPresentationOverlay();
          }

          this.updateTime(this.duration);
          this.analyticsV2.trackEnded();
        },

        showError: (error) => this.showError(error),
        debugInfo: (info) => this.view.updateDebugInfo(info),
        reportError: (component, error, data) => this.reportError(component, error, data),

        playFailed: () => (this.playbackController.playFailed = true),
        loadingEvent: (event) => {
          if (event.ty === 'start') {
            this.analyticsV2.trackVideoLoadingStart();
          } else if (event.ty === 'end') {
            this.analyticsV2.trackVideoLoadingEnd(event.du);
          } else {
            console.warn('PLAYER', 'unknown video loading event type', event.ty);
          }
        },
      },
    });

    if (this.size && this.size.videoElementWidth) {
      this.videoPlayer.size = {
        w: this.size.videoElementWidth,
        h: this.size.videoElementHeight,
      };
    } else {
      this.videoPlayer.size = {
        w: 0,
        h: 0,
      };
    }

    if (this.playbackController) {
      this.playbackController.speakerVideoPlayer = this.videoPlayer;
    }
  }

  createSlidesVideoStreamPlayer({
    presentationMediaSetId = null,
    accountId = null,
    videoAspectRatio = 16 / 9.0,
    cmcdEnabled = false,
  } = {}) {
    log('PLAYER', 'creating slides video stream player');

    this.slidesVideoStreamPlayer = new VideoPlayer(this.view.slidesElement, {
      initial: {
        ratio: videoAspectRatio,
        volume: 0,
        muted: true,
      },
      presentationId: this.options.presentationId,
      presentationMediaSetId,
      analyticsUserUuid: this.options.analyticsUserUuid,
      analyticsSessionUuid: this.options.analyticsSessionUuid,
      embed: this.embedInterface.enabled,
      accountId,
      contentType: 'full_slide_video',
      source: this.options.source,
      offline: this.props.offline,
      live: this.props.live,
      autoPlay: this.options.autoPlay,
      defaultStreamQuality: this.options.defaultStreamQuality,
      originalVideoControls: false,
      cmcdEnabled,

      callbacks: {
        ready: () => {
          this.props.slidesReadyAt = now();
          this.readyCheck();
        },

        ratioChanged: (ratio) => {
          this.view.slidesRatio = ratio;
        },

        // firstPlayHackChanged: (firstPlayHack) => (this.view.firstPlayHack = firstPlayHack),

        ended: () => {},

        showError: (error) => this.showError(error),
        debugInfo: (info) => this.view.updateDebugInfo(info),
        reportError: (component, error, data) => this.reportError(component, error, data),

        playFailed: () => (this.playbackController.slidesPlayFailed = true),

        loadingChanged: (loading) => {
          this.view.liveSlideVideoLoading = loading;
        },

        // eslint-disable-next-line no-unused-vars
        stateChanged: (state) => {},

        // eslint-disable-next-line no-unused-vars
        volumeChanged: (volume, muted) => {},

        // eslint-disable-next-line no-unused-vars
        availablePlaybackRatesChanged: (playbackRates) => {},

        // eslint-disable-next-line no-unused-vars
        playbackRateChanged: (rate) => {},

        availableQualitiesChanged: (qualities) => this.view.updateAvailableLiveSlideVideoQualities(qualities),
        qualityChanged: (quality) => {
          // this.analyticsV2.trackVideoQualityChange(quality);
          this.view.liveSlideVideoQuality = quality;
        },

        availableServersChanged: (servers) => this.view.updateAvailableLiveSlideVideoServers(servers),
        playbackServerChanged: (server) => {
          // this.analyticsV2.trackVideoPlaybackServerChange(server);
          this.view.liveSlideVideoPlaybackServerIndex = server;
        },

        // eslint-disable-next-line no-unused-vars
        subtitlesChanged: (tracks) => {},

        // eslint-disable-next-line no-unused-vars
        activeSubtitlesChanged: (track) => {},

        // eslint-disable-next-line no-unused-vars
        livestreamStartChanged: (started) => {},
        // eslint-disable-next-line no-unused-vars
        inLivePositionChanged: (inLivePosition) => {},
        // eslint-disable-next-line no-unused-vars
        streamStartChanged: (streamStart) => {},

        // eslint-disable-next-line no-unused-vars
        firstPlayHackChanged: (firstPlayHack) => {},

        // eslint-disable-next-line no-unused-vars
        timesChanged: (currentTime, duration) => {},

        // eslint-disable-next-line no-unused-vars
        seekableChanged: (seekable) => {},

        // eslint-disable-next-line no-unused-vars
        loadingEvent: (event) => {},
      },
    });

    if (this.size && this.size.slidesWidth) {
      this.slidesVideoStreamPlayer.size = {
        w: this.size.slidesWidth,
        h: this.size.slidesHeight,
      };
    } else {
      this.slidesVideoStreamPlayer.size = {
        w: 0,
        h: 0,
      };
    }

    return this.slidesVideoStreamPlayer;
  }

  createSlidesPlayer({ presentationMediaSetId = null, accountId = null, cmcdEnabled = false } = {}) {
    log('PLAYER', 'creating slides player');

    this.slidesPlayer = new SlidesPlayer(
      this.view.slidesElement,
      {
        presentationId: this.options.presentationId,
        presentationMediaSetId,
        analyticsUserUuid: this.options.analyticsUserUuid,
        analyticsSessionUuid: this.options.analyticsSessionUuid,
        embed: this.embedInterface.enabled,
        accountId,
        source: this.options.source,
        cacheTimestamp: this.presentationUpdatedAt,
        playerToken: this.options.playerToken,
        editor: this.options.editor,
        offline: this.props.offline,
        live: this.props.live,
        cmcdEnabled,
      },

      {
        ready: () => {
          this.props.slidesReadyAt = now();

          this.readyCheck();
        },
        loadingChanged: (loading) => {
          this.view.slidesLoading = loading;
        },
        inSyncChanged: (inSync) => (this.view.slidesInSync = inSync),
        currentSlideChanged: (slideIndex, slideCount, slideData) => {
          this.view.updateSlide(slideIndex, slideCount, slideData);

          this.runCallbacks('currentSlideChanged', slideIndex, slideCount, slideData);
        },
        currentPointerChanged: (pointer) => {
          this.view.updatePointer(pointer);
        },
        ratioChanged: (ratio) => (this.view.slidesRatio = ratio),

        videoChanged: (video, playing) => this.playbackController.updateSlideVideoPlayer(video, playing),
        videoLoadingChanged: (loading) => this.playbackController.updateSlideVideoLoading(loading),
        videoStateChanged: (state) => this.playbackController.updateSlideVideoState(state),
        videoMutedChanged: (visible, muted) => this.view.updateSlideMuted(visible, muted),
        videoPlaybackRateChanged: (rate) => this.playbackController.updatePlaybackRate(rate),
        videoPlayFailed: () => {
          this.playbackController.playFailed = true;
        },
        loadingEvent: (event) => {
          if (event.ty === 'start') {
            this.analyticsV2.trackSlidesLoadingStart();
          } else if (event.ty === 'end') {
            this.analyticsV2.trackSlidesLoadingEnd(event.du);
          } else {
            console.warn('PLAYER', 'unknown slides loading event type', event.ty);
          }
        },
      },
    );

    if (this.size && this.size.slidesHeight) {
      this.slidesPlayer.size = this.size;
    }
  }

  createLivestreamInfoProvider() {
    this.livestreamInfoProvider = new LivestreamInfoProvider(
      this.presentationPlaylistLoader,
      {},

      {
        error: (error) => this.showError(error),
        serversChanged: (servers) => {
          this.videoPlayer.updateLiveServers(servers);

          if (this.slidesVideoStreamPlayer) {
            this.slidesVideoStreamPlayer.updateLiveServers(servers, 'slides_stream_key');
          }
        },
        delayChanged: (delay) => this.slidesPlayer && (this.slidesPlayer.delay = delay),
        subtitlesEnabledChanged: (enabled) => (this.videoPlayer.subtitlesEnabled = enabled),
        subtitlesDelayMsChanged: (delay) => (this.videoPlayer.liveSubtitlesDelayMs = delay),
        streamStartChanged: (streamStart) => this.slidesPlayer && (this.slidesPlayer.streamStart = streamStart),
        showSlidesChanged: (showSlides) => (this.view.hideSlides = !showSlides),
        liveFinishedChanged: (liveFinished) => (this.liveFinished = liveFinished),
        newSlides: (slides) => this.slidesPlayer && this.slidesPlayer.addLivestreamSlides(slides),
        setNextPresentation: (nextPresentationData) => this.setNextPresentation(nextPresentationData),
        thumbnailChanged: (thumbnail) => (this.view.thumbnail = thumbnail),

        // eslint-disable-next-line no-unused-vars
        terminatedChanged: (terminated) => this.terminateLiveStream(),

        slideVideoServersChanged: (servers) =>
          this.slidesVideoStreamPlayer && this.slidesVideoStreamPlayer.updateLiveServers(servers, 'slides_stream_key'),
        cmcdEnabledChanged: (value) => (this.cmcdEnabled = value),
      },
    );
  }

  createAnalyticsV2() {
    this.analyticsV2 = new PlayerAnalyticsV2(
      {
        offline: this.options.offline,
        benBaseUrl: this.options.benBaseUrl,
        disableTracking: this.options.disableTracking,
        disableGoogleAnalytics: this.options.disableGoogleAnalytics || this.options.offline,
        playerToken: this.options.playerToken,
        analyticsSessionToken: this.options.analyticsSessionToken,
        analyticsPlayerSessionsIngestUrl: this.options.analyticsPlayerSessionsIngestUrl,
        analyticsPlayerSessionEventsIngestUrl: this.options.analyticsPlayerSessionEventsIngestUrl,
      },
      {
        live: () => this.live,
        inLivePosition: () => (this.videoPlayer ? this.videoPlayer.inLivePosition : false),
        slidesInSync: () => (this.slidesPlayer ? this.slidesPlayer.inSync : false),

        size: () => this.view.size,

        presentationId: () => this.options.presentationId,
        sourceUrl: () => this.sourceUrl,
        videoUrl: () => window.location.href,
        videoServiceName: () => (this.videoPlayer ? this.videoPlayer.videoServiceName : 'none'),
        playerTime: () => (this.videoPlayer ? this.videoPlayer.currentTime : 0),
        playerDuration: () => (this.videoPlayer ? this.videoPlayer.duration : 0),
        playerSlide: () => (this.slidesPlayer ? this.slidesPlayer.currentSlideIndex : 0),
        playerState: () => (this.isPlaying ? 'playing' : 'paused'),
      },
    );
  }

  createEmbedInterface() {
    this.embedInterface = new EmbedInterface(this.element);

    if (this.embedInterface.enabled) {
      this.on('load', () => this.embedInterface.sendLoad());
      this.on('ready', () => this.embedInterface.sendReady());
      this.on('resize', (size) => {
        this.embedInterface.sendSetSize(size);
        this.embedInterface.sendSizeChanged(size);
      });
    }
  }

  readyCheck() {
    this._updateReportIssueUrl();

    if (!this.ready) {
      return;
    }

    this.props.readyAt = now();

    log('PLAYER', 'load');
    this.runCallbacks('load', this.duration, this.mode, (this.slidesPlayer && this.slidesPlayer.slideCount) || 0);

    if (this.slidesPlayer) {
      if (!this.options.editor && this.mode === 'slideshow' && this.slidesPlayer.slideCount === 0) {
        this.showNotRecorded();
        return;
      }

      this.view.slidesRatio = this.slidesPlayer.ratio;
    }

    if (this.playlists) {
      this.playlists.initPlaylistsList();
      this.playlists.add('history');
    }

    if (this.bookmarks) {
      this.bookmarks.initBookmarksList();
    }

    if (this.presentationReview) {
      this.presentationReview.loadNotes();
    }

    if (this.canPlay) {
      this.analyticsV2.trackReady();
      this.startPlay();
    }
  }

  calculateStartTime() {
    const startTimeFromServer = this.props.startTimeFromServer || 0;
    const startTimeFromOptions = this.startTimeFromOptions || 0;
    const startTimeFromCookie = this.startTimeFromCookie || 0;

    if (!this.live || this.liveFinished) {
      let startTime;
      if (startTimeFromOptions > 0) {
        startTime = Math.max(startTimeFromServer, startTimeFromOptions);
      } else {
        startTime = Math.max(startTimeFromServer, startTimeFromCookie);
      }

      if (startTime > 0) {
        return startTime;
      }
    }

    return null;
  }

  trackInitialLoadTiming() {
    this.analyticsV2.trackInitialLoadTiming({
      before_load: this.props.initialLoadingStartedAt - this.props.createdAt,
      data: this.props.presentationInfoReceivedAt - this.props.initialLoadingStartedAt,
      ready: this.props.readyAt - this.props.initialLoadingStartedAt,
      video_ready: this.props.videoReadyAt ? this.props.videoReadyAt - this.props.initialLoadingStartedAt : null,
      slides_ready: this.props.slidesReadyAt ? this.props.slidesReadyAt - this.props.initialLoadingStartedAt : null,
    });
  }

  startPlay() {
    log('PLAYER', 'can play', `live = ${this.live},`, `live finished = ${this.liveFinished}`);

    this.trackInitialLoadTiming();

    const startTime = this.calculateStartTime();
    if (startTime !== null) {
      this.currentTime = startTime;
      this.updateTime(startTime, undefined, undefined, false);

      if (!this.options.autoPlay) {
        this.playbackController.pause();
      }
    }

    this.loading = false;

    log('PLAYER', 'ready');
    this.runCallbacks('ready');

    if (this.videoPlayer && this.options.autoPlay) {
      this.play();
    }
  }

  updateTime(time, streamTime, programDateTime, autoPlayVideoSlide) {
    let duration;
    if (this.videoPlayer) {
      duration = this.duration;
    } else {
      duration = time;
    }

    this.view.updateTime(time, duration);

    if (!this.live) {
      // subtract 2s so there is time to catch up
      const progressCookieValue = Math.round(Math.max(0, time - 2000));
      createCookie(
        this.progressCookieName,
        progressCookieValue,
        this.progressCookieExpireMinutes,
        `/${this.options.presentationId}`,
      );
    }

    if (this.slidesPlayer) {
      if (this.live) {
        this.slidesPlayer.updateSlide(streamTime, programDateTime, false, autoPlayVideoSlide);
      } else {
        this.slidesPlayer.updateSlide(time, programDateTime, false, autoPlayVideoSlide);
      }
    }

    this.runCallbacks('timeChanged', time, duration);
    this.embedInterface.sendTimeUpdate(time, duration);
  }

  update() {
    if (this.videoPlayer) {
      const time = this.currentTime;
      const streamTime = this.videoPlayer.currentStreamTime;
      const programDateTime = this.videoPlayer.programDateTime;

      this.updateTime(time, streamTime, programDateTime, this.slidesPlayer && this.slidesPlayer.inSync);

      this.analyticsV2.update(time);
    }

    this._updateReportIssueUrl();
  }

  highResUpdate() {
    if (this.videoPlayer) {
      const time = this.currentTime;

      if (!this.lastHighResUpdateTime || time !== this.lastHighResUpdateTime) {
        this.lastHighResUpdateTime = time;
        this.lastHighResUpdateOffset = 0;
      }

      const highResTime = time + this.lastHighResUpdateOffset;

      if (this.playbackController.playing) {
        this.lastHighResUpdateOffset += now() - this.lastHighResUpdateTimeAt;
      }

      this.lastHighResUpdateTimeAt = now();

      if (!this.live && this.slidesPlayer) {
        this.slidesPlayer.updatePointer(highResTime);
      }
    }
  }

  showNotRecorded() {
    this.analyticsV2.trackNotRecorded();

    this.loading = false;
    this.view.notRecorded = true;

    this.runCallbacks('notRecorded');
  }

  showError(error) {
    if (this.videoPlayer) {
      this.videoPlayer.destroy();
    }

    this.view.showError(error);
    this.runCallbacks('error', error);
  }

  reportError(component, error, errorData) {
    const warn = (errorData && errorData.warn) || false;
    this.analyticsV2.trackPlayerError(component, error, warn);

    if (this.options.playerErrorReportUrl) {
      const data = JSON.stringify({
        player_error: {
          env: (gon && gon.env) || 'UNKNOWN',
          presentation_id: this.options.presentationId,
          embed: this.embedInterface ? this.embedInterface.enabled : window !== window.parent,
          user_agent: navigator.userAgent,
          component,
          error,
          data: errorData,
        },
      });

      fetch(this.options.playerErrorReportUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'text/plain; charset=utf-8',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: data,
      }).catch((fetchError) => {
        console.warn('PLAYER', 'report error failed', fetchError);
      });
    }
  }

  showProcessing(text) {
    this.playbackController.pause();
    this.view.showProcessing(text);
  }

  showLivestreamFinished() {
    this.playbackController?.pause();
    this.loading = false;

    this.analyticsV2.trackLivestreamTerminated();
    this.view.livestreamTerminated = true;

    if (this.props.nextPresentationLink) {
      this.view.showNextPresentationOverlay();
    }
  }

  updateState(state) {
    log('PLAYER', 'state', state);

    if (state === 'playing') {
      this.analyticsV2.trackPlayingStateChange(this.props.firstPlay);
    } else {
      this.analyticsV2.trackPausedStateChange();
    }

    const isPlaying = state === 'playing';
    const isPaused = state === 'paused';

    this.view.playing = isPlaying;
    this.view.paused = isPaused;

    if (isPlaying) {
      this.view.ended = false;
      this.view.firstPlayHack = false;

      this.updateTimer.start();

      if (this.livestreamInfoProvider) {
        this.livestreamInfoProvider.start();
      }
    } else if (isPaused) {
      this.updateTimer.stop();
    }

    if (isPlaying) {
      this.runCallbacks('play');
    }

    if (isPaused) {
      this.runCallbacks('pause');
    }

    if (this.props.firstPlay && state === 'playing') {
      this.props.firstPlay = false;
      this.view.firstPlay = this.props.firstPlay;

      log('PLAYER', 'first play');
    }

    this.embedInterface.sendStateChanged(state);
  }

  // Internal API

  set mode(mode) {
    log('PLAYER', 'mode', mode);

    this.view.mode = mode;
  }

  get mode() {
    return this.view.mode;
  }

  set loading(loading) {
    this.view.loading = loading;
  }

  // Seeking API

  startSeek(pct) {
    if (this.props.endSeekTimeout) {
      clearTimeout(this.props.endSeekTimeout);
      this.props.endSeekTimeout = null;
    }

    this.seeking = true;

    if (this.slidesPlayer) {
      this.syncSlidesToVideo(false);
    }

    const time = (this.duration * pct) / 100.0;

    this.props.playingBeforeSeeking = this.isPlaying;

    if (this.isPlaying) {
      this.pause();
    }

    this.updateTime(time);
  }

  updateSeek(pct) {
    const time = (this.duration * pct) / 100.0;
    this.updateTime(time);
  }

  endSeek(pct) {
    const time = (this.duration * pct) / 100.0;

    this.currentTime = time;
    this.updateTime(time, undefined, undefined, this.slidesPlayer && this.slidesPlayer.inSync);

    if (this.props.playingBeforeSeeking) {
      this.play();
    }

    this.props.endSeekTimeout = setTimeout(() => {
      this.seeking = false;
      this.props.endSeekTimeout = null;
    }, 25);
  }

  // API

  setAvailableSize(height) {
    this.view.setAvailableSize(height);
  }

  play() {
    this.analyticsV2.trackPlayRequest(this.props.firstPlay);
    this.playbackController.play();
  }

  pause() {
    if (this.isPaused) {
      return;
    }

    this.analyticsV2.trackPauseRequest();
    this.playbackController.pause();
  }

  togglePlayback() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  toggleMute() {
    this.analyticsV2.trackToggleMuteRequest(this.videoPlayer.muted);
    this.videoPlayer.toggleMute();
  }

  toggleSlideMute() {
    this.analyticsV2.trackToggleSlideMuteRequest(this.slidesPlayer.muted);
    this.slidesPlayer.toggleMute();
  }

  seekToLivePosition() {
    this.analyticsV2.trackSeekToLivePositionRequest(this.videoPlayer.inLivePosition);
    this.videoPlayer.seekToLivePosition();
  }

  toggleFullscreen() {
    this.analyticsV2.trackToggleFullscreenRequest(this.view.fullscreenActive);
    this.view.toggleFullscreen();
  }

  prevSlide() {
    this.analyticsV2.trackPrevSlideRequest();
    this.slidesPlayer.prev();
  }

  nextSlide() {
    this.analyticsV2.trackNextSlideRequest();
    this.slidesPlayer.next();
  }

  syncSlidesToVideo(autoPlayVideoSlide = true) {
    this.analyticsV2.trackSyncSlidesToVideoRequest();
    this.slidesPlayer.setInSync();

    if (!this.live) {
      this.slidesPlayer.updateSlide(this.currentSlideTime, undefined, true, autoPlayVideoSlide);
    }
  }

  syncVideoToSlides() {
    this.analyticsV2.trackSyncVideoToSlidesRequest();

    const time = this.slidesPlayer.currentSlideTime;

    this.slidesPlayer.setInSync();

    this.currentTime = time;
    this.updateTime(time, undefined, undefined, true);
  }

  seekTo(time) {
    this.currentTime = time;
    this.updateTime(time, undefined, undefined, this.slidesPlayer && this.slidesPlayer.inSync);
  }

  seekToSlide(index) {
    if (this.live) return;

    const time = this.slidesPlayer.timeForSlideIndex(index);

    this.analyticsV2.trackSeekToSlideRequest(index);
    this.slidesPlayer.setInSync();

    this.videoPlayer.currentTime = time;
    this.updateTime(time, undefined, undefined, true);
  }

  getSlideUrlFromEmbed(index) {
    const { url = null, type = null } = this.slidesPlayer ? this.slidesPlayer.dataForSlideIndex(index) : {};

    this.embedInterface.sendSlideUrl(index, url, type);
  }

  skipBack() {
    const newTime = Math.max(0, this.currentTime - 15000);

    this.currentTime = newTime;
    this.updateTime(newTime, undefined, undefined, this.slidesPlayer && this.slidesPlayer.inSync);
  }

  skipForward() {
    const newTime = Math.min(this.duration, this.currentTime + 15000);

    this.currentTime = newTime;
    this.updateTime(newTime, undefined, undefined, this.slidesPlayer && this.slidesPlayer.inSync);
  }

  togglePlaylist(name, add) {
    if (!isVariableDefinedNotNull(this.playlists)) {
      return;
    }

    this.analyticsV2.trackTogglePlaylistRequest(name, add);

    if (add) {
      this.playlists.add(name);
      return;
    }

    this.playlists.remove(name);
  }

  syncSlidesVideoStream() {
    if (!this.playbackController || !this.playbackController.seekSlidesToSpeakerVideo) {
      return;
    }

    this.playbackController.seekSlidesToSpeakerVideo({ force: true });
  }

  updateSize() {
    this.view.updateSize(true, { withoutTimeout: true });
  }

  on(event, callback) {
    this.callbacks[event].add(callback);
  }

  runCallbacks(event, ...args) {
    for (const callback of this.callbacks[event]) {
      callback(...args);
    }
  }

  terminateLiveStream() {
    this.showLivestreamFinished();
  }

  destroy() {
    if (this.props.visibilityChangeListenerId) {
      removeListener(document, { id: this.props.visibilityChangeListenerId });
      this.props.visibilityChangeListenerId = null;
    }

    if (this.videoPlayer) {
      this.videoPlayer.destroy();
    }

    if (this.slidesPlayer) {
      this.slidesPlayer.destroy();
    }

    if (this.view) {
      this.view.destroy();
    }

    if (this.bookmarks) {
      this.bookmarks.destroy();
    }

    if (this.keyboard) {
      this.keyboard.destroy();
    }

    if (this.livestreamInfoProvider) {
      this.livestreamInfoProvider.destroy();
    }

    if (this.playbackController) {
      this.playbackController.destroy();
    }

    if (this.analyticsV2) {
      this.analyticsV2.destroy();
    }

    if (this.playlists) {
      this.playlists.destroy();
    }

    if (this.presentationPlaylistLoader) {
      this.presentationPlaylistLoader.destroy();
    }

    if (this.presentationReview) {
      this.presentationReview.destroy();
    }

    if (this.updateTimer) {
      this.updateTimer.stop();
    }
  }

  _updateReportIssueUrl() {
    const details = {
      link: this.sourceUrl,
      analytics_user_uuid: this.options.analyticsUserUuid,
      analytics_session_uuid: this.options.analyticsSessionUuid,
      presentation_id: this.options.presentationId,

      current_time: this.videoPlayer ? this.videoPlayer.currentTime : null,

      speaker_video_source_url: this.videoPlayer ? this.videoPlayer.activeVideoSourceUrl : null,
      speaker_video_quality: this.videoPlayer ? this.videoPlayer.activeQualityName : null,
      speaker_video_playback_rate: this.videoPlayer ? this.videoPlayer.activePlaybackRateValue : null,

      current_slide_index: this.slidesPlayer ? this.slidesPlayer.currentSlideIndex : null,
      current_slide_type: this.slidesPlayer ? this.slidesPlayer.currentSlideType : null,
      current_slide_url:
        this.playbackController && this.playbackController.activeSlideVideoSourceUrl
          ? this.playbackController.activeSlideVideoSourceUrl || null
          : null,
      current_slide_quality:
        this.playbackController && this.playbackController.activeSlideQualityName
          ? this.playbackController.activeSlideQualityName || null
          : null,
      current_slide_playback_rate:
        this.playbackController && this.playbackController.activePlaybackRateValue
          ? this.playbackController.activeSlidePlaybackRateValue || null
          : null,

      slides_video_current_time: this.slidesVideoStreamPlayer ? this.slidesVideoStreamPlayer.currentTime : null,
      slides_video_source_url: this.slidesVideoStreamPlayer ? this.slidesVideoStreamPlayer.activeVideoSourceUrl : null,
      slides_video_quality: this.slidesVideoStreamPlayer ? this.slidesVideoStreamPlayer.activeQualityName : null,
      slides_video_playback_rate: this.slidesVideoStreamPlayer
        ? this.slidesVideoStreamPlayer.activePlaybackRateValue
        : null,

      live: this.props.live,
      liveFinished: this.props.liveFinished,
      presentation_media_set_id: this.props.presentationMediaSetId,
    };

    const details64 = btoa(JSON.stringify(details));
    this.view.reportIssueUrl = `https://report.slideslive.com/?p=${details64}`;
  }

  get progressCookieExpireMinutes() {
    return 24 * 60 * 30;
  }

  get progressCookieName() {
    return `player-progress_${this.options.presentationId}`;
  }

  set volume(volume) {
    this.analyticsV2.trackVolumeChangeRequest(volume);
    this.videoPlayer.volume = volume;
  }

  get volume() {
    return this.videoPlayer.volume;
  }

  set muted(muted) {
    this.videoPlayer.muted = muted;
  }

  get muted() {
    return this.videoPlayer.muted;
  }

  get programDateTime() {
    return this.videoPlayer.programDateTime;
  }

  set currentTime(time) {
    this.analyticsV2.trackSeekRequest(time);
    this.videoPlayer.currentTime = time;
  }

  get currentTime() {
    return this.videoPlayer.currentTime;
  }

  get currentStreamTime() {
    return this.videoPlayer.currentStreamTime;
  }

  get currentSlideTime() {
    return this.currentStreamTime || this.currentTime;
  }

  set videoQuality(quality) {
    this.analyticsV2.trackVideoQualityChangeRequest(this.videoPlayer.quality, quality);
    this.videoPlayer.setQuality(quality);
  }

  set playbackServer(serverIndex) {
    this.analyticsV2.trackVideoPlaybackServerChangeRequest(this.videoPlayer.playbackServerIndex, serverIndex);
    this.videoPlayer.playbackServer = serverIndex;
  }

  set liveSlidesVideoQuality(quality) {
    // this.analyticsV2.trackVideoQualityChangeRequest(this.videoPlayer.quality, quality);
    this.slidesVideoStreamPlayer.setQuality(quality);
  }

  set liveSlidesPlaybackServer(serverIndex) {
    // this.analyticsV2.trackVideoPlaybackServerChangeRequest(this.videoPlayer.playbackServerIndex, serverIndex);
    this.slidesVideoStreamPlayer.playbackServer = serverIndex;
  }

  set subtitleTrack(track) {
    this.analyticsV2.trackSubtitleTrackChangeRequest(this.videoPlayer.subtitlesTrack, track);
    this.videoPlayer.setSubtitleTrack(track);
  }

  set playbackRate(rate) {
    this.analyticsV2.trackVideoPlaybackRateChangeRequest(this.videoPlayer.playbackRate, rate);
    this.playbackController.playbackRate = rate;
  }

  get playbackRate() {
    return this.playbackController.playbackRate;
  }

  set zoomRatio(ratio) {
    this.view.zoom = ratio;
  }

  get zoomRatio() {
    return this.view.zoom;
  }

  get duration() {
    return this.videoPlayer?.duration || 0;
  }

  get hideSlides() {
    return this.view.hideslides;
  }

  set hideSlides(hideSlides) {
    this.view.hideSlides = hideSlides;
  }

  // props

  get live() {
    return this.props.live;
  }

  set live(live) {
    this.props.live = live;
    this.view.live = live;
  }

  get offline() {
    return this.props.offline;
  }

  set offline(offline) {
    this.props.offline = offline;
    this.view.offline = offline;
  }

  get guest() {
    return this.props.guest;
  }

  set guest(guest) {
    this.props.guest = guest;
    this.view.guest = guest;
  }

  get liveFinished() {
    return this.props.liveFinished;
  }

  set liveFinished(liveFinished) {
    this.props.liveFinished = liveFinished;
    this.view.liveFinished = liveFinished;
    this.videoPlayer.liveFinished = liveFinished;
  }

  get ready() {
    const videoReady = !this.videoPlayer || this.videoPlayer.ready;
    const slidesReady = !this.slidesPlayer || this.slidesPlayer.ready;

    return videoReady && slidesReady;
  }

  get canPlay() {
    return this.ready;
  }

  get isPlaying() {
    return this.playbackController.playing;
  }

  get isPaused() {
    return !this.playbackController.playing;
  }

  get size() {
    return this.view.size;
  }

  get startTimeFromCookie() {
    return parseInt(readCookie(this.progressCookieName), 10) || 0;
  }

  get startTimeFromOptions() {
    if (isVariableDefinedNotNull(this.options.startTime) && this.options.startTime > 0) {
      return this.options.startTime;
    }

    if (
      !this.live &&
      this.slidesPlayer &&
      isVariableDefinedNotNull(this.options.startSlide) &&
      this.options.startSlide > 0
    ) {
      return this.slidesPlayer.timeForSlideIndex(this.options.startSlide);
    }

    return 0;
  }

  get source() {
    return this.embedInterface.enabled ? 'embed' : 'web';
  }

  get sourceUrl() {
    return this.embedInterface.enabled ? this.embedInterface.embedParentUrl : window.location.href;
  }

  get firstPlay() {
    return this.props.firstPlay;
  }

  get videoServiceName() {
    return this.props.videoServiceName;
  }

  //

  set seeking(seeking) {
    this.props.seeking = seeking;
    this.view.seeking = seeking;
  }

  get seeking() {
    return this.props.seeking;
  }

  get presentationUpdatedAt() {
    return this.props.presentationUpdatedAt;
  }

  set presentationUpdatedAt(presentationUpdatedAt) {
    this.props.presentationUpdatedAt = presentationUpdatedAt;
  }

  set cmcdEnabled(enabled) {
    // TODO
  }
}
