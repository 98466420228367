import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import noUiSlider from 'nouislider';

export default class ZoomSlider {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.props = {
      realVideoRatio: 16 / 9.0,
      displayVideoRatio: null,
      slidesRatio: 16 / 9.0,
      changed: !!this.options.defaultRatio,
      zoom: this.options.defaultRatio || 0.5,
    };

    noUiSlider.create(this.element, {
      start: this.props.zoom,
      range: {
        min: 0,
        max: 1,
      },
      step: 0.003,
      behaviour: 'tap-drag',
      animate: false,
    });

    this.setHandleIcon();
    this.setColors();

    this.slider.on('slide', (values, handle) => this.slide(values, handle));
    this.slider.on('set', (values, handle) => this.updateZoom(values, handle));
    this.slider.on('click', (values, handle) => this.sliderClicked(values, handle));

    if (this.props.changed) {
      this.options.callbacks.zoomChanged(this.props.zoom);
    }
  }

  setHandleIcon() {
    const handle = this.element.querySelector('.noUi-handle');
    handle.innerHTML = this.options.handleIcon;
  }

  setColors() {
    const slider = this.element;
    const handle = this.element.querySelector('.noUi-handle');

    if (isVariableDefinedNotNull(this.options.sliderColor)) {
      slider.style.backgroundColor = this.options.sliderColor;
    }

    if (isVariableDefinedNotNull(this.options.handleColor)) {
      handle.style.backgroundColor = this.options.handleColor;
    }
  }

  // eslint-disable-next-line no-unused-vars
  slide(values, handle) {
    const diff = Math.abs(this.slider.get() - this.balancedZoom);
    if (diff < 0.05) {
      this.props.changed = false;
      this.props.zoom = this.balancedZoom;
      this.slider.set(this.props.zoom);
    } else {
      this.props.changed = true;
      this.props.zoom = this.slider.get();
    }

    this.options.callbacks.zoomChanged(this.props.zoom);
  }

  // eslint-disable-next-line no-unused-vars
  updateZoom(values, handle) {
    this.options.callbacks.zoomChanged(this.props.zoom);
  }

  // eslint-disable-next-line no-unused-vars
  sliderClicked(values, handle) {
    this.options.callbacks.click(this.props.zoom);
  }

  updateBalancedZoom() {
    if (!this.props.changed) {
      this.props.zoom = this.balancedZoom;
      this.slider.set(this.props.zoom);

      this.options.callbacks.zoomChanged(this.props.zoom);
    }
  }

  set realVideoRatio(value) {
    this.props.realVideoRatio = value;
    this.updateBalancedZoom();
  }

  set displayVideoRatio(value) {
    this.props.displayVideoRatio = value;
    this.updateBalancedZoom();
  }

  set slidesRatio(value) {
    this.props.slidesRatio = value;
    this.updateBalancedZoom();
  }

  get slider() {
    return this.element.noUiSlider;
  }

  get balancedZoom() {
    const v = 1 / (this.props.displayVideoRatio || this.props.realVideoRatio);
    const s = 1 / this.props.slidesRatio;

    return s / (s + v);
  }
}
