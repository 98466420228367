import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import deepEqual from 'deep-equal';
import log from 'modules/player/log';

const UpdateInterval = 5000;
const FinishedUpdateInterval = 60000;
const UpdateIntervalVar = 4000;

export default class LivestreamInfoProvider {
  constructor(presentationPlaylistLoader, options, callbacks) {
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      lastUpdate: 0,
      lastSlideTimestamp: 0,
      lastSlideVersion: 0,
      delay: 0,
      subtitlesEnabled: true,
      subtitlesDelayMs: 0,
      streamStartFromVideo: null,
      actualStreamStartFromServer: null,
      scheduledStreamStartFromServer: null,
      appStart: null,
      showSlides: null,
      liveFinished: null,
      servers: null,
      currentRequest: null,
      updateInterval: null,
      interval: null,
      playingAnalyticsDisabled: false,
      thumbnail: null,
      terminated: false,
      reloadCounter: null,
      cmcdEnabled: null,

      destroyed: false,
    };

    this.presentationPlaylistLoader = presentationPlaylistLoader;
  }

  start() {
    if (this.props.destroyed) {
      return;
    }

    if (!this.props.interval) {
      log('LIVE', 'start');

      this.scheduleRequest();
    }
  }

  stop() {
    if (this.props.interval) {
      log('LIVE', 'stop');

      clearTimeout(this.props.interval);
      this.props.interval = null;
    }
  }

  scheduleRequest() {
    if (this.props.destroyed) {
      return;
    }

    let interval;
    if (isVariableDefinedNotNull(this.props.updateInterval)) {
      interval = this.props.updateInterval;
    } else if (this.props.liveFinished) {
      interval = FinishedUpdateInterval;
    } else {
      interval = UpdateInterval;
    }

    interval += Math.random() * UpdateIntervalVar;

    this.props.interval = setTimeout(() => this.requestLivestreamInfo(), interval);
  }

  requestLivestreamInfo() {
    if (this.props.destroyed) {
      return;
    }

    if (this.props.terminated) {
      return;
    }

    this.presentationPlaylistLoader.loadPresentationInfo({
      fatalError: (error) => {
        if (!this.props.liveFinished) {
          if (this.callbacks.error) this.callbacks.error(error);
        } else {
          window.location.reload();
        }
      },
      retriableError: () => this.scheduleRequest(),
      success: () => this.scheduleRequest(),
      presentationInfo: (info) => {
        this.processLivestreamInfo(info);
      },
    });
  }

  processLivestreamInfo(info) {
    const videoServiceName = info.video_service_name;
    const videoServiceId = info.video_service_id;
    const isLive = info.is_live;

    if (
      isVariableDefinedNotNull(isLive) &&
      !isLive &&
      isVariableDefinedNotNull(videoServiceName) &&
      videoServiceName !== '' &&
      isVariableDefinedNotNull(videoServiceId) &&
      videoServiceId
    ) {
      window.location.reload();
      return;
    }

    const cmcdEnabled = info.cmcd_enabled === null ? true : info.cmcd_enabled;
    if (isVariableDefinedNotNull(cmcdEnabled)) {
      this.updateCmcdEnabled(cmcdEnabled);
    }

    const nextPresentation = info.next_presentation;
    if (isVariableDefinedNotNull(nextPresentation)) {
      this.callbacks.setNextPresentation(nextPresentation);
    }

    const playingAnalyticsDisabled = info.playing_analytics_disabled;
    if (isVariableDefinedNotNull(playingAnalyticsDisabled)) {
      this.updatePlayingAnalyticsDisabled(playingAnalyticsDisabled);
    }

    if (isVariableDefinedNotNull(info.thumbnail)) {
      this.updateThumbnail(info.thumbnail);
    }

    const streaming = info.streaming;
    if (!isVariableDefinedNotNull(streaming)) {
      return;
    }

    const streamingDetails = streaming.details;
    if (isVariableDefinedNotNull(streamingDetails)) {
      this.updateServers(streamingDetails.servers);
      this.updateDelay(streamingDetails.delay);
      this.updateSubtitlesEnabled(streamingDetails.subtitles_enabled);
      this.updateSubtitlesDelayMs(streamingDetails.subtitles_delay_ms);
      this.updateStreamStartFromServer(streamingDetails.video_actual_start, streamingDetails.video_scheduled_start);
      this.updateShowSlides(streamingDetails.show_slides);
      this.updateLiveFinished(streamingDetails.finished);
      this.updateUpdateInterval(streamingDetails.update_interval);
      this.updateTerminated(streamingDetails.finished && streamingDetails.terminated);
      this.updateReloadCounter(streamingDetails.reload_counter);
    }

    const streamingSlides = streaming.slides;
    if (isVariableDefinedNotNull(streamingSlides)) {
      this.processNewSlides(streamingSlides.slides);
    }
  }

  updateServers(servers) {
    if (!deepEqual(this.servers, servers)) {
      this.props.servers = servers;
      this.callbacks.serversChanged(this.servers);
    }
  }

  updateDelay(delay) {
    if (this.delay !== delay) {
      log('LIVE', 'delay', delay);

      this.props.delay = delay;
      this.callbacks.delayChanged(this.delay);
    }
  }

  updateSubtitlesEnabled(enabled) {
    if (isVariableDefinedNotNull(enabled) && this.subtitlesEnabled !== enabled) {
      log('LIVE', 'subtitles enabled', enabled);

      this.props.subtitlesEnabled = enabled;
      this.callbacks.subtitlesEnabledChanged(this.subtitlesEnabled);
    }
  }

  updateSubtitlesDelayMs(delayMs) {
    if (isVariableDefinedNotNull(delayMs) && this.subtitlesDelayMs !== delayMs) {
      log('LIVE', 'subtitles delay', delayMs);

      this.props.subtitlesDelayMs = delayMs;
      this.callbacks.subtitlesDelayMsChanged(this.subtitlesDelayMs);
    }
  }

  updateStreamStartFromServer(actualStreamStartFromServer, scheduledStreamStartFromServer) {
    const streamStart = this.streamStart;

    this.props.actualStreamStartFromServer = actualStreamStartFromServer;
    this.props.scheduledStreamStartFromServer = scheduledStreamStartFromServer;

    if (this.streamStart !== streamStart) {
      this.callbacks.streamStartChanged(this.streamStart);
    }
  }

  updateStreamStartFromVideo(streamStartFromVideo) {
    const streamStart = this.streamStart;

    this.props.streamStartFromVideo = streamStartFromVideo;

    if (this.streamStart !== streamStart) {
      this.callbacks.streamStartChanged(this.streamStart);
    }
  }

  updateShowSlides(showSlides) {
    if (this.showSlides !== showSlides) {
      log('LIVE', 'show slides', showSlides);

      this.props.showSlides = showSlides;
      this.callbacks.showSlidesChanged(this.showSlides);
    }
  }

  updateLiveFinished(liveFinished) {
    if (this.liveFinished !== liveFinished) {
      log('LIVE', 'live finished', liveFinished);

      this.props.liveFinished = liveFinished;
      this.callbacks.liveFinishedChanged(this.liveFinished);
    }
  }

  updateUpdateInterval(updateInterval) {
    this.props.updateInterval = updateInterval;
  }

  updateTerminated(terminated) {
    if (this.props.terminated !== terminated) {
      log('LIVE', 'terminated', terminated);

      this.props.terminated = terminated;
      this.callbacks.terminatedChanged(terminated);
    }
  }

  updateReloadCounter(reloadCounter) {
    if (!isVariableDefinedNotNull(this.props.reloadCounter)) {
      this.props.reloadCounter = reloadCounter;
    } else if (this.props.reloadCounter !== reloadCounter) {
      // eslint-disable-next-line no-console
      console.info('LIVE', 'reloading page because reload counter changed', this.props.reloadCounter, reloadCounter);

      window.location.reload();
    }
  }

  updateCmcdEnabled(enabled) {
    if (this.props.cmcdEnabled !== enabled) {
      log('LIVE', 'cmcd enabled', enabled);

      this.props.cmcdEnabled = enabled;
      this.callbacks.cmcdEnabledChanged(enabled);
    }
  }

  updatePlayingAnalyticsDisabled(disabled) {
    if (isVariableDefinedNotNull(disabled)) {
      if (this.playingAnalyticsDisabled !== disabled) {
        log('LIVE', 'playing analytics disabled', disabled);

        this.props.playingAnalyticsDisabled = disabled;
        this.callbacks.playingAnalyticsDisabledChanged(this.playingAnalyticsDisabled);
      }
    } else if (this.playingAnalyticsDisabled) {
      log('LIVE', 'playing analytics disabled', false);

      this.props.playingAnalyticsDisabled = false;
      this.callbacks.playingAnalyticsDisabledChanged(this.playingAnalyticsDisabled);
    }
  }

  processNewSlides(slides) {
    if (isVariableDefinedNotNull(slides) && slides.length > 0) {
      this.callbacks.newSlides(slides);

      const last = slides[slides.length - 1];
      this.props.lastSlideTimestamp = last.timestamp;
      this.props.lastSlideVersion = last.version;
    }
  }

  updateThumbnail(thumbnail) {
    if (isVariableDefinedNotNull(thumbnail)) {
      if (this.props.thumbnail !== thumbnail) {
        log('LIVE', 'thumbnail', thumbnail);

        this.props.thumbnail = thumbnail;
        this.callbacks.thumbnailChanged(this.props.thumbnail);
      }
    }
  }

  destroy() {
    this.props.destroyed = true;

    if (this.props.interval) {
      clearTimeout(this.props.interval);
      this.props.interval = null;
    }
  }

  get delay() {
    return this.props.delay || 0;
  }

  get subtitlesEnabled() {
    return this.props.subtitlesEnabled;
  }

  get subtitlesDelayMs() {
    return this.props.subtitlesDelayMs || 0;
  }

  get streamStart() {
    return (
      this.props.streamStartFromVideo ||
      this.props.actualStreamStartFromServer ||
      this.props.scheduledStreamStartFromServer ||
      0
    );
  }

  get appStart() {
    return this.props.appStart || 0;
  }

  get showSlides() {
    return this.props.showSlides;
  }

  get liveFinished() {
    return this.props.liveFinished || false;
  }

  get servers() {
    return this.props.servers;
  }

  get playingAnalyticsDisabled() {
    return this.props.playingAnalyticsDisabled || false;
  }
}
