import { addListener, hide, isVariableDefinedNotNull, show } from '@slideslive/fuse-kit/utils';
import formatTime from 'modules/player/format_time';
import moment from 'moment-timezone';

import Popup from './popup';

const INITIAL_PROPS = {
  reviewNote: null,
};

export default class ReviewNotePopup extends Popup {
  constructor(popup, options) {
    super(popup, options);

    if (!isVariableDefinedNotNull(popup)) {
      return;
    }

    this.props = { ...INITIAL_PROPS };

    this.timeElement = this.findTarget('reviewNoteTime');
    this.commentsElement = this.findTarget('reviewNoteComments');
    this.formElement = this.findTarget('reviewNoteCommentForm');

    this.commentInputElement = this.findTarget('reviewNoteComment');

    addListener(this.findTarget('closeReviewNotePopup'), 'click', this.close.bind(this));
    addListener(this.findTarget('resolveReviewNote'), 'click', this.resolveReviewNote.bind(this));
    addListener(this.formElement, 'submit', this.sendReviewNoteCommentFromSubmitEvent.bind(this));

    addListener(this.commentInputElement, 'keyup', this.handleKeyUpInCommentInput.bind(this));
  }

  open(reviewNote) {
    if (!isVariableDefinedNotNull(this.popup) || !isVariableDefinedNotNull(reviewNote)) {
      return;
    }

    if (this.reviewNote && this.reviewNote.id === reviewNote.id) {
      if (this.commentText === '') {
        this.close();
      } else {
        this.render();
      }

      return;
    }

    if (this.reviewNote) {
      if (this.commentText !== '') {
        this._sendReviewNoteComment();
      }

      this.resetCommentText();

      this.props.reviewNote = null;
    }

    this.resetCommentText();

    this.props.reviewNote = reviewNote;

    this.render();
    super.open();
  }

  close() {
    if (!isVariableDefinedNotNull(this.popup)) {
      return;
    }

    this.resetCommentText();
    super.close();

    this.props.reviewNote = null;
  }

  updateOpenedReviewNote(reviewNotes) {
    if (!this.reviewNote) {
      return;
    }

    for (const updatedReviewNote of reviewNotes) {
      if (updatedReviewNote.id === this.reviewNote.id) {
        this.props.reviewNote = updatedReviewNote;
        this.render();

        return;
      }
    }

    this.close();
  }

  handleKeyUpInCommentInput(event) {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    if (event.keyCode === 27) {
      event.preventDefault();
      this.close();
    }
  }

  resolveReviewNote(event) {
    event.preventDefault();

    this._resolveReviewNote();
    this.resetCommentText();

    this.close();
  }

  sendReviewNoteCommentFromSubmitEvent(event) {
    event.preventDefault();

    if (this.commentText === '') {
      return;
    }

    this._sendReviewNoteComment();
    this.resetCommentText();

    // this.close();
  }

  render() {
    this.timeElement.textContent = formatTime(this.reviewNote.time_ms / 1000.0);

    let commentsHtml = '';

    for (const comment of this.reviewNote.comments) {
      commentsHtml += '<li class="slp__popup-reviewNote__comment">';
      commentsHtml += '<div class="slp__popup-reviewNote__comment__header">';
      commentsHtml += moment(comment.created_at).format('LLL');
      commentsHtml += ' &middot; ';
      commentsHtml += comment.commenter_name;
      commentsHtml += '</div>';
      commentsHtml += `<div class="slp__popup-reviewNote__comment__text">${comment.text}</div>`;
      commentsHtml += '</li>';
    }

    this.commentsElement.innerHTML = commentsHtml;

    if (this.reviewNote.state === 'resolved') {
      hide(this.formElement);
    } else {
      show(this.formElement, { display: 'flex' });
    }
  }

  resetCommentText() {
    if (!this.commentInputElement) return;

    this.commentInputElement.value = '';
  }

  _resolveReviewNote() {
    this.options.callbacks.resolveReviewNote({ reviewNoteId: this.reviewNote.id, commentText: this.commentText });
  }

  _sendReviewNoteComment() {
    this.options.callbacks.sendReviewNoteComment({ reviewNoteId: this.reviewNote.id, commentText: this.commentText });
  }

  get commentText() {
    if (!this.commentInputElement) return '';

    return this.commentInputElement.value;
  }

  get reviewNote() {
    return this.props.reviewNote;
  }
}
