import { addListener, isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';

export default class Popup {
  constructor(popup, options, otherConnectedElements = []) {
    if (!isVariableDefinedNotNull(popup)) {
      return;
    }

    this.popup = popup;
    this.options = options;
    this.otherConnectedElements = otherConnectedElements;

    addListener(
      this.popup.parentNode,
      'click',
      `[data-slp-open-popup="${this.popupName}"]:not([disabled])`,
      this.openFromEvent.bind(this),
    );
  }

  fireChangeCallback() {
    const visible = this.popup.classList.contains('slp__popup--active');

    this.options.callbacks.change(visible);
  }

  close() {
    if (!isVariableDefinedNotNull(this.popup) || !this.popup.classList.contains('slp__popup--active')) {
      return;
    }

    log('POPUP', 'close', this.popupName);

    this.popup.classList.remove('slp__popup--active');
    this.fireChangeCallback();
  }

  openFromEvent(event) {
    event.preventDefault();

    this.open();
  }

  open() {
    if (!isVariableDefinedNotNull(this.popup)) {
      return;
    }

    log('POPUP', 'open', this.popupName);

    for (const popup of this.popup.parentNode.querySelectorAll('[data-slp-target$="Popup"]')) {
      const popupName = popup.getAttribute('data-slp-target');
      const show = this.popupName === popupName;

      popup.classList.toggle('slp__popup--active', show);
    }

    this.fireChangeCallback();
  }

  findTarget(target) {
    return this.popup.querySelector(`[data-slp-target="${target}"]`);
  }

  get popupName() {
    return this.popup.getAttribute('data-slp-target');
  }
}
