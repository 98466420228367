import { addListener, isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';

export default class Controls {
  constructor(element, options, toggleControlsCallback) {
    this.element = element;
    this.options = options;
    this.toggleControlsCallback = toggleControlsCallback;

    this.props = {
      visible: true,
      menuOpen: false,
      modalOpen: false,
      playing: false,
      hideTimer: null,
      visibleTimeout: null,
    };

    this.addListeners(this.element);
  }

  addListeners(element) {
    addListener(element, 'mouseenter', (event) => this.mouseEnter(event));
    addListener(element, 'mousemove', (event) => this.mouseMove(event));
    addListener(element, 'mouseleave', (event) => this.mouseLeave(event));
  }

  isControlsElement(element) {
    return (
      element.dataset.slpTarget === 'videoControls' ||
      element.dataset.slpTarget === 'slidesControls' ||
      element.dataset.slpTarget === 'settingsMenu' ||
      element.dataset.slpTarget === 'liveSlidesVideoControls' ||
      element.dataset.slpTarget === 'liveSlidesVideoMenuSettings'
    );
  }

  mouseEnter(event) {
    if (event.target !== this.element) {
      if (this.isControlsElement(event.target)) {
        this.mouseInControls = true;

        log('CONTROLS', 'mouse in controls');
      }

      return;
    }

    this.mouseInPlayer = true;

    log('CONTROLS', 'mouse in player');
  }

  // eslint-disable-next-line no-unused-vars
  mouseMove(event) {
    if (this.props.mouseInPlayer && !this.props.visible) {
      this.visible = true;
    }

    this.refresh();
  }

  mouseLeave(event) {
    if (event.target !== this.element) {
      if (this.isControlsElement(event.target)) {
        this.mouseInControls = false;

        log('CONTROLS', 'mouse out controls');
      }

      return;
    }

    this.mouseInPlayer = false;

    log('CONTROLS', 'mouse out player');
  }

  refresh() {
    this.updateHideTimer();
  }

  updateHideTimer() {
    // log('CONTROLS', 'refresh');

    if (isVariableDefinedNotNull(this.props.hideTimer)) {
      clearTimeout(this.props.hideTimer);
      this.props.hideTimer = null;
    }

    if (this.props.visible && this.canHide) {
      if (this.props.mouseInPlayer) {
        this.props.hideTimer = setTimeout(() => this.hideTimerCallback(), 3000);
      } else {
        this.visible = false;
      }
    }
  }

  hideTimerCallback() {
    if (this.canHide) {
      this.visible = false;
    }
  }

  set visible(value) {
    log('CONTROLS', 'set visible', value);

    if (this.props.visibleTimeout) {
      clearTimeout(this.props.visibleTimeout);
      this.props.visibleTimeout = null;
    }

    const actuallySet = () => {
      this.props.visible = value;
      this.toggleControlsCallback(this.props.visible);

      this.updateHideTimer();
    };

    if (value) {
      actuallySet();
    } else {
      this.props.visibleTimeout = setTimeout(actuallySet, 100);
    }
  }

  get canHide() {
    return (
      !this.props.mouseInControls &&
      !this.props.menuOpen &&
      !this.props.modalOpen &&
      (this.props.playing || (!this.props.mouseInPlayer && this.options.allowHiddenControlsWhenPaused))
    );
  }

  set mouseInPlayer(value) {
    this.props.mouseInPlayer = value;

    if (value) {
      this.visible = true;
    }

    this.refresh();
  }

  set mouseInControls(value) {
    this.props.mouseInControls = value;

    if (value) {
      this.visible = true;
    }

    this.refresh();
  }

  set menuOpen(value) {
    this.props.menuOpen = value;

    if (value) {
      this.visible = true;
    }

    this.refresh();
  }

  set modalOpen(value) {
    this.props.modalOpen = value;

    if (value > 0) {
      this.visible = true;
    }

    this.refresh();
  }

  set playing(value) {
    this.props.playing = value;

    if (!value) {
      this.visible = true;
    }

    this.refresh();
  }
}
