import { addListener, isVariableDefinedNotNull, remove } from '@slideslive/fuse-kit/utils';
import formatTime from 'modules/player/format_time';
import getSpinnerInContainerAsElement from 'plugins/spinner/get_spinner_in_container_as_element';

import Modal from './modal';

const INITIAL_PROPS = {
  editing: {},
};

export default class BookmarksModal extends Modal {
  constructor(modal, options, initializeLocalization, setNewLocalizationOnTarget) {
    super(modal, options);

    if (!isVariableDefinedNotNull(modal)) {
      return;
    }

    this.props = { ...INITIAL_PROPS };

    this.initializeLocalization = initializeLocalization;
    this.setNewLocalizationOnTarget = setNewLocalizationOnTarget;
    this.content = this.modal.querySelector('[data-slp-target="modalContent"]');

    this.originalContentHTML = this.content.innerHTML;

    addListener(
      this.content,
      'submit',
      '[data-slp-target^="updateBookmarkForm-"]',
      this.handleEditBookmarkFormSubmit.bind(this),
    );
    addListener(this.content, 'click', '[data-slp-target^="updateBookmark-"]', this.handleUpdateFromEvent.bind(this));
    addListener(this.content, 'click', '[data-slp-target^="removeBookmark-"]', this.handleRemoveFromEvent.bind(this));
    addListener(this.content, 'click', '[data-slp-target^="seekTo-"]', this.handleSeekToFromEvent.bind(this));
  }

  updateBookmarksList(list) {
    let html = this.originalContentHTML;

    for (let i = 0; i < list.length; i++) {
      html += this.itemHTML(list[i]);
    }

    this.content.innerHTML = html;

    this.updateEditing();
    this.initializeLocalization(this.content);
  }

  updateEditing() {
    const ids = Object.keys(this.editing);

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];

      if (this.editing[id]) {
        const button = this.content.querySelector(`[data-slp-target="updateBookmark-${id}"]`);
        const noteElement = this.content.querySelector(`[data-slp-target="bookmarkNote-${id}"]`);

        this.enableEditing(button, id, noteElement);
      }
    }
  }

  updateBookmark(data) {
    const noteElement = this.content.querySelector(`[data-slp-target="bookmarkNote-${data.id}"]`);
    noteElement.value = data.note;

    this.modal.focus();
  }

  removeBookmark(id) {
    let itemElement = this.content.querySelector(`[data-slp-target="updateBookmarkForm-${id}"]`);

    if (!itemElement) {
      return;
    }

    itemElement = itemElement.parentNode;
    remove(itemElement);

    delete this.editing[id];

    this.modal.focus();
  }

  setBookmarkLoading(id, show) {
    const target = this.content.querySelector(`[data-slp-target="updateBookmarkForm-${id}"]`);

    if (!target) {
      return;
    }

    if (!show) {
      if (isVariableDefinedNotNull(target.spinnerElement)) {
        remove(target.spinnerElement);
      }

      target.disabled = false;
      return;
    }

    if (!isVariableDefinedNotNull(target.spinnerElement)) {
      const spinner = getSpinnerInContainerAsElement({ tiny: true, absoluteWrapper: true });
      target.spinnerElement = spinner;
    }

    target.disabled = true;
    target.insertAdjacentElement('afterbegin', target.spinnerElement);
  }

  enableEditing(button, id, noteElement) {
    this.editing[id] = true;

    button.innerHTML = this.options.icons.save;
    this.setNewLocalizationOnTarget(button, 'save');

    noteElement.readOnly = false;
    noteElement.focus();
  }

  disableEditing(button, id, noteElement) {
    this.editing[id] = false;

    button.innerHTML = this.options.icons.edit;
    this.setNewLocalizationOnTarget(button, 'edit');

    noteElement.readOnly = true;
  }

  handleEditBookmarkFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const trigger = event.selectorTarget;
    const id = trigger.getAttribute('data-id');

    this.handleUpdate(id);

    this.modal.focus();
  }

  handleUpdateFromEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    const trigger = event.selectorTarget;
    const id = trigger.getAttribute('data-id');

    this.handleUpdate(id, trigger);
  }

  handleUpdate(id, button) {
    button = button || this.content.querySelector(`[data-slp-target="updateBookmark-${id}"]`);
    const noteElement = this.content.querySelector(`[data-slp-target="bookmarkNote-${id}"]`);

    if (!this.editing[id]) {
      this.enableEditing(button, id, noteElement);
      return;
    }

    this.disableEditing(button, id, noteElement);
    this.options.callbacks.update({ id, note: noteElement.value.trim() });
  }

  handleRemoveFromEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    const trigger = event.selectorTarget;
    const id = trigger.getAttribute('data-id');

    this.handleRemove(id);
  }

  handleRemove(id) {
    this.options.callbacks.remove(id);
  }

  handleSeekToFromEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    const trigger = event.selectorTarget;
    const time = parseFloat(trigger.getAttribute('data-time')) - 2000;

    this.options.callbacks.seekTo(time);
    this.close();
  }

  itemHTML(bookmark) {
    let html = '<li class="slp__modal-bookmarks__item">';
    html += '  <button';
    html += '    class="slp__modal-bookmarks__seekTo"';
    html += `    data-slp-target="seekTo-${bookmark.id}"`;
    html += `    data-time="${bookmark.current_time * 1000}">`;
    html += '    <span class="slp__modal-bookmarks__icon">';
    html += this.options.icons.bookmark;
    html += '    </span>';
    html += '    <span class="slp__modal-bookmarks__time text--right">';
    html += formatTime(bookmark.current_time);
    html += '    </span>';
    html += '  </button>';
    html += '  <form';
    html += '    class="slp__modal-bookmarks__form"';
    html += `    data-id="${bookmark.id}"`;
    html += `    data-slp-target="updateBookmarkForm-${bookmark.id}">`;
    html += '    <div class="fieldLine__container fieldLine__container--light fieldLine__container--noLabel">';
    html += '      <input';
    html += '        class="fieldLine"';
    html += `        value="${bookmark.note}"`;
    html += `        data-slp-target="bookmarkNote-${bookmark.id}"`;
    html += '        readonly="true"';
    html += '>';
    html += '      <svg class="graphicLine" viewBox="0 0 1200 60" preserveAspectRatio="none">';
    html +=
      '        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>';
    html += '      </svg>';
    html += '    </div>';
    html += '    <button';
    html += '      data-slp-title="edit"';
    html += '      class="slp__modal-bookmarks__button slp__modal-bookmarks__button--edit"';
    html += `      data-id="${bookmark.id}"`;
    html += `      data-slp-target="updateBookmark-${bookmark.id}">`;
    html += this.options.icons.edit;
    html += '    </button>';
    html += '    <button';
    html += '      data-slp-title="remove"';
    html += '      class="slp__modal-bookmarks__button slp__modal-bookmarks__button--remove"';
    html += `      data-id="${bookmark.id}"`;
    html += `      data-slp-target="removeBookmark-${bookmark.id}">`;
    html += this.options.icons.remove;
    html += '    </button>';
    html += '  </form>';
    html += '</li>';

    return html;
  }

  get editing() {
    return this.props.editing;
  }

  set editing(editing) {
    this.props.editing = editing;
  }
}
