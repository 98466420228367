import { defer, isVariableDefinedNotNull, now } from '@slideslive/fuse-kit/utils';

export default class LivePlaybackController {
  constructor(speakerVideo, slidesVideo, callbacks) {
    this.videoPlayer = speakerVideo;
    this.slidesVideoStreamPlayer = slidesVideo;
    this.callbacks = callbacks;

    this.props = {
      loading: false,
      lastAction: null,
      state: '',
      playFailed: false,
      slidesPlayFailed: false,
      playbackRate: 1,
      lastFiniteSyncLoopAt: 0,
    };

    this.slidesVideoTimeDirty = false;
    this.syncLoopInterval = null;

    this.connectCallbacks();
    this.restartSyncLoop();
  }

  play() {
    this.playFailed = false;
    this.slidesPlayFailed = false;

    this.props.lastAction = 'play';
    this.state = 'playing';

    this.videoPlayer.play();
  }

  pause() {
    this.props.lastAction = 'pause';
    this.state = 'paused';

    this.videoPlayer.pause();
  }

  // eslint-disable-next-line no-unused-vars
  updateSpeakerVideoLoading(loading) {}

  // eslint-disable-next-line no-unused-vars
  updateSpeakerVideoState(state) {}

  // eslint-disable-next-line no-unused-vars
  updateSlideVideoPlayer(player, playing) {}

  // eslint-disable-next-line no-unused-vars
  updateSlideVideoLoading(loading) {}

  // eslint-disable-next-line no-unused-vars
  updateSlideVideoState(state) {}

  // eslint-disable-next-line no-unused-vars
  updatePlaybackRate(playbackRate) {}

  connectCallbacks() {
    this.videoPlayer.on('ready', () => {
      this.restartSyncLoop();
    });

    this.videoPlayer.on('loadingChanged', (loading) => {
      this.loading = loading;
      this.toggleSlidesPlayback();
    });

    this.videoPlayer.on('stateChanged', (state) => {
      this.state = state;
      this.toggleSlidesPlayback();
    });

    this.videoPlayer.on('seeked', () => {
      if (this.slidesVideoStreamPlayer && this.slidesVideoStreamPlayer.useHlsJs) {
        this.seekSlidesToSpeakerVideo({ force: !this.playing });
      }
    });
    this.videoPlayer.on('qualityChanged', () => this.seekSlidesToSpeakerVideo());
    this.videoPlayer.on('playbackServerChanged', () => this.seekSlidesToSpeakerVideo());

    if (this.slidesVideoStreamPlayer) {
      this.slidesVideoStreamPlayer.on('loadingChanged', () => this.toggleSlidesPlayback());
      this.slidesVideoStreamPlayer.on('qualityChanged', () => this.seekSlidesToSpeakerVideo());
      this.slidesVideoStreamPlayer.on('playbackServerChanged', () => this.seekSlidesToSpeakerVideo());

      this.slidesVideoStreamPlayer.on('programDateTimeChanged', () => {
        this.slidesVideoTimeDirty = false;
      });
    }
  }

  restartSyncLoop(interval = 100) {
    if (!this.slidesVideoStreamPlayer) return;

    this.stopSyncLoop();
    this.syncLoopInterval = setInterval(this.syncLoop.bind(this), interval);
  }

  stopSyncLoop() {
    if (this.syncLoop) {
      clearInterval(this.syncLoopInterval);
      this.syncLoopInterval = null;
    }
  }

  syncLoop() {
    const newDiff = this.diff;
    this.callbacks.diffChanged(newDiff);

    if (!Number.isFinite(newDiff)) {
      if (!isVariableDefinedNotNull(this.props.lastFiniteSyncLoopAt) || this.props.lastFiniteSyncLoopAt > 0) {
        const sinceLastFiniteSyncLoop = now() - this.props.lastFiniteSyncLoopAt;
        if (sinceLastFiniteSyncLoop > 5000) {
          this.seekSlidesToSpeakerVideo({ force: true });
          this.props.lastFiniteSyncLoopAt = now();
        }
      }

      return;
    }

    this.props.lastFiniteSyncLoopAt = now();

    if (Math.abs(newDiff) > 4000 || (!this.playing && Math.abs(newDiff) > 1000)) {
      this.seekSlidesToSpeakerVideo({ force: true });
      return;
    }

    if (!this.playing) return;

    const calcPlaybackRate = (basePlaybackRate, currentPlaybackRate, diff) => {
      // diff is > 0 when slides have higher time (speaker - slides)
      // > 0 means slides have to pick up the pace to catch up
      // < 0 means slides have to slow down for speaker video to catch up

      const playbackRateChangeCoeff = 0.5;
      const timeBufferForPlaybackRateChange = this.slidesVideoStreamPlayer.useHlsJs ? 300 : 600;
      const thresholdForPlaybackRateChange = 1000;

      if (currentPlaybackRate > basePlaybackRate) {
        // when slides are catching up to the speaker video allow them to be in front of speaker video by X ms
        // we create a time buffer (X ms) to allow playback rate to change, playback rate change has a delay

        if (diff > -timeBufferForPlaybackRateChange) {
          return basePlaybackRate + playbackRateChangeCoeff * basePlaybackRate;
        }
      } else if (currentPlaybackRate < basePlaybackRate) {
        // when slides are waiting for the speaker video allow them to be behind speaker video by X ms
        // we create a time buffer (X ms) to allow playback rate to change, playback rate change has a delay

        if (diff < timeBufferForPlaybackRateChange) {
          return basePlaybackRate - playbackRateChangeCoeff * basePlaybackRate;
        }
      } else if (Math.abs(diff) > thresholdForPlaybackRateChange) {
        const diffSign = diff < 0 ? -1 : 1;
        return basePlaybackRate + diffSign * playbackRateChangeCoeff * basePlaybackRate;
      }

      return basePlaybackRate;
    };
    const slidesPlaybackRate = calcPlaybackRate(
      this.videoPlayer.realPlaybackRate,
      this.slidesVideoStreamPlayer.realPlaybackRate,
      newDiff,
    );
    if (this.slidesVideoStreamPlayer.realPlaybackRate !== slidesPlaybackRate) {
      if (slidesPlaybackRate > this.slidesVideoStreamPlayer.realPlaybackRate) {
        // eslint-disable-next-line no-console
        console.log(
          'SYNC',
          `slides catch up: diff = ${newDiff} ms, speed = ${this.slidesVideoStreamPlayer.realPlaybackRate}x -> ${slidesPlaybackRate}x`,
        );
      } else {
        // eslint-disable-next-line no-console
        console.log(
          'SYNC',
          `slides slow down: diff = ${newDiff} ms, speed = ${this.slidesVideoStreamPlayer.realPlaybackRate}x -> ${slidesPlaybackRate}x`,
        );
      }

      this.slidesVideoStreamPlayer.realPlaybackRate = slidesPlaybackRate;
    }
  }

  seekSlidesToSpeakerVideo({ force = false } = {}) {
    if (!this.videoPlayer.ready || !this.slidesVideoStreamPlayer || !this.slidesVideoStreamPlayer.ready) {
      return;
    }

    this.printTimesToConsole();

    const diff = this.diff;
    if (!Number.isFinite(diff)) {
      if (force) {
        // eslint-disable-next-line no-console
        console.log('SYNC', 'seek to video current time, diff is not finite');

        this.slidesVideoStreamPlayer.currentTime = Math.max(
          0,
          this.videoPlayer.currentTime + (Math.random() * 200 - 100),
        );

        this.toggleSlidesPlayback();
      } else {
        // eslint-disable-next-line no-console
        console.log('SYNC', `seek skipped, diff is not finite`);
      }

      return;
    }

    const SEEK_DIFF_THRESHOLD = 1000;
    if (!force && Math.abs(diff) < SEEK_DIFF_THRESHOLD) {
      // eslint-disable-next-line no-console
      console.log('SYNC', `seek skipped, diff is less than ${SEEK_DIFF_THRESHOLD} ms`);
      return;
    }

    const diffSign = diff < 0 ? -1 : 1;
    const diffToSeek = diffSign * Math.max(100, Math.abs(diff));
    const seekTo = this.slidesVideoStreamPlayer.currentTime + diffToSeek;

    // eslint-disable-next-line no-console
    console.log('SYNC', `seek slides from ${this.slidesVideoStreamPlayer.currentTime} to ${seekTo} by ${diffToSeek}`);

    this.slidesVideoTimeDirty = true;
    this.slidesVideoStreamPlayer.currentTime = seekTo;

    this.toggleSlidesPlayback();
  }

  printTimesToConsole() {
    const speakerVideoTimeString = isVariableDefinedNotNull(this.speakerVideoTime)
      ? new Date(this.speakerVideoTime).toISOString()
      : 'N/A';
    const slidesVideoTimeString = isVariableDefinedNotNull(this.slidesVideoTime)
      ? new Date(this.slidesVideoTime).toISOString()
      : 'N/A';

    // eslint-disable-next-line no-console
    console.log(
      'SYNC',
      'speaker times:',
      `ready = ${this.videoPlayer.ready.toString().padStart(5, ' ')},`,
      `current time = ${this.videoPlayer.currentTime.toFixed(3).toString().padStart(16, ' ')},`,
      `program date time = ${speakerVideoTimeString}`,
    );

    // eslint-disable-next-line no-console
    console.log(
      'SYNC',
      'slides times: ',
      `ready = ${this.slidesVideoStreamPlayer.ready.toString().padStart(5, ' ')},`,
      `current time = ${this.slidesVideoStreamPlayer.currentTime.toFixed(3).toString().padStart(16, ' ')},`,
      `program date time = ${slidesVideoTimeString}`,
    );
  }

  toggleSlidesPlayback() {
    if (!this.videoPlayer.ready) return;
    if (!this.slidesVideoStreamPlayer || !this.slidesVideoStreamPlayer.ready) return;

    if (
      this.videoPlayer.isPlaying &&
      !this.videoPlayer.loading &&
      !this.slidesVideoStreamPlayer.isPlaying &&
      !this.slidesVideoStreamPlayer.loading
    ) {
      this.slidesVideoStreamPlayer.play();
    } else if (!this.videoPlayer.isPlaying && this.slidesVideoStreamPlayer.isPlaying) {
      this.slidesVideoStreamPlayer.pause();
    }
  }

  get diff() {
    const speakerNow = this.speakerVideoTime;
    const slidesNow = this.slidesVideoTime;
    const diff = speakerNow - slidesNow;

    if (speakerNow === null || slidesNow === null || !Number.isFinite(diff)) return NaN;

    return diff;
  }

  get playing() {
    return this.props.state === 'playing';
  }

  get loading() {
    return this.videoPlayer.loading;
  }

  set loading(loading) {
    if (this.props.loading !== loading) {
      this.props.loading = loading;
      defer(() => this.callbacks.loadingChanged(loading));
    }
  }

  get playbackRate() {
    return this.props.playbackRate;
  }

  set playbackRate(value) {
    if (value === this.playbackRate) {
      return;
    }

    this.props.playbackRate = value;
    this.videoPlayer.playbackRate = value;

    this.syncLoop();
  }

  set state(state) {
    if (this.props.state !== state) {
      this.props.state = state;
      this.callbacks.stateChanged(state);
    }
  }

  set playFailed(value) {
    this.props.playFailed = value;

    if (value) {
      this.pause();
    }
  }

  set slidesPlayFailed(value) {
    this.props.slidesPlayFailed = value;
  }

  get speakerVideoTime() {
    return this.videoPlayer.programDateTime;
  }

  get slidesVideoTime() {
    if (!this.slidesVideoStreamPlayer) return undefined;
    if (!Number.isFinite(this.slidesVideoStreamPlayer.programDateTime)) return undefined;
    if (this.slidesVideoTimeDirty) return undefined;

    return this.slidesVideoStreamPlayer.programDateTime;
  }
}
