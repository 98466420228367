import { isVariableDefinedNotNull, parseTime, urlParam } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';

export default class PlayerOptions {
  constructor(playerElement, options) {
    this.element = playerElement;
    this.options = {};

    this.addOptions(options);

    if (this.options.offline) {
      this.options.disableTracking = true;
    }

    log('OPTIONS', this.data);
  }

  addOptions(options) {
    let benBaseUrl;
    const benBaseUrlTag = document.querySelector('meta[name="ben-base-url"]');
    if (isVariableDefinedNotNull(benBaseUrlTag)) {
      benBaseUrl = benBaseUrlTag.content;
    }

    this.setOption('benBaseUrl', benBaseUrl || '');

    this.addStringOption(options, 'presentationId', undefined, 'presentationId');
    this.addBooleanOption(options, 'offline', false);

    this.addBooleanOption(options, 'disableTracking', false);
    this.addBooleanOption(options, 'disableGoogleAnalytics', false);
    this.addBooleanOption(options, 'showThumbnail', true);
    this.addBooleanOption(options, 'editor', false);
    this.addIntegerOption(options, 'maxHeight');
    this.addBooleanOption(options, 'fitToViewport', true, 'fitToViewport');

    this.addBooleanOption(options, 'autoLoad', true, 'autoLoad');
    this.addBooleanOption(options, 'autoPlay', false, 'autoPlay');

    this.addTimeOption(options, 'startTime', 0, 'startTime', 'time');
    this.addIntegerOption(options, 'startSlide', 0, 'startSlide', 'slide');

    this.addBooleanOption(options, 'hideTitle', false);
    this.addBooleanOption(options, 'titleLink', true);
    this.addBooleanOption(options, 'disableFullscreen', false, 'disableFullscreen');

    this.addBooleanOption(options, 'hideZoomControls', false, 'hideZoomControls');
    this.addFloatOption(options, 'zoomRatio', undefined, 'zoomRatio');
    this.addStringOption(options, 'zoomSliderColor', undefined, 'zoomSliderColor');
    this.addStringOption(options, 'zoomHandleColor', undefined, 'zoomHandleColor');

    this.addStringOption(options, 'locale', 'en', 'locale');

    this.addBooleanOption(options, 'verticalEnabled', window === window.parent, 'verticalEnabled');
    this.addBooleanOption(options, 'verticalEnabledOnMobile', window === window.parent, 'verticalEnabledOnMobile');
    this.addIntegerOption(options, 'verticalDisabledWhenWidthGte', 768, 'verticalDisabledWhenWidthGte');
    this.addIntegerOption(options, 'verticalWhenWidthLte', null, 'verticalWhenWidthLte');

    this.addBooleanOption(options, 'hideBigPlayButton', false, 'hideBigPlayButton');
    this.addBooleanOption(options, 'allowHiddenControlsWhenPaused', false, 'allowHiddenControlsWhenPaused');

    this.addStringOption(options, 'registrationId', undefined, 'registrationId');
    this.addStringOption(options, 'reviewToken', undefined, 'reviewToken');

    this.addStringOption(options, 'defaultStreamQuality', undefined, 'defaultStreamQuality', 'default_stream_quality');

    this.addBooleanOption(
      options,
      'originalVideoControls',
      false,
      undefined,
      'i_really_want_to_use_original_video_controls',
    );

    this.addBooleanOption(options, 'm3u8', false, undefined, 'm3u8');
    this.addStringOption(options, 'playerErrorReportUrl', undefined, 'playerErrorReportUrl');
    this.addStringOption(options, 'analyticsPlayerSessionsIngestUrl', undefined, 'analyticsPlayerSessionsIngestUrl');
    this.addStringOption(
      options,
      'analyticsPlayerSessionEventsIngestUrl',
      undefined,
      'analyticsPlayerSessionEventsIngestUrl',
    );

    this.addBooleanOption(options, 'canReviewPresentation', false, 'canReviewPresentation');
    this.addStringOption(options, 'presentationReviewNotesUrl', undefined, 'presentationReviewNotesUrl');
    this.addStringOption(options, 'resolvePresentationReviewNoteUrl', undefined, 'presentationResolveReviewNoteUrl');
    this.addStringOption(options, 'commentPresentationReviewNoteUrl', undefined, 'presentationCommentReviewNoteUrl');

    this.addStringOption(options, 'cdnOverride', undefined, undefined, 'cdnOverride');
    this.addStringOption(options, 'countryOverride', undefined, undefined, 'country_override');

    this.addStringOption(options, 'playerToken', undefined, 'playerToken');
    this.addStringOption(options, 'source', undefined, 'source');
    this.addStringOption(options, 'analyticsSessionToken', undefined, 'analyticsSessionToken');
    this.addStringOption(options, 'analyticsUserUuid', undefined, 'analyticsUserUuid');
    this.addStringOption(options, 'analyticsSessionUuid', undefined, 'analyticsSessionUuid');

    this.addStringOption(options, 'mode', 'video_slideshow', 'mode');
    this.addStringOption(options, 'thumbnailUrl', null, 'thumbnailUrl');
    this.addStringOption(options, 'slidesVideoServiceDataUrl', null, 'slidesVideoServiceDataUrl');
  }

  setOption(name, value) {
    this.options[name] = value;

    Object.defineProperty(this, name, {
      get: () => this.options[name],
    });
  }

  getRawOption(options, name, datasetName, urlParamName) {
    let value;

    if (
      isVariableDefinedNotNull(options) &&
      isVariableDefinedNotNull(name) &&
      isVariableDefinedNotNull(options[name])
    ) {
      value = options[name];
    }

    if (isVariableDefinedNotNull(datasetName) && isVariableDefinedNotNull(this.element.dataset[datasetName])) {
      value = this.element.dataset[datasetName];
    }

    if (isVariableDefinedNotNull(urlParamName)) {
      const urlParamValue = urlParam(urlParamName);
      if (isVariableDefinedNotNull(urlParamValue)) {
        value = urlParamValue;
      }
    }

    return value;
  }

  addStringOption(options, name, defaultValue, datasetName, urlParamName) {
    let value = this.getRawOption(options, name, datasetName, urlParamName);
    if (!isVariableDefinedNotNull(value)) {
      value = defaultValue;
    }
    this.setOption(name, value);
  }

  addBooleanOption(options, name, defaultValue, datasetName, urlParamName) {
    let value = this.getRawOption(options, name, datasetName, urlParamName);
    if (!isVariableDefinedNotNull(value)) {
      value = defaultValue;
    } else {
      value =
        value === true ||
        (typeof value.toLowerCase === 'function' && (value.toLowerCase() === 'true' || value.toLowerCase() === 'yes'));
    }
    this.setOption(name, value);
  }

  addIntegerOption(options, name, defaultValue, datasetName, urlParamName) {
    let value = this.getRawOption(options, name, datasetName, urlParamName);
    if (!isVariableDefinedNotNull(value)) {
      value = defaultValue;
    } else {
      value = parseInt(value, 10);
    }
    this.setOption(name, value);
  }

  addFloatOption(options, name, defaultValue, datasetName, urlParamName) {
    let value = this.getRawOption(options, name, datasetName, urlParamName);
    if (!isVariableDefinedNotNull(value)) {
      value = defaultValue;
    } else {
      value = parseFloat(value);
    }
    this.setOption(name, value);
  }

  addTimeOption(options, name, defaultValue, datasetName, urlParamName) {
    let value = this.getRawOption(options, name, datasetName, urlParamName);
    if (!isVariableDefinedNotNull(value)) {
      value = defaultValue;
    } else {
      value = parseTime(value) * 1000;
    }
    this.setOption(name, value);
  }

  get data() {
    return this.options;
  }
}
