import {
  addListener,
  getWidth,
  isMobileDevice,
  isVariableDefinedNotNull,
  viewportHeight,
  viewportWidth,
} from '@slideslive/fuse-kit/utils';

// 16/9 = 1.78
// 4/3 = 1.33

// display  | real
// 4/3      | 16/9
// 640x480  | 853x480

// 16/9     | 4/3
// 1280x720 | 1280x960

function calcVideoElementSize(w, h, displayVideoRatio, realVideoRatio) {
  let realVideoW;
  let realVideoH;
  if (displayVideoRatio > realVideoRatio) {
    // we want displayed video to be wider, lets scale its height
    realVideoW = w;
    realVideoH = h * (realVideoRatio / displayVideoRatio);
  } else if (displayVideoRatio < realVideoRatio) {
    realVideoW = w * (realVideoRatio / displayVideoRatio);
    realVideoH = h;
  } else {
    realVideoW = w;
    realVideoH = h;
  }

  return { w: realVideoW, h: realVideoH };
}

function calcSizeHorizontal(
  maxWidth,
  maxHeight,
  spacing,
  displayVideoRatio,
  realVideoRatio,
  slidesRatio,
  zoom,
  { zoomHidden = false } = {},
) {
  const zoomHeight = zoomHidden ? 0 : 40;

  maxWidth -= spacing;

  if (maxHeight && maxHeight < (maxWidth * 0.5) / displayVideoRatio + zoomHeight) {
    maxWidth = ((maxHeight - zoomHeight) * displayVideoRatio) / 0.5;
  }

  const videoWidth = Math.round(maxWidth * zoom);
  const videoHeight = videoWidth / displayVideoRatio;

  const slidesWidth = maxWidth - videoWidth;
  const slidesHeight = slidesWidth / slidesRatio;

  const totalWidth = videoWidth + slidesWidth + spacing;
  const totalHeight = Math.max(videoHeight, slidesHeight) + zoomHeight;

  const videoElementSize = calcVideoElementSize(videoWidth, videoHeight, displayVideoRatio, realVideoRatio);

  return {
    vertical: false,
    width: totalWidth,
    height: totalHeight,
    videoWidth,
    videoHeight,
    slidesWidth,
    slidesHeight,
    videoElementWidth: videoElementSize.w,
    videoElementHeight: videoElementSize.h,
  };
}

function calcSizeVertical(maxWidth, maxHeight, spacing, displayVideoRatio, realVideoRatio, slidesRatio) {
  let videoWidth = maxWidth;
  let videoHeight = videoWidth / displayVideoRatio;

  let slidesWidth = maxWidth;
  let slidesHeight = slidesWidth / slidesRatio;

  if (maxHeight && videoHeight + slidesHeight + spacing > maxHeight) {
    const onlyMediaHeight = maxHeight - spacing;

    slidesHeight = (onlyMediaHeight * displayVideoRatio) / (slidesRatio + displayVideoRatio);
    slidesWidth = slidesHeight * slidesRatio;

    videoHeight = onlyMediaHeight - slidesHeight;
    videoWidth = slidesWidth;
  }

  const totalWidth = videoWidth;
  const totalHeight = videoHeight + slidesHeight + spacing;

  const videoElementSize = calcVideoElementSize(videoWidth, videoHeight, displayVideoRatio, realVideoRatio);

  return {
    vertical: true,
    width: totalWidth,
    height: totalHeight,
    videoWidth,
    videoHeight,
    slidesWidth,
    slidesHeight,
    videoElementWidth: videoElementSize.w,
    videoElementHeight: videoElementSize.h,
  };
}

export default class SizeCalc {
  constructor(element, options, sizeChangedCallback, zoomRatioChangedCallback) {
    this.element = element;
    this.options = options;
    this.sizeChangedCallback = sizeChangedCallback;
    this.zoomRatioChangedCallback = zoomRatioChangedCallback;

    this.props = {
      mode: this.options.mode,
      realVideoRatio: 16 / 9.0,
      displayVideoRatio: null,
      slidesRatio: 16 / 9.0,
      zoom: 0.5,
      maxHeight: null,
      size: null,
      loading: false,
      fullscreen: false,
      updateTimeout: null,
      forceNextUpdate: false,
      message: false,
      compactDefault: false,
    };

    addListener(window, 'resize', () => this.updateSize());
  }

  updateSize(force = false, { withoutTimeout = false } = {}) {
    if (force) {
      this.props.forceNextUpdate = true;
    }

    if (withoutTimeout) {
      clearTimeout(this.props.updateTimeout);
      this.props.updateTimeout = null;

      this.doUpdateSize();
    } else if (!this.props.updateTimeout) {
      this.props.updateTimeout = setTimeout(() => this.doUpdateSize(), 0);
    }
  }

  doUpdateSize(force = false) {
    const f = this.props.forceNextUpdate || force;

    this.props.updateTimeout = null;
    this.props.forceNextUpdate = null;

    let size;
    if (this.props.compactDefault) {
      size = this.compactSize();
    } else {
      switch (this.props.mode) {
        case 'video_slideshow':
          size = this.hideSlides ? this.calcSizeForVideo() : this.calcSizeForVideoSlideshow();
          break;
        case 'video':
          size = this.calcSizeForVideo();
          break;
        case 'audio_slideshow':
          size = this.calcSizeForAudioSlideshow();
          break;
        case 'slideshow':
          size = this.calcSizeForSlideshow();
          break;
        default:
          size = this.calcSizeForVideoSlideshow();
          break;
      }
    }

    size = this.roundSize(size);

    if (size && (f || !this.areSizesEqual(this.props.size, size))) {
      this.props.size = size;
      this.sizeChangedCallback(this.props.size);
    }
  }

  compactSize() {
    const sizeWidth = 320;
    const sizeHeight = 320 / (16 / 9);

    return {
      width: sizeWidth,
      height: sizeHeight,
      videoWidth: 0,
      videoHeight: 0,
      slidesWidth: 0,
      slidesHeight: 0,
      spacing: 0,
    };
  }

  roundSize(size) {
    const roundedSize = {
      width: Math.round(size.width),
      height: Math.round(size.height),
      videoWidth: Math.round(size.videoWidth),
      videoHeight: Math.round(size.videoHeight),
      slidesWidth: Math.round(size.slidesWidth),
      slidesHeight: Math.round(size.slidesHeight),
      spacing: Math.round(size.spacing),
    };

    if (isVariableDefinedNotNull(size.vertical)) {
      roundedSize.vertical = size.vertical;
    }

    if (isVariableDefinedNotNull(size.videoElementWidth)) {
      roundedSize.videoElementWidth = Math.round(size.videoElementWidth);
    }

    if (isVariableDefinedNotNull(size.videoElementHeight)) {
      roundedSize.videoElementHeight = Math.round(size.videoElementHeight);
    }

    return roundedSize;
  }

  calcSizeForVideoSlideshow() {
    let spacing;
    let size;

    if (this.verticalLayout) {
      spacing = 5;
      size = this.calcSizeForVideoSlideshowVertical(spacing);
    } else {
      spacing = Math.min(20, Math.max(5, this.maxWidth * 0.01));
      size = this.calcSizeForVideoSlideshowHorizontal(spacing);
    }

    size.spacing = spacing;
    return size;
  }

  calcSizeForVideoSlideshowHorizontal(spacing) {
    return calcSizeHorizontal(
      this.maxWidth,
      this.maxHeight,
      spacing,
      this.props.displayVideoRatio || this.props.realVideoRatio,
      this.props.realVideoRatio,
      this.props.slidesRatio,
      this.props.zoom,
      {
        zoomHidden: this.options.hideZoomControls,
      },
    );
  }

  calcSizeForVideoSlideshowVertical(spacing) {
    return calcSizeVertical(
      this.maxWidth,
      this.maxHeight,
      spacing,
      this.props.displayVideoRatio || this.props.realVideoRatio,
      this.props.realVideoRatio,
      this.props.slidesRatio,
    );
  }

  calcSizeForVideo() {
    const size = this.calcSizeForOnePartOnly(this.props.displayVideoRatio || this.props.realVideoRatio);

    const videoElementSize = calcVideoElementSize(
      size.w,
      size.h,
      this.props.displayVideoRatio || this.props.realVideoRatio,
      this.props.realVideoRatio,
    );

    return {
      width: size.w,
      height: size.h,
      videoWidth: size.w,
      videoHeight: size.h,
      videoElementWidth: videoElementSize.w,
      videoElementHeight: videoElementSize.h,
      slidesWidth: 0,
      slidesHeight: 0,
      spacing: 0,
    };
  }

  calcSizeForAudioSlideshow() {
    const size = this.calcSizeForSlideshow();
    size.videoWidth = size.slidesWidth;
    size.videoHeight = size.slidesHeight;
    return size;
  }

  calcSizeForSlideshow() {
    const size = this.calcSizeForOnePartOnly(this.props.slidesRatio);

    return {
      width: size.w,
      height: size.h,
      videoWidth: 0,
      videoHeight: 0,
      slidesWidth: size.w,
      slidesHeight: size.h,
      spacing: 0,
    };
  }

  calcSizeForOnePartOnly(ratio) {
    const maxHeight = this.maxHeight;

    let w = this.maxWidth;
    let h = w / ratio;

    if (maxHeight && h > maxHeight) {
      h = maxHeight;
      w = h * ratio;
    }

    return {
      w,
      h,
    };
  }

  areSizesEqual(a, b) {
    if (!a && !b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    for (const key of Object.keys(a)) {
      if (a[key] !== b[key]) {
        return false;
      }
    }

    return true;
  }

  setAvailableSize(maxHeight) {
    if (this.options.fitToViewport) {
      this.props.maxHeight = maxHeight;

      this.updateSize();
    }
  }

  set realVideoRatio(value) {
    if (this.props.realVideoRatio !== value) {
      this.props.realVideoRatio = value;
      this.updateSize();
    }
  }

  set displayVideoRatio(value) {
    if (this.props.displayVideoRatio !== value) {
      this.props.displayVideoRatio = value;
      this.updateSize();
    }
  }

  set slidesRatio(value) {
    if (this.props.slidesRatio !== value) {
      this.props.slidesRatio = value;
      this.updateSize();
    }
  }

  get zoom() {
    return this.props.zoom;
  }

  set zoom(value) {
    if (this.props.zoom !== value) {
      this.props.zoom = value;
      this.zoomRatioChangedCallback(value);
      this.updateSize();
    }
  }

  get maxWidth() {
    if (this.props.fullscreen) {
      return viewportWidth();
    }

    let maxWidth = getWidth(this.element);

    if (this.options.maxWidth && this.options.maxWidth < maxWidth) {
      maxWidth = this.options.maxWidth;
    }

    return maxWidth;
  }

  get maxHeight() {
    if (this.props.fullscreen) {
      return viewportHeight();
    }

    let maxHeight = this.props.maxHeight;

    if (!maxHeight && this.options.fitToViewport) {
      if (window !== window.parent) {
        maxHeight = viewportHeight();
      } else {
        const delta = 150;
        maxHeight = viewportHeight() - delta;
      }

      if (maxHeight < 200) {
        maxHeight = 200;
      }
    }

    if (this.options.maxHeight && (!maxHeight || this.options.maxHeight < maxHeight)) {
      return this.options.maxHeight;
    }

    return maxHeight;
  }

  set mode(mode) {
    if (this.props.mode !== mode) {
      this.props.mode = mode;
      this.updateSize();
    }
  }

  set loading(value) {
    if (this.props.loading !== value) {
      this.props.loading = value;
      this.updateSize();
    }
  }

  set message(value) {
    this.props.message = value;
  }

  set compactDefault(value) {
    this.props.compactDefault = value;
  }

  get size() {
    return this.props.size;
  }

  set hideSlides(hide) {
    if (this.hideSlides !== hide) {
      this.props.hideSlides = hide;
      this.updateSize();
    }
  }

  get hideSlides() {
    return this.props.hideSlides;
  }

  set fullscreen(fullscreen) {
    this.props.fullscreen = fullscreen;
    this.updateSize();
  }

  get fullscreen() {
    return this.props.fullscreen;
  }

  get verticalLayout() {
    if (this.props.mode !== 'video_slideshow') return false;

    const verticalEnabled = this.options.verticalEnabled || (isMobileDevice() && this.options.verticalEnabledOnMobile);
    if (!verticalEnabled) return false;

    const maxWidth = this.maxWidth;
    if (!this.props.fullscreen) {
      if (this.options.verticalWhenWidthLte !== null && maxWidth <= this.options.verticalWhenWidthLte) {
        return true;
      }

      if (this.options.verticalDisabledWhenWidthGte !== null && maxWidth >= this.options.verticalDisabledWhenWidthGte) {
        return false;
      }
    }

    const maxHeight = this.maxHeight;
    return maxHeight && maxWidth < maxHeight;
  }
}
