import { inlineCss } from '@slideslive/fuse-kit/utils';

function createVideoElement() {
  const videoElement = document.createElement('video');
  videoElement.setAttribute('crossorigin', 'anonymous');
  videoElement.setAttribute('playsinline', 'playsinline');
  videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
  videoElement.playsinline = true;
  videoElement['webkit-playsinline'] = true;

  inlineCss(videoElement, {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 1,
  });

  return videoElement;
}

export default createVideoElement;
