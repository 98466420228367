import { defer } from '@slideslive/fuse-kit/utils';

import Slide from './slide';

class EditorImage extends Slide {
  constructor(options, callbacks) {
    super('image', options, callbacks);
  }

  beforeCallbackCalled() {
    this.isLoaded = true;
    this.isLoading = false;

    this.element.onload = null;
    this.element.onerror = null;
  }

  load() {
    if (this.isLoading) return;

    if (this.isLoaded) {
      defer(() => this.callbacks.loaded(this.element));
      return;
    }

    super.load();

    this.element = new Image();

    this.element.onload = () => {
      this.beforeCallbackCalled();
      this.callbacks.loaded(this.element);
    };

    this.element.onerror = () => {
      this.beforeCallbackCalled();
      this.callbacks.error(this.element, 'Something went wrong.');
    };

    this.element.src = this.url;
  }

  get loading() {
    return this.options.loading;
  }

  get url() {
    return this.options.url;
  }

  get progressImageUrl() {
    return this.url;
  }

  get originalUrl() {
    return this.options.originalUrl;
  }
}

export default EditorImage;
