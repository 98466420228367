import { defer, isVariableDefinedNotNull, now } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';
import VideoServiceCallbacks from 'modules/player/video/services/helpers/video_service_callbacks';
import YouTubeIframeLoader from 'youtube-iframe';

const LOG_TAG = 'YouTubeV2';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class Youtube {
  constructor(element, options) {
    this._element = element;
    this._options = options;

    this._videoId = null;

    this._ratio = valueOrDefault(this._options.initial, 'ratio', 16 / 9.0);

    this._timing = {
      createdAt: now(),
      initializingStartedAt: undefined,
      initializedAt: undefined,
      loadedAt: undefined,
      firstReadyAt: undefined,
    };

    this._volume = {
      volume: valueOrDefault(this._options.initial, 'volume', 100),
      muted: valueOrDefault(this._options.initial, 'muted', false),
    };

    this._playbackRate = {
      availablePlaybackRates: [
        {
          key: '1',
          name: `1 &times;`,
          playbackRate: 1,
        },
      ],
      activePlaybackRateIndex: '1',
    };

    this._quality = {
      quality: valueOrDefault(this._options.initial, 'quality', null),

      availableQualities: [],
      activeQualityIndex: -1,
      currentQualityName: 'Auto',
    };

    this._subtitles = {
      addedTrackIds: [],
      trackElements: [],
      availableSubtitles: [
        {
          key: 'Off/off',
          name: 'Off',
          language: 'off',
        },
      ],
      activeSubtitlesIndex: 'Off/off',
      slidesLiveSubtitles: [],
    };

    this._live = {
      finished: valueOrDefault(this._options.initial, 'liveFinished', false),
      inLivePosition: true,
      livePositionOffset: 0,
    };

    this._state = {
      initializing: false,
      initialized: false,
      loading: false,
      ready: false,
      state: '',
      firstPlay: true,
      seekable: true,
    };

    this._callbacks = new VideoServiceCallbacks();

    this._youtubeProps = {
      ccModule: '',
    };

    this._youtube = null;
  }

  on(event, callback) {
    this._callbacks.on(event, callback);
  }

  _runCallbacks(event, ...args) {
    this._callbacks.run(event, ...args);
  }

  load(sources, { videoId }) {
    if (sources) {
      console.warn(LOG_TAG, 'multiple sources are not supported');
    }

    if (!videoId) {
      console.warn(LOG_TAG, 'video id must be set');
    }

    this.loading = true;
    this._state.initializing = true;
    this._timing.initializingStartedAt = now();
    this._videoId = videoId;

    YouTubeIframeLoader.load((YT) => this._load(YT));
  }

  // public methods

  play() {
    this._state.ended = false;
    this._youtube.playVideo();
  }

  pause() {
    this._youtube.pauseVideo();

    if (this._live.inLivePosition) {
      this._live.livePositionOffset = 0;

      this._live.inLivePosition = false;
      this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);
    }
  }

  seekToLivePosition() {
    log(LOG_TAG, 'seek to live position', this._liveSyncPosition);

    this.currentTime = this._liveSyncPosition;

    this._live.livePositionOffset = 0;
    this._live.inLivePosition = true;
    this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);
  }

  loadSubtitles(subtitles) {
    this._subtitles.slidesLiveSubtitles = subtitles;

    this._updateAvailableSubtitles();
  }

  destroy() {
    console.warn(LOG_TAG, 'Not implemented:', 'destroy');
  }

  // public getters and setters

  get useHlsJs() {
    return false;
  }

  get videoElement() {
    return null;
  }

  get activeVideoSourceIndex() {
    return 0;
  }

  set activeVideoSourceIndex(index) {}

  get ready() {
    return this._state.ready;
  }

  get loading() {
    return this._state.loading;
  }

  set loading(loading) {
    if (loading === this._state.loading) {
      return;
    }

    this._state.loading = loading;
    this._runCallbacks('loadingChanged', this._state.loading);
  }

  get duration() {
    if (this._options.live && !this._live.liveFinished) {
      if (this._live.inLivePosition) {
        return this.currentTime;
      }

      return this.currentTime + this._live.livePositionOffset;
    }

    return this._youtube.getDuration() * 1000;
  }

  get currentTime() {
    return this._youtube && this._youtube.getCurrentTime ? this._youtube.getCurrentTime() * 1000 : 0;
  }

  set currentTime(value) {
    const currentTimeBeforeSeek = this.currentTime;

    this._live.livePositionOffset = this.duration - value;
    this._youtube.seekTo(value / 1000.0, true);

    this._runCallbacks('seekRequest', currentTimeBeforeSeek, value);

    this._live.inLivePosition = false;
    this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);
  }

  get programDateTime() {
    return this.currentTime;
  }

  get state() {
    return this._state.state;
  }

  set state(state) {
    if (state === this._state.state) {
      return;
    }

    this._state.state = state;
    this._runCallbacks('stateChanged', this._state.state);
  }

  get seeking() {
    return this._state.seeking;
  }

  set seeking(seeking) {
    this._state.seeking = seeking;
  }

  get volume() {
    return this._volume.volume;
  }

  set volume(value) {
    this._volume.muted = false;
    this._volume.volume = value;

    if (this._youtube) this._youtube.setVolume(this._volume.volume);

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get muted() {
    return this._volume.muted;
  }

  set muted(muted) {
    this._volume.muted = muted;

    if (this._youtube) {
      if (muted) {
        this._youtube.setVolume(0);
      } else {
        this._youtube.setVolume(this._volume.volume);
      }
    }

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get realPlaybackRate() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1;
  }

  set realPlaybackRate(rate) {
    if (this._youtube) {
      this._youtube.setPlaybackRate(rate);
    }
  }

  get activePlaybackRate() {
    return this._playbackRate.availablePlaybackRates.find(
      (rate) => rate.key === this._playbackRate.activePlaybackRateIndex,
    );
  }

  get activePlaybackRateIndex() {
    return this._playbackRate.activePlaybackRateIndex;
  }

  set activePlaybackRateIndex(index) {
    if (index !== this._playbackRate.activePlaybackRateIndex) {
      this._playbackRate.activePlaybackRateIndex = index;

      this._youtube.setPlaybackRate(this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1);

      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  get activeQuality() {
    return this._quality.availableQualities.find((quality) => quality.key === this._quality.activeQualityIndex);
  }

  get activeQualityIndex() {
    return this._quality.activeQualityIndex;
  }

  set activeQualityIndex(index) {
    if (index !== this._quality.activeQualityIndex) {
      this._quality.activeQualityIndex = index;

      this._youtube.setPlaybackQuality(this.activeQuality ? this.activeQuality.level : 'auto');

      this._runCallbacks('activeQualityIndexChanged', this._quality.activeQualityIndex);
    }
  }

  get activeSubtitles() {
    return this._subtitles.availableSubtitles.find(
      (subtitles) => subtitles.key === this._subtitles.activeSubtitlesIndex,
    );
  }

  get activeSubtitlesIndex() {
    return this._subtitles.activeSubtitlesIndex;
  }

  set activeSubtitlesIndex(index) {
    if (index !== this._subtitles.activeSubtitlesIndex) {
      this._subtitles.activeSubtitlesIndex = index;

      const activeSubtitles = this.activeSubtitles;

      this._youtube.setOption(this._youtubeProps.ccModule, 'track', activeSubtitles.track);

      this._runCallbacks('renderSubtitlesWord', null);
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }

  get activeVideoSourceUrl() {
    return '';
  }

  get activeQualityName() {
    return this.activeQuality ? this.activeQuality.name : null;
  }

  get activePlaybackRateValue() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : null;
  }

  get live() {
    return this._options.live;
  }

  get liveFinished() {
    return this._live.liveFinished;
  }

  set liveFinished(liveFinished) {
    this._live.liveFinished = liveFinished;

    if (this._live.inLivePosition) {
      this._live.livePositionOffset = 0;
      this._live.inLivePosition = false;
      this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);
    }
  }

  set liveSubtitlesEnabled(enabled) {}

  set liveSubtitlesDelayMs(delayMs) {}

  get inLivePosition() {
    return this._live.inLivePosition;
  }

  get ratio() {
    return this._ratio;
  }

  set size(size) {}

  //

  _load(YT) {
    this._state.initializing = false;
    this._state.initialized = true;
    this._timing.initializedAt = now();

    const elementId = this._element.getAttribute('id');

    this._youtube = new YT.Player(elementId, {
      host: 'https://www.youtube-nocookie.com',
      videoId: this._videoId,
      playerVars: {
        enablejsapi: 1,
        origin: window.origin,
        autoplay: 0,
        controls: this._options.originalVideoControls ? 1 : 0,
        disablekb: 1,
        fs: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        playsinline: 1,
        rel: 0,
        start: 0,
        cc_load_policy: 1,
      },
      events: {
        onReady: () => this._onYouTubeReady(),
        onApiChange: () => this._onYouTubeApiChange(),
        onStateChange: (state) => this._onYouTubeStateChange(state.data),
        onPlaybackQualityChange: (quality) => this._onYouTubePlaybackQualityChange(quality.data),
        onPlaybackRateChange: (rate) => this._onYouTubePlaybackRateChange(rate.data),
        onError: (error) => {
          log('YOUTUBE', 'error', error);

          this._handleYouTubeError({ error, source: 'youtube_callback' });
        },
      },
    });

    this._element = document.getElementById(elementId);
    this._element.setAttribute('allow', 'autoplay');

    this._state.loaded = true;
    this._timing.loadedAt = now();
  }

  // Internal

  _onYouTubeReady() {
    this._canPlay();
  }

  _onYouTubeApiChange() {
    const options = this._youtube.getOptions();
    if (options.indexOf('captions') >= 0) {
      this._youtubeProps.ccModule = 'captions';
    } else if (options.indexOf('cc') >= 0) {
      this._youtubeProps.ccModule = 'cc';
    }

    if (this._youtubeProps.ccModule) {
      this._updateAvailableSubtitles();

      if (this.activeSubtitles) {
        this._youtube.setOption(this._youtubeProps.ccModule, 'track', this.activeSubtitles.track);
      }
    }
  }

  _onYouTubeStateChange(state) {
    // -1 - unstarted
    //  0 - ended
    //  1 - playing
    //  2 - paused
    //  3 - loading
    //  5 - cued

    log(LOG_TAG, 'state changed', state);

    if (state === 3) {
      this.loading = true;
      return;
    }

    if (state === 1 && this._state.firstPlay) {
      this._runCallbacks('firstPlay');
      this._state.firstPlay = false;

      this._updateAvailableQualities();
    }

    switch (state) {
      case -1:
        break;
      case 1:
        this.state = 'playing';
        this._state.ended = false;
        break;
      case 0:
      case 2:
        this.state = 'paused';
        break;
      case 5:
        this.state = 'paused';
        break;
      default:
    }

    this.loading = false;

    if (state === 0) {
      this._state.ended = true;
      this._runCallbacks('ended');
    }
  }

  _onYouTubePlaybackQualityChange(quality) {
    log(LOG_TAG, 'quality changed', quality);

    if (this._quality.activeQualityIndex !== 'auto') {
      const activeQuality = this._quality.availableQualities.find((q) => q.level === quality);
      if (activeQuality) {
        this._quality.activeQualityIndex = activeQuality.key;
        this._runCallbacks('activeQualityIndexChanged', this._quality.activeQualityIndex);
      }
    }

    this._updateAvailableQualities();
  }

  _onYouTubePlaybackRateChange(rate) {
    log(LOG_TAG, 'playback rate changed', rate);

    const activePlaybackRate = this._playbackRate.availablePlaybackRates.find((r) => r.playbackRate === rate);

    if (activePlaybackRate) {
      this._playbackRate.activePlaybackRateIndex = activePlaybackRate.key;
      this._runCallbacks('activeQualityIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }

    this._updateAvailablePlaybackRates();
  }

  _canPlay() {
    if (!this.ready) {
      this._setReady();
    }
  }

  _setReady() {
    if (!this._timing.firstReadyAt) this._timing.firstReadyAt = now();

    this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);

    this._updateAvailablePlaybackRates();
    this._updateAvailableQualities();

    this._youtube.setPlaybackRate(this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1);
    this._youtube.setPlaybackQuality(this.activeQuality ? this.activeQuality.level : 'auto');
    this._youtube.setOption(
      this._youtubeProps.ccModule || 'captions',
      'track',
      this.activeSubtitles ? this.activeSubtitles.track : '',
    );

    this._youtube.loadModule('captions');

    if (this._volume.muted) {
      this._youtube.setVolume(0);
    } else {
      this._youtube.setVolume(this._volume.volume);
    }

    this._runCallbacks('volumeChanged', this.volume, this.muted);

    this._state.seekable = true;
    this._runCallbacks('seekableChanged', this._state.seekable);

    this.loading = false;
    this.state = 'paused';

    // this.ytStateChange(this._youtube.getPlayerState());

    defer(() => {
      this._state.ready = true;
      this._runCallbacks('ready');
    });
  }

  _updateAvailablePlaybackRates() {
    const previousPlaybackRateKey = this.activePlaybackRate?.key;

    const playbackRates = this._youtube.getAvailablePlaybackRates().filter((rate) => rate >= 0);

    this._playbackRate.availablePlaybackRates = playbackRates.map((playbackRate) => ({
      key: playbackRate.toString(),
      name: `${playbackRate} &times;`,
      playbackRate,
    }));

    this._runCallbacks('availablePlaybackRatesChanged', this._playbackRate.availablePlaybackRates);

    const activePlaybackRateKey =
      this._playbackRate.availablePlaybackRates.findIndex((rate) => rate.key === previousPlaybackRateKey) >= 0
        ? previousPlaybackRateKey
        : '1';

    if (this.activePlaybackRateIndex !== activePlaybackRateKey) {
      this.activePlaybackRateIndex = activePlaybackRateKey;
    } else {
      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  _updateAvailableQualities() {
    let activeQualityKey = this.activeQuality?.key;
    const availableQualities = [
      {
        key: 'auto',
        name: 'Auto',
        level: -1,
        bitrate: Infinity,
      },
    ];

    const ytQualities = this._youtube.getAvailableQualityLevels();

    for (const ytQuality of ytQualities) {
      availableQualities.push({
        key: ytQuality,
        name: this.ytQualityTitle(ytQuality) || ytQuality,
        level: ytQualities,
        bitrate: 0,
      });
    }

    if (
      !isVariableDefinedNotNull(activeQualityKey) ||
      this._quality.activeQualityIndex === -1 ||
      this._quality.activeQualityIndex === 'auto'
    ) {
      activeQualityKey = 'auto';
    }

    availableQualities.sort((a, b) => a.bitrate - b.bitrate);

    if (activeQualityKey === 'auto') {
      availableQualities.find((quality) => quality.key === 'auto').name = `Auto (${this._quality.currentQualityName})`;
    }

    this._quality.availableQualities = availableQualities;
    this._runCallbacks('availableQualitiesChanged', this._quality.availableQualities);

    if (this.activeQualityIndex !== activeQualityKey) this.activeQualityIndex = activeQualityKey;
  }

  _updateAvailableSubtitles() {
    const previousSubtitlesKey = this.activeSubtitles?.key;
    const availableSubtitles = [
      {
        key: 'Off/off',
        name: 'Off',
        language: '',
        track: {},
      },
    ];

    if (this._youtubeProps.ccModule) {
      const ccOptions = this._youtube.getOptions(this._youtubeProps.ccModule);
      if (ccOptions && ccOptions.indexOf('tracklist') > 0) {
        const tracks = this._youtube.getOption(this._youtubeProps.ccModule, 'tracklist');
        for (let i = 0; i < tracks.length; ++i) {
          const track = tracks[i];

          let name = track.displayName;
          const parts = name.split('-').map((part) => part.trim());
          if (parts.length === 2) {
            name = parts[1];
          }

          availableSubtitles.push({
            key: `youtube/${track.displayName}`,
            name,
            language: track.languageCode.split('-')[0],
            track,
          });
        }
      }
    }

    for (let i = 0; i < this._subtitles.slidesLiveSubtitles.length; ++i) {
      const subtitles = this._subtitles.slidesLiveSubtitles[i];

      availableSubtitles.push({
        key: `slideslive/${subtitles.name}`,
        name: subtitles.name,
        language: subtitles.language,
        track: { languageCode: subtitles.language },
      });
    }

    this._subtitles.availableSubtitles = availableSubtitles;
    this._runCallbacks('availableSubtitlesChanged', this._subtitles.availableSubtitles);

    const activeSubtitlesIndex =
      this._subtitles.availableSubtitles.findIndex((subtitles) => subtitles.key === previousSubtitlesKey) >= 0
        ? previousSubtitlesKey
        : 'Off/off';

    if (this.activeSubtitlesIndex !== activeSubtitlesIndex) {
      this.activeSubtitlesIndex = activeSubtitlesIndex;
    } else {
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }

  get _liveSyncPosition() {
    let liveSyncPosition = this._youtube.getDuration();
    if (liveSyncPosition === null) {
      liveSyncPosition = (this.currentTime + this._live.livePositionOffset) / 1000.0;
    }

    return liveSyncPosition;
  }

  // Internal

  ytQualityTitle(quality) {
    switch (quality) {
      case 'hd1080':
        return '1080p';
      case 'hd720':
        return '720p';
      case 'large':
        return '480p';
      case 'medium':
        return '360p';
      case 'small':
        return '240p';
      case 'auto':
        return 'Auto';
      default:
        return null;
    }
  }

  _handleYouTubeError({ error, source }) {
    this._showError(error);
    this._reportError(error, { source });
  }

  _showError(error) {
    let errorMessage;

    if (error.data === 2) {
      errorMessage = [`Invalid YouTube video ID: ${this._videoId}`, 'Report this error to support@slideslive.com.'];
    } else if (error.data === 5) {
      errorMessage = [
        'Unknown error in YouTube HTML5 player.',
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    } else if (error.data === 100) {
      errorMessage = [
        'YouTube video does not exist or its privacy is set to Private.',
        'Report this error to support@slideslive.com.',
      ];
    } else if (error.data === 101 || error.data === 150) {
      errorMessage = [
        'YouTube video does not exist, it is private or its playback is disabled for embeds.',
        'Report this error to support@slideslive.com.',
      ];
    } else {
      errorMessage = [
        `Unknown YouTube error: ${error.data}`,
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    }

    errorMessage.push('');
    errorMessage.push(`<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`);

    this._runCallbacks('showError', `${errorMessage.join('<br>')}`);
  }

  _reportError(error, { source = 'unknown', warn = false } = {}) {
    const reportData = {};

    reportData.warn = warn;
    reportData.source = source;

    reportData.state = {
      video_id: this._videoId,
      ready: this._state.ready,
      currentTime: this.currentTime,
      duration: this.duration,
    };

    reportData.error = {
      data: error.data,
    };

    const component = 'YOUTUBE_PLAYER';

    if (error.data === 2) {
      this._runCallbacks('reportError', component, 'INVALID_VIDEO', reportData);
    } else if (error.data === 5) {
      this._runCallbacks('reportError', component, 'HTML5_ERROR', reportData);
    } else if (error.data === 100) {
      this._runCallbacks('reportError', component, 'NOT_AVAILABLE', reportData);
    } else if (error.data === 101 || error.data === 150) {
      this._runCallbacks('reportError', component, 'EMBED_NOT_ALLOWED', reportData);
    } else {
      this._runCallbacks('reportError', component, 'OTHER_ERROR', reportData);
    }
  }
}
