import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';

function nativeErrorCodeName(error) {
  if (isVariableDefinedNotNull(error.code)) {
    if (error.constructor.name === 'MediaError') {
      for (const member of Object.getOwnPropertyNames(MediaError.prototype)) {
        if (member.startsWith('MEDIA_ERR_') && MediaError.prototype[member] === error.code) {
          return member;
        }
      }
    }
  }

  return `UNKNOWN (${error.code ? error.code : '<undefined>'})`;
}

export default nativeErrorCodeName;
