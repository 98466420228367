import { addListener, isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';
import stimulus, { FUSE_TOOLTIP_CONTROLLER } from 'plugins/stimulus';

export default class Menu {
  constructor(trigger, menu, rootMenu, changeCallback) {
    this.trigger = trigger;
    this.menu = menu;
    this.rootMenu = rootMenu;
    this.changeCallback = changeCallback;

    this.originalContent = {};
    this.activeItems = {};

    addListener(this.trigger, 'click', (event) => this.toggleMenu(event));
    addListener(this.menu, 'click', '[data-slp-open-menu]', (event) => this.openMenuFromEvent(event));
    addListener(this.menu, 'click', '[data-slp-action-param]', (event) => this.setActiveItemFromEvent(event));
  }

  fireChangeCallback() {
    const visible = this.menu.classList.contains('slp__menu--active');

    if (stimulus.hasTarget(this.trigger, { [FUSE_TOOLTIP_CONTROLLER]: 'item' })) {
      stimulus.setControllerDataValue(this.trigger, {
        [FUSE_TOOLTIP_CONTROLLER]: {
          disabled: visible,
        },
      });
    }

    this.changeCallback(visible);
  }

  close() {
    if (!this.menu.classList.contains('slp__menu--active')) {
      return;
    }

    log('MENU', 'close');

    this.menu.classList.remove('slp__menu--active');
    this.fireChangeCallback();
  }

  toggleMenu(event) {
    log('MENU', 'toggle');

    event.preventDefault();

    if (!this.menu.classList.contains('slp__menu--active')) {
      this.openMenu(this.rootMenu);
    }

    this.menu.classList.toggle('slp__menu--active');
    this.fireChangeCallback();
  }

  openMenuFromEvent(event) {
    event.preventDefault();

    this.openMenu(event.selectorTarget.getAttribute('data-slp-open-menu'));
  }

  openMenu(name) {
    for (const submenu of this.menu.querySelectorAll('[data-slp-menu]')) {
      const submenuName = submenu.getAttribute('data-slp-menu');
      const show = name === submenuName;

      submenu.classList.toggle('slp__submenu--active', show);
    }
  }

  setSubmenuContent(submenuName, data) {
    const submenu = this.menu.querySelector(`[data-slp-menu="${submenuName}"]`);
    if (!isVariableDefinedNotNull(submenu)) {
      console.warn(`Submenu ${submenuName} not found.`);
      return;
    }

    if (!isVariableDefinedNotNull(this.originalContent[submenuName])) {
      this.originalContent[submenuName] = submenu.innerHTML;
    }

    if (data.length > 0) {
      let html = this.originalContent[submenuName];
      for (const itemData of data) {
        html += '<li class="slp__submenu__button"';
        if (isVariableDefinedNotNull(itemData.param)) {
          html += ` data-slp-action-w-param="${itemData.action}"`;
          html += ` data-slp-action-param="${itemData.param}"`;
        } else {
          html += ` data-slp-action="${itemData.action}"`;
        }
        html += `>${itemData.title}</li>`;
      }
      submenu.innerHTML = html;

      const openButton = this.menu.querySelector(`[data-slp-open-menu="${submenuName}"]`);
      openButton.classList.remove('slp__submenu__button--disabled');

      const activeItem = this.activeItems[submenuName];
      this.setActiveItem(submenuName, activeItem);
    }
  }

  disableSubmenu(submenuName) {
    const openButton = this.menu.querySelector(`[data-slp-open-menu="${submenuName}"]`);
    openButton.classList.add('slp__submenu__button--disabled');
  }

  setActiveItem(submenuName, itemParam) {
    if (!isVariableDefinedNotNull(itemParam)) {
      return;
    }

    this.activeItems[submenuName] = itemParam;

    const submenu = this.menu.querySelector(`[data-slp-menu="${submenuName}"]`);
    if (!isVariableDefinedNotNull(submenu)) {
      return;
    }

    let activeItemText = null;
    for (const item of submenu.querySelectorAll('[data-slp-action-param]')) {
      const active = item.getAttribute('data-slp-action-param') === itemParam.toString();
      item.classList.toggle('slp__submenu__button--active', active);

      if (active) {
        activeItemText = item.innerText;
      }
    }

    if (isVariableDefinedNotNull(activeItemText)) {
      const submenuButton = this.menu.querySelector(`[data-slp-open-menu="${submenuName}"]`);
      if (isVariableDefinedNotNull(submenuButton)) {
        const activeItemLabel = submenuButton.querySelector('.slp__submenu__activeItemLabel');
        if (isVariableDefinedNotNull(activeItemLabel)) {
          activeItemLabel.innerHTML = activeItemText;
        }
      }
    }
  }
}
