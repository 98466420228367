import { defer, isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';

import OnDemandImage from './slide/on_demand_image';
import OnDemandVideo from './slide/on_demand_video';

const ON_THE_FLY_SLIDES_QUALITIES = [432, 540, 720, 1080, 1440, 2160];

export default class OnDemand {
  constructor(options, callbacks) {
    this.options = options;
    this.callbacks = callbacks;

    const slideHost = gon?.hosts?.slideslive_on_the_fly_resized_slides_host;

    this.props = {
      slideHost,
      slidePath: '',
      timedSlideIndex: null,
      manualSlideIndex: null,
      ready: false,
      inSync: true,
      quality: 540,
      videoServiceData: null,
    };

    this.slides = [];

    this.pointer = [];
    this.currentPointerIndex = null;
  }

  load(jsonUrl, xmlUrl, videoSlidesVideoServiceDataUrl) {
    this.callbacks.loadingChanged(true);

    this.loadJson(jsonUrl, videoSlidesVideoServiceDataUrl, () => {
      this.loadXml(xmlUrl);
    });
  }

  loadJson(url, videoSlidesVideoServiceDataUrl, errorCallback) {
    if (!url) {
      defer(() => errorCallback());
      return;
    }

    this.fetchSlidesJson(url, errorCallback).then((slidesJson) => {
      if (!slidesJson) return;
      this.processSlidesJson(slidesJson, videoSlidesVideoServiceDataUrl);
    });
  }

  loadXml(url) {
    if (!url) {
      this.callbacks.loadingChanged(false);

      this.props.ready = true;
      this.callbacks.ready();

      return;
    }

    this.fetchSlidesXml(url).then((slidesXml) => {
      if (!slidesXml) return;

      this.processSlidesXml(slidesXml);
    });
  }

  loadOffline(slides) {
    this.callbacks.loadingChanged(true);

    this.processSlidesJson(JSON.parse(slides));
  }

  fetchSlidesData(url, contentType, errorCallback) {
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
    })
      .catch((error) => {
        console.warn('SLIDES', `fetch exception for ${contentType}:`, error);
        this.callbacks.loadingChanged(false);
      })
      .then((response) => {
        if (!response.ok) {
          if (errorCallback) {
            errorCallback();
            return null;
          }

          this.callbacks.loadingChanged(false);

          if (response.status === 403 || response.status === 404) {
            this.props.ready = true;
            this.callbacks.ready();
          } else {
            console.warn('SLIDES', `fetch HTTP error for ${contentType}:`, response.status, response.statusText);
          }

          return null;
        }

        return response;
      });
  }

  fetchSlidesJson(url, errorCallback = null) {
    return this.fetchSlidesData(url, 'application/json', errorCallback).then((response) => {
      if (!response) return null;

      return response.json();
    });
  }

  fetchSlidesXml(url) {
    return this.fetchSlidesData(url, 'application/xml', null).then((response) => {
      if (!response) return null;

      return response.text().then((slidesXml) => new DOMParser().parseFromString(slidesXml, 'application/xml'));
    });
  }

  processSlidesJson(json, videoSlidesVideoServiceDataUrl) {
    for (const slideData of json.slides) {
      let data;

      if (slideData.type === 'video') {
        data = new OnDemandVideo(
          {
            local: this.options.offline,
            presentationId: this.presentationId,
            presentationMediaSetId: this.options.presentationMediaSetId,
            analyticsUserUuid: this.options.analyticsUserUuid,
            analyticsSessionUuid: this.options.analyticsSessionUuid,
            embed: this.options.embed,
            accountId: this.options.accountId,
            source: this.options.source,
            cacheTimestamp: this.cacheTimestamp,
            cmcdEnabled: this.options.cmcdEnabled,
            data: slideData.video,
            time: slideData.time,
          },
          {
            loaded: this.callbacks.slideLoaded,
            error: (element, error) => {
              console.warn('SLIDE', 'video slide loading error', error);
              this.callbacks.slideLoaded(element);
            },
            updateVolume: this.callbacks.updateVolume,
            loadingChanged: (loading, target) => {
              this.callbacks.loadingChanged(loading);
              this.callbacks.videoLoadingChanged(target, loading);
            },
            stateChanged: this.callbacks.videoStateChanged,
            ended: this.callbacks.videoEnded,
            playbackRateChanged: this.callbacks.videoPlaybackRateChanged,
            playFailed: this.callbacks.videoPlayFailed,
          },
        );
      } else if (slideData.type === 'image') {
        data = new OnDemandImage(
          {
            local: this.options.offline,
            slideHost: this.props.slideHost,
            slidePath: this.props.slidePath,
            presentationId: this.presentationId,
            cacheTimestamp: this.cacheTimestamp,
            data: slideData.image,
            time: slideData.time,
            useWebP: this.options.useWebP,
          },
          {
            loaded: this.callbacks.slideLoaded,
            error: this.callbacks.slideLoaded,
          },
        );
      } else {
        console.warn('Unknown slide type', slideData.type);
      }

      if (data) {
        this.slides.push(data);
      }
    }

    this.pointer = json.pointer || [];

    if (this.options.offline) {
      this.processingPresentationInfoFinished();
      return;
    }

    const videoSlides = [];
    for (const slide of this.slides) {
      if (slide.type === 'video') {
        videoSlides.push(slide);
      }
    }

    if (videoSlides.length > 0) {
      this.loadVideoServiceDataForVideoSlides(videoSlides, videoSlidesVideoServiceDataUrl);
    } else {
      this.processingPresentationInfoFinished();
    }
  }

  processSlidesXml(xml) {
    for (const slideElement of xml.getElementsByTagName('slide')) {
      const name = slideElement.querySelector('slideName').textContent;

      let time;
      let timeElement = slideElement.querySelector('time');
      if (isVariableDefinedNotNull(timeElement)) {
        time = parseInt(timeElement.textContent, 10);
      } else {
        timeElement = slideElement.querySelector('timeSec');
        time = parseInt(timeElement.textContent, 10) * 1000;
      }

      const data = new OnDemandImage(
        {
          local: this.options.offline,
          slideHost: this.props.slideHost,
          slidePath: this.props.slidePath,
          presentationId: this.presentationId,
          cacheTimestamp: this.cacheTimestamp,
          data: { name },
          time,
          useWebP: this.options.useWebP,
        },
        {
          loaded: this.callbacks.slideLoaded,
          error: this.callbacks.slideLoaded,
        },
      );

      this.slides.push(data);
    }

    this.processingPresentationInfoFinished();
  }

  loadVideoServiceDataForVideoSlides(videoSlides, videoSlidesVideoServiceDataUrl) {
    const videos = videoSlides.filter((slide) => slide.videoService === 'yoda').map((slide) => slide.videoId);
    const urlParams = new URLSearchParams({
      player_token: this.options.playerToken,
      videos,
    });

    fetch(`${videoSlidesVideoServiceDataUrl}?${urlParams}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        Accept: 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .catch((error) => {
        console.warn('SLIDES', 'fetch exception for video slides video service data:', error);
        this.callbacks.loadingChanged(false);
      })
      .then((response) => {
        if (!response.ok) {
          this.callbacks.loadingChanged(false);
          return null;
        }

        return response.json();
      })
      .then((json) => {
        if (!json) return;

        for (const videoSlide of videoSlides) {
          const videoData = json[videoSlide.videoId];
          videoSlide.videoServers = videoData.video_servers;
        }
      })
      .then(() => this.processingPresentationInfoFinished())
      .catch((error) => {
        console.warn('SLIDES', 'error when parsing slides video service data:', error);
        this.processingPresentationInfoFinished();
      });
  }

  processingPresentationInfoFinished() {
    if (this.slideCount > 0) {
      this.props.timedSlideIndex = 0;
    }

    this.props.ready = true;
    this.callbacks.ready();

    this.fireCurrentSlideChanged();
  }

  updateVideoCurrentTimeSlideIfInSync(time, slide) {
    if (!this.props.inSync) return;
    if (slide.type !== 'video') return;

    const expectedSlideVideoCurrentTime = time - slide.time;
    const slideVideoCurrentTime = this.currentSlide.currentTime;

    if (Math.abs(expectedSlideVideoCurrentTime - slideVideoCurrentTime) > 1000) {
      this.currentSlide.currentTime = expectedSlideVideoCurrentTime;
    }
  }

  updateSlide(time, programDateTime, force, autoPlayVideoSlide = false) {
    if (this.slides.length === 0 || !this.props.ready) {
      return;
    }

    const nextSlideIndex = this.slideIndexForTime(time);
    const nextSlide = this.slides[nextSlideIndex];

    if (force || nextSlideIndex !== this.props.timedSlideIndex) {
      this.props.timedSlideIndex = nextSlideIndex;

      if (this.props.inSync) {
        this.fireCurrentSlideChanged(autoPlayVideoSlide);
      }
    }

    if (autoPlayVideoSlide) {
      nextSlide.autoPlay = true;

      this.updateVideoCurrentTimeSlideIfInSync(time, nextSlide);
    }
  }

  timeForSlideIndex(slideIndex) {
    return this.slides[slideIndex].time;
  }

  slideIndexForTime(time) {
    let i = 1;
    while (i < this.slides.length && this.slides[i].time <= time) {
      i += 1;
    }

    return i - 1;
  }

  updatePointer(time) {
    let nextPointerIndex;
    if (this.inSync) {
      nextPointerIndex = this.pointerIndexForTime(time);
    } else {
      nextPointerIndex = null;
    }

    const nextPointer = this.pointer[nextPointerIndex];
    if (!nextPointer || nextPointer.time > time || time - nextPointer.time > 5000) {
      nextPointerIndex = null;
    }

    if (this.currentPointerIndex !== nextPointerIndex) {
      this.currentPointerIndex = nextPointerIndex;
      this.fireCurrentPointerChanged();
    }
  }

  pointerIndexForTime(time) {
    let i = 1;
    while (i < this.pointer.length && this.pointer[i].time <= time) {
      i += 1;
    }

    return i - 1;
  }

  progressImageUrl(time) {
    const slideIndex = this.slideIndexForTime(time);

    if (slideIndex < 0 || slideIndex >= this.slides.length) {
      return null;
    }

    const slide = this.slides[slideIndex];
    const url = slide.type === 'image' ? slide.progressImageUrl(this.quality) : slide.progressImageUrl;

    return url;
  }

  dataForSlideIndex(index) {
    const slide = this.slides[index];
    const url = slide.type === 'image' ? slide.url(this.quality) : slide.url;

    return {
      url,
      type: slide.type,
      time: slide.time,
    };
  }

  fireCurrentPointerChanged() {
    this.callbacks.currentPointerChanged({
      time: this.currentPointer?.time,
      x: this.currentPointer?.x,
      y: this.currentPointer?.y,
    });
  }

  fireCurrentSlideChanged(autoPlayVideoSlide) {
    if (!isVariableDefinedNotNull(this.currentSlide)) {
      return;
    }

    const time = this.currentSlideIndex === 0 ? 0 : this.currentSlide.time;
    const url = this.currentSlide.type === 'image' ? this.currentSlide.url(this.quality) : this.currentSlide.url;

    const currentSlideData = {
      url,
      type: this.currentSlide.type,
      time,
    };

    if (this.currentSlide.type === 'video') {
      currentSlideData.video = this.currentSlide;
    }

    this.callbacks.currentSlideChanged(this.currentSlideIndex, this.slideCount, currentSlideData, autoPlayVideoSlide);
  }

  fireCurrentSlideUpdateVideoCurrentTime(expectedVideoCurrentTime) {
    if (this.currentSlide.type !== 'video') {
      return;
    }

    this.callbacks.currentSlideUpdateVideoCurrentTime(expectedVideoCurrentTime);
  }

  next() {
    this.inSync = false;

    this.props.manualSlideIndex = (this.currentSlideIndex + 1) % this.slideCount;
    this.fireCurrentSlideChanged(0);
  }

  prev() {
    this.inSync = false;

    this.props.manualSlideIndex = (this.currentSlideIndex - 1 + this.slideCount) % this.slideCount;
    this.fireCurrentSlideChanged(0);
  }

  setInSync() {
    this.inSync = true;
  }

  set inSync(value) {
    if (this.props.inSync !== value) {
      this.props.inSync = value;

      if (this.props.inSync) {
        this.props.manualSlideIndex = null;
      }

      this.callbacks.inSyncChanged(this.props.inSync);
    }
  }

  get inSync() {
    return this.props.inSync;
  }

  get presentationId() {
    return this.options.presentationId;
  }

  get cacheTimestamp() {
    return this.options.cacheTimestamp || 0;
  }

  get ready() {
    return this.props.ready;
  }

  get currentSlideIndex() {
    if (this.props.inSync || !isVariableDefinedNotNull(this.props.manualSlideIndex)) {
      return this.props.timedSlideIndex;
    }

    return this.props.manualSlideIndex;
  }

  get currentSlideTime() {
    return this.currentSlide ? this.currentSlide.time : null;
  }

  get currentSlideType() {
    return this.currentSlide ? this.currentSlide.type : null;
  }

  get currentSlide() {
    return this.slides[this.currentSlideIndex];
  }

  get currentPointer() {
    if (!isVariableDefinedNotNull(this.currentPointerIndex)) {
      return null;
    }

    return this.pointer[this.currentPointerIndex];
  }

  get slideCount() {
    return this.slides.length;
  }

  set size(size) {
    let quality = ON_THE_FLY_SLIDES_QUALITIES.find((qualityHeight) => qualityHeight >= size.slidesHeight);
    if (!quality) {
      console.warn('Slide quality not found for', size);
      quality = ON_THE_FLY_SLIDES_QUALITIES[0];
    }

    this.quality = quality.toString();
  }

  get quality() {
    return this.props.quality;
  }

  set quality(quality) {
    if (this.quality !== quality) {
      this.props.quality = quality;
      this.fireCurrentSlideChanged();
    }
  }
}
