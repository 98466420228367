import { addListener, getEventPageX, getOffset, getWidth, removeListener } from '@slideslive/fuse-kit/utils';

export default class VolumePanel {
  constructor(volumePanel, volumeSlider, volumeBar, volumeBarCurrent, setVolumeCallback) {
    this.panel = volumePanel;
    this.slider = volumeSlider;
    this.bar = volumeBar;
    this.current = volumeBarCurrent;
    this.setVolumeCallback = setVolumeCallback;

    this.props = {
      mouseIn: false,
      mouseDown: false,
      setVolumeTimeout: undefined,
    };

    this.mouseUpHandler = (event) => {
      this.setMouseDown(event, false);

      if (!this.props.mouseIn) {
        this.hide(event);
      }
    };

    this.mouseMoveHandler = (event) => this.setVolumeFromMouseEvent(event);

    addListener(this.panel, 'mouseenter', (event) => this.show(event));
    addListener(this.panel, 'mouseleave', (event) => this.hide(event));

    addListener(this.panel, 'mouseenter', (event) => this.mouseEnterSlider(event));
    addListener(this.panel, 'mouseleave', (event) => this.mouseLeaveSlider(event));

    addListener(this.slider, 'dragstart', (event) => event.preventDefault());
    addListener(this.slider, 'mousedown', (event) => this.setMouseDown(event, true));
  }

  show(event) {
    if (event.target !== this.panel) {
      return;
    }

    this.panel.classList.add('slp__volumePanel--active');
  }

  hide(event) {
    if (this.props.mouseIn && event.target !== this.panel) {
      return;
    }

    this.props.mouseIn = false;

    if (this.props.mouseDown) {
      return;
    }

    this.panel.classList.remove('slp__volumePanel--active');
  }

  mouseEnterSlider(event) {
    if (event.target !== this.panel) {
      return;
    }

    this.props.mouseIn = true;

    addListener(document, 'mouseup', this.mouseUpHandler);
    addListener(document, 'mousemove', this.mouseMoveHandler);
  }

  mouseLeaveSlider(event) {
    if (this.props.mouseIn && event.target !== this.panel) {
      return;
    }

    this.props.mouseIn = false;

    if (this.props.mouseDown) {
      return;
    }

    removeListener(document, { handler: this.mouseUpHandler });
    removeListener(document, { handler: this.mouseMoveHandler });
  }

  setMouseDown(event, value) {
    this.setVolumeFromMouseEvent(event);

    this.props.mouseDown = value;
  }

  setVolumeFromMouseEvent(event) {
    if (!this.props.mouseDown) {
      return;
    }

    const mouseX = getEventPageX(event) - getOffset('left', this.bar, true) || 0;
    const volume = Math.max(0, Math.min(100, (mouseX / getWidth(this.bar)) * 100));

    this.setVolumeCallback(volume);
  }

  setVolumeDisplay(volume) {
    this.current.style.width = `${volume}%`;
  }
}
