import { ajax } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';

export default class PresentationReview {
  constructor(options, callbacks) {
    this.props = {
      notes: [],

      destroyed: false,
    };

    this.options = options;
    this.callbacks = callbacks;
  }

  loadNotes() {
    ajax('GET', `${this.options.reviewNotesUrl}?media_set_id=${this.options.mediaSetId}`, undefined, {
      withCredentials: true,
      noAcceptHeader: true,
      headers: {
        Accept: 'application/json',
      },
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        this._loadNotesFromJsonResponse(req.response);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('REVIEW', 'error when loading notes', req);
      },
    });
  }

  findNote(noteId) {
    for (const note of this.notes) {
      if (note.id === noteId) {
        return note;
      }
    }

    return null;
  }

  resolveNote(noteId, comment) {
    const data = this._createNoteData(noteId, comment);

    ajax('POST', this.options.resolveReviewNoteUrl.replace(':id', noteId), JSON.stringify(data), {
      withCredentials: true,
      noAcceptHeader: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        this._loadNotesFromJsonResponse(req.response);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('REVIEW', 'resolve review note error', req);
      },
    });
  }

  commentNote(noteId, comment) {
    const data = this._createNoteData(noteId, comment);

    ajax('POST', this.options.commentReviewNoteUrl.replace(':id', noteId), JSON.stringify(data), {
      withCredentials: true,
      noAcceptHeader: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      success: (req) => {
        if (this.props.destroyed) {
          return;
        }

        this._loadNotesFromJsonResponse(req.response);
      },
      error: (req) => {
        if (this.props.destroyed) {
          return;
        }

        log('REVIEW', 'comment review note error', req);
      },
    });
  }

  destroy() {
    this.props.destroyed = true;
  }

  _createNoteData(noteId, comment) {
    return {
      review_note_comment: {
        media_set_id: this.options.mediaSetId,
        video_version: this.options.videoVersion,
        slides_version: this.options.slidesVersion,
        text: comment,
      },
    };
  }

  _loadNotesFromJsonResponse(response) {
    const notes = JSON.parse(response).data;

    log('REVIEW', 'loaded notes', notes.length);

    this.notes = notes;
    this.callbacks.notesChanged(this.notes);
  }
}
