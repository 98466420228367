import getSpinner from './get_spinner';

function getSpinnerInContainerAsElement(options = {}) {
  const spinner = document.createElement('div');
  const klass = options.absoluteWrapper ? 'spinner__wrapper' : 'spinner__container';
  spinner.classList.add(klass);
  spinner.innerHTML = getSpinner(options);

  return spinner;
}

export default getSpinnerInContainerAsElement;
