import { addListener } from '@slideslive/fuse-kit/utils';

export default class Fullscreen {
  constructor(element, fullscreenChangedCallback) {
    this.element = element;
    this.fullscreenChangedCallback = fullscreenChangedCallback;

    this.props = {
      active: false,
    };

    const fullScreenListeners = 'webkitfullscreenchange mozfullscreenchange MSFullscreenChange fullscreenchange';
    addListener(document, fullScreenListeners, (event) => this.updateFullscreenStatus(event));
  }

  toggleFullscreen() {
    if (this.props.active) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  closeFullscreen() {
    const f =
      document.exitFullscreen ||
      document.webkitExitFullscreen ||
      document.mozCancelFullScreen ||
      document.msExitFullscreen;

    f.call(document);
  }

  openFullscreen() {
    const f =
      this.element.requestFullscreen ||
      this.element.webkitRequestFullscreen ||
      this.element.mozRequestFullScreen ||
      this.element.msRequestFullscreen;

    f.call(this.element);
  }

  // eslint-disable-next-line no-unused-vars
  updateFullscreenStatus(event) {
    this.active = !!(
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      false
    );
  }

  set active(value) {
    this.props.active = value;
    this.fullscreenChangedCallback(this.props.active);
  }

  get active() {
    return this.props.active;
  }
}
