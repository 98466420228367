import { isVariableDefinedNotNull, now } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';

export default class LoadingDurationLogger {
  constructor(component, callbacks = {}) {
    this.callbacks = callbacks;

    this.props = {
      component,
      loadingStart: null,
      loadingStartData: null,

      events: [],
    };
  }

  logLoadingChange(loading, { data = null } = {}) {
    if (loading && this.props.loadingStart === null) {
      this._logStart({ data });
    } else if (!loading && this.props.loadingStart !== null) {
      this._logEnd({ data });
    }
  }

  _logStart({ data = null }) {
    const loadingStart = now();

    this.props.loadingStart = loadingStart;
    this.props.loadingStartData = data;

    this._addEvent(this._createEvent('start', loadingStart, data));

    log('LOADING', this.props.component, 'loading start', data);
  }

  _logEnd({ data = null } = {}) {
    const loadingEnd = now();
    const loadingDuration = loadingEnd - this.props.loadingStart;

    this._addEvent(this._createEvent('end', loadingEnd, data, loadingDuration));

    log('LOADING', this.props.component, 'loading end', loadingDuration, data);

    this.props.loadingStart = null;
    this.props.loadingStartData = data;
  }

  _createEvent(type, time, data, duration) {
    const event = { ty: type, t: time };

    if (isVariableDefinedNotNull(data)) {
      event.da = data;
    }

    if (isVariableDefinedNotNull(duration)) {
      event.du = duration;
    }

    return event;
  }

  _addEvent(event) {
    this.props.events.push(event);

    if (this.callbacks.event) {
      this.callbacks.event(event);
    }
  }
}
