import {
  addListener,
  formatTimeString,
  generateRandomId,
  isVariableDefinedNotNull,
  now,
  remove,
  valueOrDefault,
} from '@slideslive/fuse-kit/utils';
import Hls from 'hls.js';
import playerI18n from 'modules/player/localizations';
import mux from 'mux-embed';

import log from '../../../log';
import VideoServiceCallbacks from '../helpers/video_service_callbacks';
import createHlsJs from './create_hls_js';
import createVideoElement from './create_video_element';
import { handleHlsJsError, handleNativeError } from './error_handlers';
import * as ID3 from './id3.ts';
import SourceSwitcher from './source_switcher';
import validatePlaybackSupport from './validate_playback_support';

const LOG_TAG = 'HLSv3';

function logEvent(event) {
  log(LOG_TAG, event.type, event);
}

class HlsV3 {
  constructor(element, options) {
    this._element = element;
    this._options = options;

    this._initialSources = {};
    this._videoId = null;

    this._ratio = valueOrDefault(this._options.initial, 'ratio', 16 / 9.0);

    this._timing = {
      createdAt: now(),
      initializingStartedAt: undefined,
      initializedAt: undefined,
      loadedAt: undefined,
      firstReadyAt: undefined,
    };

    this._volume = {
      volume: valueOrDefault(this._options.initial, 'volume', 100),
      muted: valueOrDefault(this._options.initial, 'muted', false),
    };

    this._playbackRate = {
      availablePlaybackRates: [
        {
          key: '1',
          name: `1 &times;`,
          playbackRate: 1,
        },
      ],
      activePlaybackRateIndex: '1',
    };

    this._quality = {
      quality: valueOrDefault(this._options.initial, 'quality', null),

      availableQualities: [],
      activeQualityIndex: -1,
      currentQualityName: 'Auto',
    };

    this._subtitles = {
      addedTrackIds: [],
      trackElements: [],
      availableSubtitles: [
        {
          key: 'Off/off',
          name: 'Off',
          language: 'off',
        },
      ],
      activeSubtitlesIndex: 'Off/off',
    };

    this._liveSubtitles = {
      tracks: {},
      subtitlesEnabled: valueOrDefault(this._options.initial, 'subtitlesEnabled', true),

      injectedTimes: [],
      lastSubtitlesEndTime: 0,
      delayMs: valueOrDefault(this._options.initial, 'liveSubtitlesDelayMs', 0),
      disableDeprecatedLiveSubtitles: true,
    };

    this._live = {
      started: null,
      finished: valueOrDefault(this._options.initial, 'liveFinished', false),

      seekToLiveBeforePlay: false,

      usingJsonMetadata: false,
      streamStartSet: false,
      streamStart: 0,
      streamStartRaw: 0,
      streamStartStartTime: 0,
      streamStartEndTime: 0,
      timeSinceStreamStartSet: false,
      timeSinceStreamStart: 0,

      inLivePosition: true,
      inLivePositionInitialCallbackCalled: false,

      streamMetadataTrackElement: null,
      streamMetadataTrack: null,
    };

    this._state = {
      initializing: false,
      initialized: false,
      loading: false,
      ready: false,
      state: '',

      playOnCanPlay: false,
      firstPlay: true,
      seekable: true,
      currentTime: 0,
      duration: 0,

      programDateTimeSet: false,
      programDateTime: undefined,
      programDateTimeSetAt: undefined,

      seekToFromEndOnCanPlay: null,
    };

    this._allowHlsJs = true;

    this._hlsJsErrors = {
      recoverMediaErrorCounter: 0,
      lastRecoverMediaErrorCallAt: null,
      audioCodecsSwapped: false,
      recoverMediaBufferAppendErrorCounter: 0,
      recoverMediaErrDecodeCounter: 0,
    };

    this._callbacks = new VideoServiceCallbacks();

    this._videoElement = null;
    this._sourceSwitcher = null;
    this._hlsJs = null;
  }

  on(event, callback) {
    this._callbacks.on(event, callback);
  }

  _runCallbacks(event, ...args) {
    this._callbacks.run(event, ...args);
  }

  load(sources, { videoId }) {
    this.loading = true;

    this._state.initializing = true;
    this._timing.initializingStartedAt = now();
    this._initialSources = sources;
    this._videoId = videoId || null;

    this._load();
  }

  setVideoSources(sources) {
    if (this._state.loaded) {
      this._sourceSwitcher.setSources(sources);
    } else {
      this._initialSources = sources;
    }
  }

  // public methods

  play() {
    log(LOG_TAG, 'play request', this._live.seekToLiveBeforePlay);

    const seekToLiveBeforePlay = this._live.seekToLiveBeforePlay;

    if (!this._state.seekable || (!this._live.liveFinished && seekToLiveBeforePlay)) {
      this._live.seekToLiveBeforePlay = false;
      this.seekToLivePosition();
    }

    const promise = this._videoElement.play();

    if (isVariableDefinedNotNull(promise)) {
      promise
        .then(() => {
          this._state.lastPlayFailed = false;
        })
        .catch((error) => {
          console.warn(LOG_TAG, 'play failed', error);

          this._runCallbacks('playFailed');
          this._state.lastPlayFailed = true;
          this._live.seekToLiveBeforePlay = seekToLiveBeforePlay;

          this.loading = false;
          this.state = 'paused';
        });
    }
  }

  pause() {
    log(LOG_TAG, 'pause request');
    this._videoElement.pause();
  }

  seekToLivePosition() {
    log(LOG_TAG, 'seek to live position', this._liveSyncPosition);

    this.currentTime = this._liveSyncPosition;

    this._live.inLivePosition = true;
    this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);
  }

  loadSubtitles(subtitles) {
    for (const subtitlesEntry of subtitles) {
      this._createSubtitlesTrack({
        language: subtitlesEntry.language,
        label: subtitlesEntry.name,
        url: subtitlesEntry.webvtt_url,
      });
    }
  }

  destroy() {
    this._destroyHlsJs();

    if (this._videoElement) {
      remove(this._videoElement);
      this._videoElement = null;
    }
  }

  // public getters and setters

  get useHlsJs() {
    return !!this._hlsJs;
  }

  get videoElement() {
    return this._videoElement;
  }

  get activeVideoSourceIndex() {
    return this._sourceSwitcher.activeSourceIndex;
  }

  set activeVideoSourceIndex(index) {
    this._sourceSwitcher.activeSourceIndex = index;
  }

  get ready() {
    return this._state.ready;
  }

  get loading() {
    return this._state.loading;
  }

  set loading(loading) {
    if (loading === this._state.loading) {
      return;
    }

    this._state.loading = loading;
    this._runCallbacks('loadingChanged', this._state.loading);
  }

  get duration() {
    return this._state.duration;
  }

  get currentTime() {
    return this._videoElement ? this._videoElement.currentTime * 1000.0 : 0;
  }

  set currentTime(value) {
    this._state.seekToFromEndOnCanPlay = null;
    this._live.seekToLiveBeforePlay = false;

    this._subtitles.previousSubtitleEndTime = null;

    const currentTimeBeforeSeek = this.currentTime;
    this._videoElement.currentTime = value / 1000.0;

    this._runCallbacks('seekRequest', currentTimeBeforeSeek, value);
  }

  get programDateTime() {
    return this._state.programDateTime;
  }

  get state() {
    return this._state.state;
  }

  set state(state) {
    if (state === this._state.state) {
      return;
    }

    this._state.state = state;
    this._runCallbacks('stateChanged', this._state.state);
  }

  get seeking() {
    return this._state.seeking;
  }

  set seeking(seeking) {
    this._state.seeking = seeking;
  }

  get volume() {
    return this._volume.volume;
  }

  set volume(value) {
    this._volume.muted = false;
    this._volume.volume = value;

    if (this._videoElement) this._videoElement.muted = this._volume.muted;
    if (this._videoElement) this._videoElement.volume = this._volume.volume / 100.0;

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get muted() {
    return this._volume.muted;
  }

  set muted(muted) {
    this._volume.muted = muted;

    if (this._videoElement) this._videoElement.muted = this._volume.muted;
    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get realPlaybackRate() {
    return this._videoElement ? this._videoElement.playbackRate : 1;
  }

  set realPlaybackRate(rate) {
    if (this._videoElement) this._videoElement.playbackRate = rate;
  }

  get activePlaybackRate() {
    return this._playbackRate.availablePlaybackRates.find(
      (rate) => rate.key === this._playbackRate.activePlaybackRateIndex,
    );
  }

  get activePlaybackRateIndex() {
    return this._playbackRate.activePlaybackRateIndex;
  }

  set activePlaybackRateIndex(index) {
    if (index !== this._playbackRate.activePlaybackRateIndex) {
      this._playbackRate.activePlaybackRateIndex = index;

      this._videoElement.playbackRate = this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1;

      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  get activeQuality() {
    return this._quality.availableQualities.find((quality) => quality.key === this._quality.activeQualityIndex);
  }

  get activeQualityIndex() {
    return this._quality.activeQualityIndex;
  }

  set activeQualityIndex(index) {
    if (index !== this._quality.activeQualityIndex) {
      this._quality.activeQualityIndex = index;

      if (this._hlsJs && this.activeQuality) {
        this._hlsJs.nextLevel = this.activeQuality.level;
      }

      this._runCallbacks('activeQualityIndexChanged', this._quality.activeQualityIndex);
    } else if (this._hlsJs && this.activeQuality && this.activeQuality.key !== 'auto') {
      if (this.activeQuality.level !== this._hlsJs.currentLevel) {
        this._hlsJs.currentLevel = this.activeQuality.level;
      }
    }
  }

  get activeSubtitles() {
    return this._subtitles.availableSubtitles.find(
      (subtitles) => subtitles.key === this._subtitles.activeSubtitlesIndex,
    );
  }

  get activeSubtitlesIndex() {
    return this._subtitles.activeSubtitlesIndex;
  }

  set activeSubtitlesIndex(index) {
    if (index !== this._subtitles.activeSubtitlesIndex) {
      this._subtitles.activeSubtitlesIndex = index;

      const activeSubtitles = this.activeSubtitles;

      for (const textTrack of this._videoElement.textTracks) {
        if (textTrack.kind === 'captions' || textTrack.kind === 'subtitles') {
          textTrack.mode = 'disabled';
          textTrack.mode = activeSubtitles && textTrack.id === activeSubtitles.key ? 'showing' : 'disabled';
        } else if (textTrack.kind === 'metadata') {
          textTrack.mode = 'hidden';
        }
      }

      this._runCallbacks('renderSubtitlesWord', null);
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }

  get activeVideoSourceUrl() {
    if (this._hlsJs) return this._hlsJs.url;

    return this._videoElement ? this._videoElement.src : null;
  }

  get activeQualityName() {
    return this.activeQuality ? this.activeQuality.name : null;
  }

  get activePlaybackRateValue() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : null;
  }

  get live() {
    return this._options.live;
  }

  get liveFinished() {
    return this._live.liveFinished;
  }

  set liveFinished(liveFinished) {
    this._state.seekToLiveBeforePlay = false;
    this._state.liveFinished = liveFinished;

    this._endByTime();
    this._updateInLivePosition();
    this._updateAvailablePlaybackRates();
  }

  set liveSubtitlesEnabled(enabled) {
    this._liveSubtitles.subtitlesEnabled = enabled;

    if (Object.values(this._liveSubtitles.tracks).length > 0) {
      if (this._liveSubtitles.subtitlesEnabled && this._liveSubtitles.trackMode === 'showing') {
        this.activeSubtitlesIndex = Object.values(this._liveSubtitles.tracks)[0].id;
      }

      if (!this._liveSubtitles.subtitlesEnabled /* && this._liveSubtitles.track.mode === 'showing' */) {
        this._liveSubtitles.trackMode = 'showing';
        this.activeSubtitlesIndex = 'Off/off';
      } else {
        this._liveSubtitles.trackMode = 'disabled';
      }
    } else {
      this._liveSubtitles.trackMode = 'showing';
    }

    this._updateAvailableSubtitles();
  }

  set liveSubtitlesDelayMs(delayMs) {
    this._liveSubtitles.delayMs = delayMs;
  }

  get inLivePosition() {
    return this._live.inLivePosition;
  }

  get ratio() {
    return this._ratio;
  }

  set size(size) {}

  // private methods

  _load() {
    this._state.initializing = false;
    this._state.initialized = true;
    this._timing.initializedAt = now();

    this._videoElement = createVideoElement();
    // setupMuxOnVideoElement({ videoElement: this._videoElement, options: this._options });

    this._element.insertAdjacentElement('beforeend', this._videoElement);

    const {
      supported,
      type: playbackType,
      errors,
      errorReport,
    } = validatePlaybackSupport({
      videoElement: this._videoElement,
    });

    if (!supported) {
      this._runCallbacks('showError', `${errors.join('<br>')}`);
      this._runCallbacks('reportError', 'HLS_PLAYER', errorReport.errorName, errorReport.reportData);

      return;
    }

    this._allowHlsJs = playbackType === 'hls.js';
    this._addVideoElementListeners();

    this._sourceSwitcher = new SourceSwitcher(this);
    this._sourceSwitcher.on('availableSourcesChanged', (sources) => {
      if (sources.length === 0) {
        this._updateLiveStreamStarted(false);
      }

      this._runCallbacks('availableVideoSourcesChanged', sources);
    });
    this._sourceSwitcher.on('activeSourceIndexChanged', (index) =>
      this._runCallbacks('activeVideoSourceIndexChanged', index),
    );
    this._sourceSwitcher.on('sourceUrlChanged', (sourceFormat, sourceUrl) =>
      this._setPlaybackUrl(sourceFormat, sourceUrl),
    );

    this._state.loaded = true;
    this._timing.loadedAt = now();

    if (this._subtitles.trackElements.length > 0) {
      for (const track of this._subtitles.trackElements) {
        this._videoElement.appendChild(track);
      }
    }

    this._sourceSwitcher.setSources(this._initialSources);
  }

  _addVideoElementListeners() {
    addListener(this._videoElement, 'load', logEvent);
    addListener(this._videoElement, 'loadend', logEvent);
    addListener(this._videoElement, 'loadstart', logEvent);
    addListener(this._videoElement, 'offline', logEvent);
    addListener(this._videoElement, 'online', logEvent);
    addListener(this._videoElement, 'readystatechange', logEvent);
    addListener(this._videoElement, 'emptied', logEvent);

    addListener(this._videoElement, 'error', (event) => handleNativeError(this, this._videoElement.error, event));
    addListener(this._videoElement, 'stalled', (event) => this._onStalled(event));
    addListener(this._videoElement, 'suspend', (event) => this._onSuspend(event));
    addListener(this._videoElement, 'ended', (event) => this._onEnded(event));
    addListener(this._videoElement, 'loadedmetadata', (event) => this._onLoadedMetadata(event));
    addListener(this._videoElement, 'loadeddata', (event) => this._onLoadedData(event));
    addListener(this._videoElement, 'canplay', (event) => this._onCanPlay(event));
    addListener(this._videoElement, 'play', (event) => this._onPlay(event));
    addListener(this._videoElement, 'pause', (event) => this._onPause(event));
    addListener(this._videoElement, 'playing', (event) => this._onPlaying(event));
    addListener(this._videoElement, 'waiting', (event) => this._onWaiting(event));
    addListener(this._videoElement, 'seeking', (event) => this._onSeeking(event));
    addListener(this._videoElement, 'seeked', (event) => this._onSeeked(event));
    addListener(this._videoElement, 'timeupdate', (event) => this._onTimeUpdate(event));
    addListener(this._videoElement.textTracks, 'addtrack', (event) => this._onTextTracksAdd(event));
  }

  _createHlsJs() {
    const cmcdSessionId = this._options.analyticsSessionUuid;
    const cmcdContentIdParts = [`${this._options.presentationId}`, `${this._options.contentType}`];

    if (this._videoId) {
      cmcdContentIdParts.push(this._videoId);
    }

    this._hlsJs = createHlsJs({
      analyticsUserUuid: this._options.analyticsUserUuid,
      analyticsSessionUuid: this._options.analyticsSessionUuid,
      presentationId: this._options.presentationId,
      contentType: this._options.contentType,
      videoId: this._videoId,
      cmcdEnabled: this._options.cmcdEnabled,
      cmcdSessionId,
      cmcdContentId: cmcdContentIdParts.join('/'),
    });

    this._hlsJs.on(Hls.Events.ERROR, (event, data) => handleHlsJsError(this, data));
    this._hlsJs.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) =>
      this._injectLiveSubtitlesFromHlsJsFragMetadata(data),
    );
    this._hlsJs.on(Hls.Events.LEVEL_SWITCHED, (event, data) => this._updateCurrentQualityNameForHlsJs(data));
  }

  _destroyHlsJs() {
    if (this._hlsJs) {
      this._hlsJs.detachMedia();
      this._hlsJs.destroy();
      this._hlsJs = null;
    }
  }

  _resetHlsJsErrors() {
    this._hlsJsErrors.lastRecoverMediaErrorCallAt = null;
    this._hlsJsErrors.audioCodecsSwapped = false;
  }

  _setPlaybackUrl(format, url, { autoPlay = false, seekable = true } = {}) {
    log(LOG_TAG, 'loading video from', format, url);

    if (!this._live || this._live.started) {
      this.loading = true;
    }

    this._state.playOnCanPlay = isVariableDefinedNotNull(autoPlay) ? autoPlay : this._state.state === 'playing';

    if (!this._live.liveFinished && (!this.ready || this.inLivePosition)) {
      this._state.seekToFromEndOnCanPlay = 'live';
    } else {
      this._state.seekToFromEndOnCanPlay = this.duration - this.currentTime;
    }

    const activeSubtitlesKey = this.activeSubtitles?.key;
    const trackElements = this._videoElement.querySelectorAll('track[kind="subtitles"], track[kind="captions"]');
    for (const track of trackElements) {
      this._videoElement.removeChild(track);
    }

    this._resetLiveSubtitles();
    this._resetStreamMetadataTrack();

    this._state.seekable = seekable;
    this._runCallbacks('seekableChanged', this._state.seekable);

    if (this._hlsJs) {
      this._destroyHlsJs();
      this._resetHlsJsErrors();

      this._hlsJs = undefined;
    }

    const useHlsJs = this._allowHlsJs && format === 'hls';

    if (useHlsJs) {
      this._createHlsJs();

      this._hlsJs.loadSource(url);
      this._hlsJs.attachMedia(this._videoElement);

      if (this._videoElement.mux) {
        this._videoElement.mux.removeHLSJS();
        this._videoElement.mux.addHLSJS({
          Hls,
          hlsjs: this._hlsJs,
        });
      }
    } else {
      this._videoElement.src = url;
    }

    if (this._videoElement.mux) {
      mux.emit(this._videoElement, 'videochange', { custom_2: url });
    }

    this._runCallbacks('renderSubtitlesWord', null);

    for (const track of trackElements) {
      this._videoElement.appendChild(track);
    }

    this.activeSubtitlesIndex = 'Off/off';
    this.activeSubtitlesIndex = activeSubtitlesKey;
  }

  _canPlay() {
    log(LOG_TAG, 'customCanPlay');

    // this._sourceSwitcher.stopReloadVideoFromNextServer();

    let timesChanged = false;
    if (!this.ready || this.duration === 0) {
      timesChanged = true;

      this._state.duration = this._calcDuration();
    }

    if (!this.ready && this.live && this._state.liveFinished) {
      timesChanged = true;
    }

    if (this.ready) {
      if (this._state.seekToFromEndOnCanPlay === 'live') {
        timesChanged = true;

        if (!this.inLivePosition) {
          this.seekToLivePosition();
        }

        this._live.seekToLiveBeforePlay = true;
      } else if (isVariableDefinedNotNull(this._state.seekToFromEndOnCanPlay)) {
        timesChanged = true;

        this.currentTime = Math.min(this.duration, this.duration - this._state.seekToFromEndOnCanPlay);
      }
    }

    this._state.seekToFromEndOnCanPlay = undefined;

    if (timesChanged) {
      this._updateInLivePosition();
      this._runCallbacks('timesChanged', this.currentTime, this.duration);
    }

    if (this.ready) {
      this._stopLoadingIfPossible();

      if (this._videoElement.paused) {
        this.state = 'paused';
      } else {
        this.state = 'playing';
      }
    } else {
      this._setReady();
    }

    if (this._state.playOnCanPlay) {
      this.play();

      this._state.playOnCanPlay = false;
    }
  }

  _setReady() {
    if (!this._timing.firstReadyAt) this._timing.firstReadyAt = now();

    this._updateLiveStreamStarted(true);

    this._updateAvailablePlaybackRates();
    this._updateAvailableQualities();
    this._updateAvailableSubtitles();

    this._videoElement.muted = this._volume.muted;
    this._videoElement.volume = this._volume.volume / 100.0;
    this._videoElement.playbackRate = this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1;

    this.loading = false;
    this.state = 'paused';

    this._runCallbacks('volumeChanged', this.volume, this.muted);

    this._state.ready = true;
    this._runCallbacks('ready');
  }

  _updateAvailablePlaybackRates() {
    const previousPlaybackRateKey = this.activePlaybackRate?.key;

    let playbackRates;
    if (this.live && !this._live.liveFinished) {
      playbackRates = [1];
    } else {
      playbackRates = [0.5, 1, 1.2, 1.45, 1.7, 2];
    }

    this._playbackRate.availablePlaybackRates = playbackRates.map((playbackRate) => ({
      key: playbackRate.toString(),
      name: `${playbackRate} &times;`,
      playbackRate,
    }));

    this._runCallbacks('availablePlaybackRatesChanged', this._playbackRate.availablePlaybackRates);

    const activePlaybackRateKey =
      this._playbackRate.availablePlaybackRates.findIndex((rate) => rate.key === previousPlaybackRateKey) >= 0
        ? previousPlaybackRateKey
        : '1';

    if (this.activePlaybackRateIndex !== activePlaybackRateKey) {
      this.activePlaybackRateIndex = activePlaybackRateKey;
    } else {
      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  _updateAvailableQualities() {
    let activeQualityKey;
    const availableQualities = [
      {
        key: 'auto',
        name: 'Auto',
        level: -1,
        bitrate: Infinity,
      },
    ];

    if (this._hlsJs) {
      for (const [id, track] of Object.entries(this._hlsJs.levels)) {
        const level = parseInt(id, 10);
        const key = level.toString();

        availableQualities.push({
          key,
          name: `${track.height}p`,
          level,
          bitrate: track.bitrate,
        });

        if (!activeQualityKey && level === this._hlsJs.currentLevel) {
          activeQualityKey = key;
        }
      }
    }

    if (
      !isVariableDefinedNotNull(activeQualityKey) ||
      this._quality.activeQualityIndex === -1 ||
      this._quality.activeQualityIndex === 'auto'
    ) {
      activeQualityKey = 'auto';
    }

    availableQualities.sort((a, b) => a.bitrate - b.bitrate);

    if (activeQualityKey === 'auto') {
      availableQualities.find((quality) => quality.key === 'auto').name = `Auto (${this._quality.currentQualityName})`;
    }

    this._quality.availableQualities = availableQualities;
    this._runCallbacks('availableQualitiesChanged', this._quality.availableQualities);

    if (this.activeQualityIndex !== activeQualityKey) this.activeQualityIndex = activeQualityKey;
  }

  _updateAvailableSubtitles() {
    const previousSubtitlesKey = this.activeSubtitles?.key;

    this._subtitles.availableSubtitles = [
      {
        key: 'Off/off',
        name: 'Off',
        language: 'off',
      },
    ];

    for (const textTrack of this._videoElement.textTracks) {
      if (textTrack.kind === 'captions' || textTrack.kind === 'subtitles') {
        this._subtitles.availableSubtitles.push({
          key: textTrack.id,
          name: textTrack.label,
          language: textTrack.language,
        });
      }
    }

    this._runCallbacks('availableSubtitlesChanged', this._subtitles.availableSubtitles);

    const activeSubtitlesIndex =
      this._subtitles.availableSubtitles.findIndex((subtitles) => subtitles.key === previousSubtitlesKey) >= 0
        ? previousSubtitlesKey
        : 'Off/off';

    if (this.activeSubtitlesIndex !== activeSubtitlesIndex) {
      this.activeSubtitlesIndex = activeSubtitlesIndex;
    } else {
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }

  _stopLoadingIfPossible() {
    const canStopLoading = !this.seeking;
    if (canStopLoading) {
      // this._sourceSwitcher.stopReloadVideoFromNextServer();
      this.loading = false;
    }
  }

  _calcDuration() {
    if (!this._videoElement) {
      return this.duration;
    }

    if (this._videoElement.seekable) {
      if (this._videoElement.seekable.length >= 1) {
        const end = this._videoElement.seekable.end(0);
        if (Number.isFinite(end)) {
          return Math.floor(this._videoElement.seekable.end(0) * 1000);
        }
      }
    }

    return Math.floor(this._videoElement.currentTime * 1000);
  }

  _end() {
    this.pause();

    if (!this._state.ended) {
      // this.sourceSwitcher.stopReloadVideoFromNextServer();
      this._state.ended = true;
      this._runCallbacks('ended');
    }
  }

  _endByTime() {
    if (this.duration === 0 || !this.live || !this._live.liveFinished) {
      return false;
    }

    if (Math.abs(this.duration - this.currentTime) > 500) {
      return false;
    }

    log(LOG_TAG, 'ended by time');
    this._end();

    return true;
  }

  _createSubtitlesTrack({ language = 'en', kind = 'subtitles', label = '', url = undefined }) {
    const key = generateRandomId(12);

    if (!url) {
      url = URL.createObjectURL(new Blob([''], { type: 'text/plain' }));
    }

    const trackElement = document.createElement('track');
    trackElement.srclang = language;
    trackElement.kind = kind;
    trackElement.label = label;
    trackElement.id = key;
    trackElement.src = url;

    this._subtitles.trackElements.push(trackElement);

    if (this._videoElement) {
      this._videoElement.appendChild(trackElement);
    }

    return key;
  }

  _updateLiveStreamStarted(started) {
    if (this._live.started === started) {
      return;
    }

    if (this._live.started) {
      return;
    }

    this.loading = false;
    this._live.started = started;
    this._runCallbacks('liveStreamStartedChanged', this._live.started);
  }

  _updateInLivePosition() {
    const livePositionDelta = this._hlsJs ? (this.duration - this._liveSyncPosition) * 2 : 30000;
    const inLivePosition =
      this.live && !this._live.liveFinished && this.duration - this.currentTime <= livePositionDelta;

    if (inLivePosition !== this._live.inLivePosition || !this._live.inLivePositionInitialCallbackCalled) {
      this._live.inLivePosition = inLivePosition;
      this._live.inLivePositionInitialCallbackCalled = true;
      this._runCallbacks('inLivePositionChanged', this._live.inLivePosition);
    }
  }

  _parseMetadataTextTracks() {
    for (const track of this._videoElement.textTracks) {
      if (track.kind !== 'metadata') continue;

      this._parseMetadataTextTrack(track);
    }
  }

  _parseMetadataTextTrack(track) {
    if (this._live.streamMetadataTrack && track.id === this._live.streamMetadataTrack.id) {
      if (!this.lastActiveStreamMetadataCueIds) this.lastActiveStreamMetadataCueIds = [];

      const activeStreamMetadataCueIds = [];

      if (track.activeCues && track.activeCues.length > 0) {
        for (const cue of track.activeCues) {
          if (activeStreamMetadataCueIds.includes(cue.__streamMetadataId)) continue;
          activeStreamMetadataCueIds.push(cue.__streamMetadataId);
          if (this.lastActiveStreamMetadataCueIds.includes(cue.__streamMetadataId)) continue;

          if (cue.__streamMetadataKind === 'subtitles_words') {
            const data = JSON.parse(cue.text);

            this._processStreamMetadataSubtitles(cue.startTime, data);
          }
        }
      }

      this.lastActiveStreamMetadataCueIds = activeStreamMetadataCueIds;
    } else if (track.activeCues && track.activeCues.length > 0) {
      for (const cue of track.activeCues) {
        this._parseID3MetadataFromTextTrackCue(cue);
      }
    }
  }

  _parseID3MetadataFromTextTrackCue(cue) {
    if (this.seeking) {
      return;
    }

    if (!cue.value) {
      console.warn(cue);
      return;
    }

    const parseStringValue = (c) => {
      const array = new Uint8Array(c.value.data);
      return ID3.utf8ArrayToStr(array);
    };

    const parseIntValue = (c) => parseInt(parseStringValue(c), 10);

    switch (cue.value.info) {
      case 'SL_STAR':
        if (this._live.usingJsonMetadata) break;

        if (cue.startTime < this._live.streamStartStartTime || cue.endTime > this._live.streamStartEndTime) {
          this._live.streamStartStartTime = cue.startTime;
          this._live.streamStartEndTime = cue.endTime;
          this._live.streamStart = parseIntValue(cue);

          this._runCallbacks('streamStartChanged', this._live.streamStart);
        }
        break;

      case 'SL_CURR':
        if (this._live.usingJsonMetadata) break;

        this._live.timeSinceStreamStart = parseIntValue(cue);
        this._runCallbacks('timeSinceStreamStartChanged', this._live.timeSinceStreamStart);
        break;

      case 'SL_SUBT':
        if (!this._hlsJs) {
          if (!this._liveSubtitlesInjected(cue.startTime)) {
            this._injectLiveSubtitles(cue.startTime, parseStringValue(cue), { addDelay: true });
          }
        }
        break;

      case 'SL_PRDT':
        if (this._live.usingJsonMetadata) break;

        if (cue.endTime - cue.startTime < 1000) {
          this._programDateTime = parseIntValue(cue);
        }
        break;

      case 'SlidesLiveStreamMetadata': {
        if (cue.endTime - cue.startTime > 1) {
          break;
        }
        this._live.usingJsonMetadata = true;

        const streamStartDateTimeBefore = this._live.streamStart;

        const metadata = JSON.parse(cue.value.data);
        this._live.streamStart = new Date(metadata.streamStartDateTime).getTime();
        this._live.timeSinceStreamStart = metadata.streamCurrentTime;
        this._programDateTime = new Date(metadata.programDateTime).getTime();

        const realProgramDateTime = metadata.programDateTime;
        const calculatedProgramDateTime = metadata.streamStartDateTime + metadata.streamCurrentTime;
        const programDateTimeDiff = realProgramDateTime - calculatedProgramDateTime;

        this._runCallbacks('debugInfo', {
          [`${metadata.originStreamName}       metadata version`]: metadata.v,
          [`${metadata.originStreamName}       videoCurrentTime`]: `${formatTimeString(
            this._videoElement.currentTime * 1000,
            {
              inMs: true,
              useColons: true,
              alwaysShowMs: true,
            },
          )} (${Math.round(this._videoElement.currentTime * 1000)})`,
          [`${metadata.originStreamName}      streamCurrentTime`]: `${formatTimeString(metadata.streamCurrentTime, {
            inMs: true,
            useColons: true,
            alwaysShowMs: true,
          })} (${metadata.streamCurrentTime})`,

          [`${metadata.originStreamName}    streamStartDateTime`]: metadata.streamStartDateTime,
          [`${metadata.originStreamName}  streamStartDateTime S`]: new Date(metadata.streamStartDateTime).toISOString(),
          [`${metadata.originStreamName}   real programDateTime`]: realProgramDateTime,
          [`${metadata.originStreamName}   calc programDateTime`]: calculatedProgramDateTime,
          [`${metadata.originStreamName} real programDateTime S`]: new Date(realProgramDateTime).toISOString(),
          [`${metadata.originStreamName} calc programDateTime S`]: new Date(calculatedProgramDateTime).toISOString(),
          [`${metadata.originStreamName}   diff programDateTime`]: programDateTimeDiff,
        });

        if (streamStartDateTimeBefore !== this._live.streamStart) {
          this._runCallbacks('streamStartChanged', this._live.streamStart);
        }

        this._runCallbacks('timeSinceStreamStartChanged', this._live.timeSinceStreamStart);

        if (metadata.streamMetadata) {
          this._processStreamMetadata(cue.startTime * 1000.0, metadata.streamCurrentTime, metadata.streamMetadata);
        }

        break;
      }

      default:
        console.warn('HLS:', 'Unknown ID3 metadata:', cue);
        break;
    }
  }

  _processStreamMetadata(cueTimeBase, streamCurrentTimeBase, streamMetadata) {
    if (!this._live.streamMetadataTrack) {
      this._createStreamMetadataTrack();
    }

    for (const entry of streamMetadata) {
      if (this._live.streamMetadataTrack.__injectedStreamMetadata.indexOf(entry.id) >= 0) continue;

      const startTime = cueTimeBase + entry.startStreamTime - streamCurrentTimeBase;
      const endTime = startTime + (entry.endStreamTime - entry.startStreamTime);

      const vttCue = new VTTCue(startTime / 1000.0, endTime / 1000.0, entry.data);
      vttCue.__streamMetadataId = entry.id;
      vttCue.__streamMetadataKind = entry.kind;
      this._live.streamMetadataTrack.addCue(vttCue);
    }
  }

  _createStreamMetadataTrack() {
    const key = generateRandomId(12);

    const trackElement = document.createElement('track');
    trackElement.kind = 'metadata';
    trackElement.id = key;
    trackElement.track.mode = 'hidden';

    if (this._videoElement) {
      this._videoElement.appendChild(trackElement);
    }

    this._live.streamMetadataTrackElement = trackElement;
    this._live.streamMetadataTrack = trackElement.track;
    this._live.streamMetadataTrack.__injectedStreamMetadata = [];

    return key;
  }

  _resetStreamMetadataTrack() {
    if (!this._live.streamMetadataTrack) return;

    if (this._live.streamMetadataTrack.cues) {
      for (const cue of this._live.streamMetadataTrack.cues) {
        this._live.streamMetadataTrack.removeCue(cue);
      }
    }

    this._live.streamMetadataTrack.__injectedStreamMetadata = [];
  }

  _processStreamMetadataSubtitles(startTime, data) {
    if (!this._liveSubtitles.tracks[data.language]) {
      this._createLiveSubtitlesTrack({ language: data.language });
    }

    if (this._liveSubtitles.tracks[data.language].mode === 'showing') {
      this._runCallbacks('renderSubtitlesWord', data.content);
    }
  }

  _createLiveSubtitlesTrack({ language = 'en' } = {}) {
    let languageName;

    if (language === 'cs') languageName = playerI18n(this._options.locale).cs;
    else if (language === 'de') languageName = playerI18n(this._options.locale).de;
    else if (language === 'en') languageName = playerI18n(this._options.locale).en;
    else if (language === 'sk') languageName = playerI18n(this._options.locale).sk;
    else languageName = language;

    const key = this._createSubtitlesTrack({
      language,
      label: `${languageName} (${playerI18n(this._options.locale).automated})`,
    });

    this._updateAvailableSubtitles();

    for (const track of this._videoElement.textTracks) {
      if (track.id === key) {
        this._liveSubtitles.tracks[language] = track;
        break;
      }
    }

    requestAnimationFrame(() => {
      if (this._liveSubtitles.subtitlesEnabled && this.activeSubtitlesIndex === 'Off/off') {
        this.activeSubtitlesIndex = key;
      }
    });
  }

  _resetLiveSubtitles() {
    for (const track of Object.values(this._liveSubtitles.tracks)) {
      if (track.cues) {
        for (const cue of track.cues) {
          track.removeCue(cue);
        }
      }
    }

    this._liveSubtitles.injectedSubtitleTimes = [];
  }

  _liveSubtitlesInjected(time) {
    return this._liveSubtitles.injectedTimes.indexOf(time) >= 0;
  }

  _injectLiveSubtitles(time, text, { addDelay }) {
    if (this._liveSubtitles.disableDeprecatedLiveSubtitles) return;

    if (!this._liveSubtitles.tracks.en) {
      this._createLiveSubtitlesTrack({ language: 'en' });
    }

    this._liveSubtitles.injectedTimes.push(time);

    let timeWithDelay = time;
    if (addDelay) {
      timeWithDelay += (this._live.liveSubtitlesDelayMs || 0) / 1000.0;
    }

    let subtitlesStartTime;
    if (
      this._liveSubtitles.lastSubtitlesEndTime &&
      Math.abs(timeWithDelay - this._liveSubtitles.lastSubtitlesEndTime) < 1
    ) {
      subtitlesStartTime = Math.max(timeWithDelay, this._liveSubtitles.lastSubtitlesEndTime);
    } else {
      subtitlesStartTime = timeWithDelay;
    }

    log(
      LOG_TAG,
      'inject subtitles',
      this.currentTime / 1000.0,
      time,
      subtitlesStartTime,
      subtitlesStartTime - time,
      this._live.liveSubtitlesDelayMs,
      text,
    );

    const subtitlesEndTime = subtitlesStartTime + 2;
    this._liveSubtitles.lastSubtitlesEndTime = subtitlesEndTime;

    const vttCue = new VTTCue(subtitlesStartTime, subtitlesEndTime, text);
    this._liveSubtitles.tracks.en.addCue(vttCue);
  }

  // native video element listeners

  _onLoadedMetadata(event) {
    logEvent(event);
    this._canPlay();
  }

  _onLoadedData(event) {
    logEvent(event);
    this._createStreamMetadataTrack();
    this._canPlay();
  }

  _onCanPlay(event) {
    logEvent(event);
    this._canPlay();
  }

  _onPlay(event) {
    logEvent(event);
    this._stopLoadingIfPossible();
    this.state = 'playing';
    this._state.ended = false;

    if (this._state.firstPlay) {
      this._runCallbacks('firstPlay');
      this._state.firstPlay = false;
    }
  }

  _onPause(event) {
    logEvent(event);
    this._stopLoadingIfPossible();
    this.state = 'paused';
  }

  _onPlaying(event) {
    logEvent(event);
    this._stopLoadingIfPossible();
    this.state = 'playing';
    this._state.ended = false;
  }

  _onWaiting(event) {
    logEvent(event);
    // this._sourceSwitcher.startLoadingTimeout(this.currentTime);
    this.loading = true;
  }

  _onStalled(event) {
    logEvent(event);
    this._stopLoadingIfPossible();
  }

  _onSuspend(event) {
    logEvent(event);
    this._stopLoadingIfPossible();
  }

  _onSeeking(event) {
    logEvent(event);
    this.loading = true;
    this.seeking = true;
    this._state.ended = false;
    this._runCallbacks('seeking');
  }

  _onSeeked(event) {
    logEvent(event);
    this.seeking = false;
    this._stopLoadingIfPossible();
    this._parseMetadataTextTracks();
    this._runCallbacks('seeked');

    if (!this.live) {
      this._programDateTime = this.currentTime;
    }
  }

  _onEnded(event) {
    logEvent(event);

    if (!this.live || this._live.liveFinished) {
      log(LOG_TAG, 'ended by event');
      this._end();
    } else {
      // this.sourceSwitcher.scheduleReloadVideoFromNextServer({ allowSameServer: true });

      this.loading = true;
      this.state = 'playing';
    }
  }

  _onTimeUpdate() {
    if (this.live) {
      if (
        this._state.state === 'playing' &&
        !this.loading &&
        !this.seeking &&
        isVariableDefinedNotNull(this._state.programDateTimeSetAt)
      ) {
        this._state.programDateTime += now() - this._state.programDateTimeSetAt;
        this._state.programDateTimeSetAt = now();
      }
    } else {
      this._programDateTime = this.currentTime;
    }

    this._state.duration = this._calcDuration();

    this._runCallbacks('timeUpdate', this.currentTime, this.duration);

    if (this._endByTime()) {
      return;
    }

    if (this.live && this.ready) {
      this._updateInLivePosition();
    }
  }

  _onTextTracksAdd(event) {
    logEvent(event);

    const track = event.track;
    if (track.kind !== 'metadata') {
      if (this._subtitles.addedTrackIds.indexOf(track.id) < 0) {
        this._subtitles.addedTrackIds.push(track.id);
        this._updateAvailableSubtitles();
      }

      return;
    }

    addListener(track, 'cuechange', (e) => this._parseMetadataTextTrack(e.target));

    if (track.mode !== 'hidden') {
      console.warn(LOG_TAG, 'native', 'metadata text track mode is', track.mode, track);
    }

    if (track === this._live.streamMetadataTrack) {
      track.__setHiddenInterval = setInterval(() => {
        if (track.mode !== 'hidden') {
          track.mode = 'hidden';
        }
      }, 1000);
    }
  }

  // hls.js listeners

  _injectLiveSubtitlesFromHlsJsFragMetadata(fragMetadata) {
    for (const sample of fragMetadata.samples) {
      const id3Data = ID3.getID3Data(sample.data, 0);
      const id3Frames = ID3.getID3Frames(id3Data);

      for (const frame of id3Frames) {
        if (frame.info === 'SL_SUBT') {
          if (!this._liveSubtitlesInjected(sample.pts)) {
            const text = ID3.utf8ArrayToStr(new Uint8Array(frame.data));

            this._injectLiveSubtitles(sample.pts, text, { addDelay: false });
          }
        }
      }
    }
  }

  _updateCurrentQualityNameForHlsJs(levelSwitchData) {
    if (this._hlsJs.levels[levelSwitchData.level] && this._hlsJs.levels[levelSwitchData.level].height > 0) {
      this._quality.currentQualityName = `${this._hlsJs.levels[levelSwitchData.level].height}p`;
    }

    if (this._quality.activeQualityIndex !== 'auto') {
      const quality = this._quality.availableQualities.find((q) => q.level === levelSwitchData.level);
      if (quality) {
        this._quality.activeQualityIndex = quality.key;
        this._runCallbacks('activeQualityIndexChanged', this._quality.activeQualityIndex);
      }
    }

    this._updateAvailableQualities();
  }

  // private getters and setters

  set _programDateTime(time) {
    this._state.programDateTimeSet = true;
    this._state.programDateTime = time;
    this._state.programDateTimeSetAt = now();

    this._runCallbacks('programDateTimeChanged', time);
  }

  get _liveSyncPosition() {
    if (this._hlsJs) {
      const liveSyncPosition = this._hlsJs.liveSyncPosition * 1000;
      if (isVariableDefinedNotNull(liveSyncPosition) && Number.isFinite(liveSyncPosition)) {
        return liveSyncPosition;
      }
    }

    return Math.max(0, this._calcDuration() - 8000);
  }
}

export default HlsV3;
