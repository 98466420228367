import { defer, inlineCss, isVariableDefinedNotNull, now } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';
import VideoServiceCallbacks from 'modules/player/video/services/helpers/video_service_callbacks';
import VimeoPlayerApiLoader from 'vimeo-player-api-loader';

const LOG_TAG = 'VIMEO';

const valueOrDefault = (object, key, defaultValue) =>
  isVariableDefinedNotNull(object[key]) ? object[key] : defaultValue;

export default class VimeoV2 {
  constructor(element, options) {
    this._element = element;
    this._options = options;

    this._videoId = null;

    this._ratio = valueOrDefault(this._options.initial, 'ratio', 16 / 9.0);

    this._loadingTimeout = null;

    this._timing = {
      createdAt: now(),
      initializingStartedAt: undefined,
      initializedAt: undefined,
      loadedAt: undefined,
      firstReadyAt: undefined,
    };

    this._volume = {
      volume: valueOrDefault(this._options.initial, 'volume', 100),
      muted: valueOrDefault(this._options.initial, 'muted', false),
    };

    this._playbackRate = {
      availablePlaybackRates: [
        {
          key: '1',
          name: `1 &times;`,
          playbackRate: 1,
        },
      ],
      activePlaybackRateIndex: '1',
    };

    this._quality = {
      quality: valueOrDefault(this._options.initial, 'quality', null),

      availableQualities: [],
      activeQualityIndex: -1,
      currentQualityName: 'Auto',
    };

    this._subtitles = {
      addedTrackIds: [],
      trackElements: [],
      availableSubtitles: [
        {
          key: 'Off/off',
          name: 'Off',
          language: 'off',
        },
      ],
      activeSubtitlesIndex: 'Off/off',
    };

    this._state = {
      initializing: false,
      initialized: false,
      loading: false,
      ready: false,
      playbackState: '',

      playOnCanPlay: false,
      firstPlay: true,
      seekable: true,
      currentTime: 0,
      duration: 0,
    };

    this._callbacks = new VideoServiceCallbacks();

    this._vimeo = null;
  }

  on(event, callback) {
    this._callbacks.on(event, callback);
  }

  _runCallbacks(event, ...args) {
    this._callbacks.run(event, ...args);
  }

  load(sources, { videoId }) {
    if (sources) {
      console.warn(LOG_TAG, 'multiple sources are not supported');
    }

    if (!videoId) {
      console.warn(LOG_TAG, 'video id must be set');
    }

    this.loading = true;
    this._state.initializing = true;
    this._timing.initializingStartedAt = now();
    this._videoId = videoId;

    VimeoPlayerApiLoader.load((VimeoSDK) => this._load(VimeoSDK));
  }

  // public methods

  play() {
    log(LOG_TAG, 'play request');

    this._state.ended = false;

    this._vimeo.play().catch((error) => {
      console.warn(LOG_TAG, 'play error', error);

      if (error.name === 'PrivacyError') {
        this._handleVimeoError({ error, source: 'play' });
      }

      this._runCallbacks('playFailed');
    });
  }

  pause() {
    log(LOG_TAG, 'pause request');

    this._vimeo.pause().catch((error) => {
      console.warn(LOG_TAG, 'pause error', error);

      if (error.name === 'PrivacyError') {
        this._handleVimeoError({ error, source: 'pause' });
      }
    });
  }

  seekToLivePosition() {
    console.warn(LOG_TAG, 'Not implemented:', 'seekToLivePosition');
  }

  loadSubtitles(subtitles) {
    console.warn(LOG_TAG, 'Not implemented:', 'loadSubtitles', subtitles);
  }

  destroy() {
    console.warn(LOG_TAG, 'Not implemented:', 'destroy');
  }

  // public getters and setters

  get useHlsJs() {
    return false;
  }

  get videoElement() {
    return null;
  }

  get activeVideoSourceIndex() {
    return 0;
  }

  set activeVideoSourceIndex(index) {}

  get ready() {
    return this._state.ready;
  }

  get loading() {
    return this._state.loading;
  }

  set loading(loading) {
    if (loading === this._state.loading) {
      return;
    }

    this._state.loading = loading;
    this._runCallbacks('loadingChanged', this._state.loading);
  }

  get duration() {
    return this._state.duration;
  }

  get currentTime() {
    return this._state.currentTime;
  }

  set currentTime(value) {
    const currentTimeBeforeSeek = this.currentTime;

    this._vimeo.setCurrentTime(value / 1000.0).then((seconds) => {
      this._state.currentTime = seconds * 1000;
    });

    this._runCallbacks('seekRequest', currentTimeBeforeSeek, value);
  }

  get programDateTime() {
    return this.currentTime;
  }

  get state() {
    return this._state.state;
  }

  set state(state) {
    if (state === this._state.state) {
      return;
    }

    this._state.state = state;
    this._runCallbacks('stateChanged', this._state.state);
  }

  get seeking() {
    return this._state.seeking;
  }

  set seeking(seeking) {
    this._state.seeking = seeking;
  }

  get volume() {
    return this._volume.volume;
  }

  set volume(value) {
    this._volume.muted = false;
    this._volume.volume = value;

    if (this._vimeo) this._vimeo.setVolume(this._volume.volume / 100.0);

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get muted() {
    return this._volume.muted;
  }

  set muted(muted) {
    this._volume.muted = muted;

    if (this._vimeo) {
      if (muted) {
        this._vimeo.setVolume(0);
      } else {
        this._vimeo.setVolume(this._volume.volume / 100.0);
      }
    }

    this._runCallbacks('volumeChanged', this._volume.volume, this._volume.muted);
  }

  get realPlaybackRate() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1;
  }

  set realPlaybackRate(rate) {
    if (this._vimeo) {
      this._vimeo.setPlaybackRate(rate);
    }
  }

  get activePlaybackRate() {
    return this._playbackRate.availablePlaybackRates.find(
      (rate) => rate.key === this._playbackRate.activePlaybackRateIndex,
    );
  }

  get activePlaybackRateIndex() {
    return this._playbackRate.activePlaybackRateIndex;
  }

  set activePlaybackRateIndex(index) {
    if (index !== this._playbackRate.activePlaybackRateIndex) {
      this._playbackRate.activePlaybackRateIndex = index;

      this._vimeo.setPlaybackRate(this.activePlaybackRate ? this.activePlaybackRate.playbackRate : 1);

      this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
    }
  }

  get activeQuality() {
    return this._quality.availableQualities.find((quality) => quality.key === this._quality.activeQualityIndex);
  }

  get activeQualityIndex() {
    return this._quality.activeQualityIndex;
  }

  set activeQualityIndex(index) {
    if (index !== this._quality.activeQualityIndex) {
      this._quality.activeQualityIndex = index;
      this._runCallbacks('activeQualityIndexChanged', this._quality.activeQualityIndex);
    }
  }

  get activeSubtitles() {
    return this._subtitles.availableSubtitles.find(
      (subtitles) => subtitles.key === this._subtitles.activeSubtitlesIndex,
    );
  }

  get activeSubtitlesIndex() {
    return this._subtitles.activeSubtitlesIndex;
  }

  set activeSubtitlesIndex(index) {
    if (index !== this._subtitles.activeSubtitlesIndex) {
      this._subtitles.activeSubtitlesIndex = index;

      const activeSubtitles = this.activeSubtitles;

      if (activeSubtitles.id === 'Off/off') {
        this._vimeo.disableTextTrack();
      } else {
        this._vimeo.enableTextTrack(activeSubtitles.language);
      }

      this._runCallbacks('renderSubtitlesWord', null);
      this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
    }
  }

  get activeVideoSourceUrl() {
    return this._element.querySelector('iframe')?.src || null;
  }

  get activeQualityName() {
    return this.activeQuality ? this.activeQuality.name : null;
  }

  get activePlaybackRateValue() {
    return this.activePlaybackRate ? this.activePlaybackRate.playbackRate : null;
  }

  get live() {
    return false;
  }

  get liveFinished() {
    return false;
  }

  set liveFinished(liveFinished) {}

  set liveSubtitlesEnabled(enabled) {}

  set liveSubtitlesDelayMs(delayMs) {}

  get inLivePosition() {
    return false;
  }

  get ratio() {
    return this._ratio;
  }

  set size(size) {
    this._element.style.width = `${size.w}px`;
    this._element.style.height = `${size.h}px`;
  }

  //

  _load(VimeoSDK) {
    this._state.initializing = false;
    this._state.initialized = true;
    this._timing.initializedAt = now();

    const vimeoOptions = {
      loop: false,
      autoplay: false,
      byline: false,
      portrait: false,
      title: false,
      responsive: true,
      speed: true,
      dnt: true,
      controls: true,
    };

    const vimeoOptionsString = Object.entries(vimeoOptions)
      .map((pair) => pair.join('='))
      .join('&');

    const url = `https://player.vimeo.com/video/${this._videoId}?${vimeoOptionsString}`;
    const iframe = document.createElement('iframe');

    inlineCss(iframe, {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    });

    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute('referrerpolicy', 'origin');
    iframe.src = url;

    this._element.insertAdjacentElement('beforeend', iframe);

    this._vimeo = new VimeoSDK.Player(iframe);

    this._vimeo.on('error', (data) => this._onVimeoError(data));
    this._vimeo.on('play', (data) => this._onVimeoPlay(data));
    this._vimeo.on('pause', (data) => this._onVimeoPause(data));
    this._vimeo.on('ended', (data) => this._onVimeoEnded(data));
    this._vimeo.on('loaded', (data) => this._onVimeoLoaded(data));
    this._vimeo.on('seeked', (data) => this._onVimeoSeeked(data));
    this._vimeo.on('timeupdate', (data) => this._onVimeoTimeupdate(data));
    this._vimeo.on('texttrackchange', (data) => this._onVimeoTextTrackChange(data));

    this._vimeo.on('bufferend', () => {
      this.loading = false;
    });

    this._vimeo.on('bufferstart', () => {
      this.loading = true;
    });

    this._vimeo.on('playbackratechange', (data) => {
      const activePlaybackRateKey =
        this._playbackRate.availablePlaybackRates.find((rate) => rate.playbackRate === data.playbackRate)?.key || '1';

      if (this.activePlaybackRateIndex !== activePlaybackRateKey) {
        this.activePlaybackRateIndex = activePlaybackRateKey;
      } else {
        this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
      }
    });

    this._vimeo.ready().catch((error) => {
      console.warn(LOG_TAG, 'ready error', error);

      this._clearLoadingTimeout();
      this._handleVimeoError({ error, source: 'ready_promise' });
    });

    this._loadingTimeout = setTimeout(
      () => () => {
        const error = { name: 'LoadingTimeout', message: 'Loading timed out after 2 minutes.' };

        this._handleVimeoError({ error, source: 'timeout_callback' });
      },
      120000,
    );

    Promise.all([this._vimeo.getVideoWidth(), this._vimeo.getVideoHeight()]).then((dimensions) => {
      const width = dimensions[0];
      const height = dimensions[1];
      const ratio = width / height;

      this._ratio = ratio;
      this._runCallbacks('ratioChanged', ratio);
    });

    this._state.loaded = true;
    this._timing.loadedAt = now();
  }

  // Internal

  _onVimeoError(error) {
    log(LOG_TAG, 'error', error);

    if (error.name === 'PrivacyError') {
      this._handleVimeoError({ error, source: 'error_callback' });
    }

    this._clearLoadingTimeout();
  }

  _onVimeoPlay(data) {
    log(LOG_TAG, 'play', data);

    this.loading = false;
    this.state = 'playing';
    this._state.ended = false;
  }

  _onVimeoPause(data) {
    log(LOG_TAG, 'pause', data);

    this.loading = false;
    this.state = 'paused';
  }

  _onVimeoEnded(data) {
    log(LOG_TAG, 'ended', data);

    this.loading = false;
    this.state = 'paused';

    this._state.ended = true;
    this._runCallbacks('ended');
  }

  _onVimeoLoaded(data) {
    log(LOG_TAG, 'loaded', data);

    this._canPlay();
  }

  _onVimeoSeeked(data) {
    log(LOG_TAG, 'seeked', data);

    this.loading = false;

    this.seeking = false;
    this._runCallbacks('seeked');
  }

  _onVimeoTimeupdate(data) {
    this._state.currentTime = Math.floor(data.seconds * 1000);
    this._state.duration = Math.floor(data.duration * 1000);
  }

  _onVimeoTextTrackChange(data) {
    log(LOG_TAG, 'text track change', data);
  }

  _clearLoadingTimeout() {
    if (this._loadingTimeout) {
      clearTimeout(this._loadingTimeout);
      this._loadingTimeout = null;
    }
  }

  _canPlay() {
    if (!this.ready) {
      this._setReady();
    }
  }

  _setReady() {
    if (!this._timing.firstReadyAt) this._timing.firstReadyAt = now();

    this._clearLoadingTimeout();

    this._updateAvailablePlaybackRates();
    this._updateAvailableQualities();
    this._updateAvailableSubtitles();

    this._vimeo.getDuration().then((seconds) => {
      this._state.duration = Math.floor(seconds * 1000);

      this._updateAvailablePlaybackRates();

      if (this._volume.muted) {
        this._vimeo.setVolume(0);
      } else {
        this._vimeo.setVolume(this._volume.volume / 100.0);
      }

      this._runCallbacks('volumeChanged', this.volume, this.muted);

      this._state.seekable = true;
      this._runCallbacks('seekableChanged', this._state.seekable);

      this.loading = false;
      this.state = 'paused';

      defer(() => {
        this._state.ready = true;
        this._runCallbacks('ready');
      });
    });
  }

  _updateAvailablePlaybackRates() {
    const previousPlaybackRateKey = this.activePlaybackRate?.key;

    const updateAvailablePlaybackRates = (playbackRates) => {
      this._playbackRate.availablePlaybackRates = playbackRates.map((playbackRate) => ({
        key: playbackRate.toString(),
        name: `${playbackRate} &times;`,
        playbackRate,
      }));

      this._runCallbacks('availablePlaybackRatesChanged', this._playbackRate.availablePlaybackRates);

      const activePlaybackRateKey =
        this._playbackRate.availablePlaybackRates.findIndex((rate) => rate.key === previousPlaybackRateKey) >= 0
          ? previousPlaybackRateKey
          : '1';

      if (this.activePlaybackRateIndex !== activePlaybackRateKey) {
        this.activePlaybackRateIndex = activePlaybackRateKey;
      } else {
        this._runCallbacks('activePlaybackRateIndexChanged', this._playbackRate.activePlaybackRateIndex);
      }
    };

    this._vimeo
      .setPlaybackRate(1)
      .then(() => {
        updateAvailablePlaybackRates([0.5, 1, 1.25, 1.5, 2]);
      })
      .catch(() => {
        updateAvailablePlaybackRates([1]);
      });
  }

  _updateAvailableQualities() {
    let activeQualityKey;
    const availableQualities = [
      {
        key: 'auto',
        name: 'Auto',
        level: -1,
        bitrate: Infinity,
      },
    ];

    if (
      !isVariableDefinedNotNull(activeQualityKey) ||
      this._quality.activeQualityIndex === -1 ||
      this._quality.activeQualityIndex === 'auto'
    ) {
      activeQualityKey = 'auto';
    }

    availableQualities.sort((a, b) => a.bitrate - b.bitrate);

    if (activeQualityKey === 'auto') {
      availableQualities.find((quality) => quality.key === 'auto').name = `Auto (${this._quality.currentQualityName})`;
    }

    this._quality.availableQualities = availableQualities;
    this._runCallbacks('availableQualitiesChanged', this._quality.availableQualities);

    if (this.activeQualityIndex !== activeQualityKey) this.activeQualityIndex = activeQualityKey;
  }

  _updateAvailableSubtitles() {
    const previousSubtitlesKey = this.activeSubtitles?.key;
    const availableSubtitles = [
      {
        key: 'Off/off',
        name: 'Off',
        language: 'off',
      },
    ];

    this._vimeo.getTextTracks().then((tracks) => {
      for (let i = 0; i < tracks.length; ++i) {
        const track = tracks[i];
        availableSubtitles.push({
          key: track.label,
          name: track.label,
          language: track.language,
        });
      }

      this._subtitles.availableSubtitles = availableSubtitles;
      this._runCallbacks('availableSubtitlesChanged', this._subtitles.availableSubtitles);

      const activeSubtitlesIndex =
        this._subtitles.availableSubtitles.findIndex((subtitles) => subtitles.key === previousSubtitlesKey) >= 0
          ? previousSubtitlesKey
          : 'Off/off';

      if (this.activeSubtitlesIndex !== activeSubtitlesIndex) {
        this.activeSubtitlesIndex = activeSubtitlesIndex;
      } else {
        this._runCallbacks('activeSubtitlesIndexChanged', this._subtitles.activeSubtitlesIndex);
      }
    });
  }

  _handleVimeoError({ error, source }) {
    this._showError(error);
    this._reportError(error, { source });
  }

  _showError(error) {
    let errorMessage;

    if (error.name === 'PrivacyError') {
      errorMessage = ['Video is not allowed to be played here.', 'Report this error to support@slideslive.com.'];
    } else if (error.name === 'NotFoundError') {
      errorMessage = ['Video not found.', 'Report this error to support@slideslive.com.'];
    } else if (error.name === 'LoadingTimeout') {
      errorMessage = [
        'Video loading timeout.',
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    } else {
      errorMessage = [
        'Video loading failed.',
        'Please try again or report this error to support@slideslive.com if it persists.',
      ];
    }

    errorMessage.push('');
    errorMessage.push(`<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`);

    errorMessage.push('');
    errorMessage.push(`<span class="text__paragraph--tiniest">${error.name}</span>`);
    errorMessage.push(`<span class="text__paragraph--tiniest">${error.message}</span>`);

    this._runCallbacks('showError', `${errorMessage.join('<br>')}`);
  }

  _reportError(error, { source = 'unknown', warn = false } = {}) {
    const reportData = {};

    reportData.warn = warn;
    reportData.source = source;

    reportData.state = {
      video_id: this._videoId,
      ready: this.ready,
      currentTime: this.currentTime,
      duration: this.duration,
    };

    reportData.error = {
      name: error.name,
      message: error.message,
    };

    const component = 'VIMEO_PLAYER';

    if (error.name === 'PrivacyError') {
      this._runCallbacks('reportError', component, 'PRIVACY_ERROR', reportData);
    } else if (error.name === 'NotFoundError') {
      this._runCallbacks('reportError', component, 'VIDEO_NOT_FOUND', reportData);
    } else if (error.name === 'LoadingTimeout') {
      this._runCallbacks('reportError', component, 'SL_LOADING_TIMEOUT', reportData);
    } else {
      this._runCallbacks('reportError', component, 'OTHER_ERROR', reportData);
    }
  }
}
