import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';

import ERROR_REPORT_DATA_VERSION from '../errors/error_report_data_version';
import nativeErrorCodeName from '../errors/native_error_code_name';
import nativeVideoNetworkStateName from '../errors/native_video_network_state_name';
import nativeVideoReadyStateName from '../errors/native_video_ready_state_name';

function createErrorReportData(
  videoService,
  error,
  { source = 'unknown', warn = false, nativeError = false, customErrorName = null } = {},
) {
  const reportData = {
    warn,
    source,
  };

  if (isVariableDefinedNotNull(videoService.props.hlsJsRecoverMediaErrorCounter)) {
    reportData.hls_js_recover_media_error_counter = videoService.props.hlsJsRecoverMediaErrorCounter;
  }

  reportData.state = {
    report_version: ERROR_REPORT_DATA_VERSION,
    hls_js_supported: window.Hls.isSupported(),
    using_hls_js_player: videoService.props.useHlsJs,

    native_video_can_play_dash: videoService.video.canPlayType('application/dash+xml'),
    native_video_can_play_hls: videoService.video.canPlayType('application/vnd.apple.mpegurl'),
    native_video_can_play_mp4: videoService.video.canPlayType('video/mp4'),

    native_video_has_error: !!videoService.video.error,
    native_video_network_state: nativeVideoNetworkStateName(videoService.video.networkState),
    native_video_ready_state: nativeVideoReadyStateName(videoService.video.readyState),

    player_ready: videoService.props.ready,
    player_current_time: videoService.currentTime,
    player_duration: videoService.duration,

    hls_js_recover_media_error_counter: videoService.props.hlsJsRecoverMediaErrorCounter,
    hls_js_audio_codecs_swapped: videoService.props.hlsJsAudioCodecsSwapped,
    hls_js_recover_media_buffer_append_error_counter: videoService.props.hlsJsRecoverMediaBufferAppendErrorCounter,
    hls_js_recover_media_err_decode_counter: videoService.props.hlsJsRecoverMediaErrDecodeCounter,

    stream_started: videoService.props.started,
  };

  if (error) {
    if (nativeError) {
      reportData.native_error = {
        error: error.constructor.name,
        code: nativeErrorCodeName(error),
        message: error.message,
      };

      try {
        reportData.raw_native_error = JSON.stringify(error);
      } catch {
        reportData.raw_native_error = null;
      }
    } else {
      reportData.hls_js_error = {
        type: error.type,
        details: error.details,
        fatal: error.fatal,
      };

      try {
        reportData.raw_hls_js_error = JSON.stringify(error);
      } catch {
        reportData.raw_hls_js_error = null;
      }

      if (error.url) {
        reportData.uri = error.url;
        reportData.hls_js_error.url = error.url;
      }

      if (error.response) {
        reportData.hls_js_error.response = {};

        if (error.response.code) {
          reportData.hls_js_error.response.code = error.response.code;
        }

        if (error.response.text) {
          reportData.hls_js_error.response.text = error.response.text;
        }
      }

      if (error.reason) {
        reportData.hls_js_error.reason = error.reason;
      }

      if (error.level) {
        reportData.hls_js_error.level = error.level;
      }

      if (error.mimeType) {
        reportData.hls_js_error.mimeType = error.mimeType;
      }

      if (error.buffer) {
        reportData.hls_js_error.buffer = error.buffer;
      }

      if (error.hole) {
        reportData.hls_js_error.hole = error.hole;
      }

      if (error.err) {
        reportData.hls_js_error.err = {};

        if (error.err.message) {
          reportData.hls_js_error.err.message = error.err.message;
        }
      }

      if (error.error) {
        reportData.hls_js_error.error = { name: error.error.constructor.name };

        if (error.error.code) {
          reportData.hls_js_error.error.code = error.error.code;
        }

        if (error.error.message) {
          reportData.hls_js_error.error.message = error.error.message;
        }
      }
    }
  }

  if (videoService.video && videoService.video.error) {
    reportData.video_error = {
      error: videoService.video.error.constructor.name,
      code: nativeErrorCodeName(videoService.video.error.code),
      message: videoService.video.error.message,
    };

    try {
      reportData.raw_video_error = JSON.stringify(videoService.video.error);
    } catch {
      reportData.raw_video_error = null;
    }
  }

  let errorName;
  if (customErrorName) {
    errorName = `C::${customErrorName}`;
  } else if (nativeError) {
    errorName = `N::${error.constructor.name}::${nativeErrorCodeName(error)}`;
  } else {
    errorName = `S::${error.type}::${error.details}`;
  }

  return {
    errorName,
    reportData,
  };
}

export default createErrorReportData;
