import { urlParam } from '@slideslive/fuse-kit/utils';

function log(category, ...args) {
  let debugSlidesLivePlayer = urlParam('debug_slideslive_player');
  debugSlidesLivePlayer = debugSlidesLivePlayer && debugSlidesLivePlayer.toUpperCase();
  if (debugSlidesLivePlayer && debugSlidesLivePlayer !== 'FALSE') {
    const c = category.toString() || '';
    if (
      debugSlidesLivePlayer === 'TRUE' ||
      debugSlidesLivePlayer === 'YES' ||
      debugSlidesLivePlayer === c.toUpperCase()
    ) {
      // eslint-disable-next-line no-console
      console.debug(c.padEnd(8, ' '), ...args);
    }
  }
}

export default log;
