import { isMobileDevice } from '@slideslive/fuse-kit/utils';
import log from 'modules/player/log';

import Html from './html/html';
import SizeCalc from './size_calc';

export default class View {
  constructor(element, options, callbacks) {
    this.element = element;
    this.options = options;
    this.callbacks = callbacks;

    this.props = {
      mode: this.options.mode,

      slidesInSyncTimeout: null,
    };

    this.createHtml();
    this.createSizeCalc();
  }

  createHtml() {
    this.html = new Html(
      this.element,
      {
        locale: this.options.locale,
        disableFullscreen: this.options.disableFullscreen,
        hideZoomControls: this.options.hideZoomControls,
        zoomRatio: this.options.zoomRatio,
        zoomSliderColor: this.options.zoomSliderColor,
        zoomHandleColor: this.options.zoomHandleColor,
        hideBigPlayButton: this.options.hideBigPlayButton,
        allowHiddenControlsWhenPaused: this.options.allowHiddenControlsWhenPaused,
        mode: this.props.mode,
      },
      {
        actionCallback: (action) => this.handleAction(action),
        actionWithParamCallback: (action, param) => this.handleActionWithParam(action, param),
        progressImageUrlCallback: (time) => this.callbacks.progressImageUrl(time),
        controlsVisibilityChanged: (visible) => this.callbacks.controlsVisibilityChanged(visible),
      },
    );
  }

  createSizeCalc() {
    this.sizeCalc = new SizeCalc(
      this.element,
      {
        hideZoomControls: this.options.hideZoomControls,
        verticalEnabled: this.options.verticalEnabled,
        verticalEnabledOnMobile: this.options.verticalEnabledOnMobile,
        verticalDisabledWhenWidthGte: this.options.verticalDisabledWhenWidthGte,
        verticalWhenWidthLte: this.options.verticalWhenWidthLte,
        maxHeight: this.options.maxHeight,
        maxWidth: this.options.maxWidth,
        fitToViewport: this.options.fitToViewport,
        mode: this.props.mode,
      },
      (size) => {
        log('VIEW', 'set size', size);

        this.html.setSize(size);
        this.callbacks.sizeChanged(size);
      },
      (ratio) => {
        this.callbacks.zoomRatioChanged(ratio);
      },
    );
  }

  toggleClassesAfterRender() {
    this.html.toggleClass('mobile', isMobileDevice());
    this.html.toggleClass('hideThumbnail', !this.options.showThumbnail);
    this.html.toggleClass('originalVideoControls', this.options.originalVideoControls);
  }

  renderClickToLoad() {
    this.html.renderClickToLoad();

    this.sizeCalc.message = true;
    // this.sizeCalc.compactDefault = true;
    this.sizeCalc.updateSize(true, { withoutTimeout: true });

    this.toggleClassesAfterRender();
  }

  renderPlayer() {
    this.html.renderPlayer();

    this.sizeCalc.message = false;
    this.sizeCalc.compactDefault = false;
    this.sizeCalc.updateSize(true, { withoutTimeout: true });

    this.toggleClassesAfterRender();
  }

  showError(error) {
    this.html.renderError(error);

    this.sizeCalc.message = true;
    this.sizeCalc.updateSize(true, { withoutTimeout: true });

    this.toggleClassesAfterRender();
  }

  showProcessing(text) {
    this.html.renderProcessing(text);

    this.sizeCalc.message = true;
    this.sizeCalc.updateSize(true, { withoutTimeout: true });

    this.toggleClassesAfterRender();
  }

  showLivestreamFinished() {
    this.html.renderLivestreamFinished();

    this.sizeCalc.message = true;
    this.sizeCalc.updateSize(true, { withoutTimeout: true });

    this.toggleClassesAfterRender();
  }

  handleAction(action) {
    switch (action) {
      case 'clickToLoad':
        this.callbacks.clickToLoad();
        break;

      case 'videoClick':
      case 'slidesClick':
        this.callbacks.togglePlayback();
        break;

      case 'togglePlayback':
        this.callbacks.togglePlayback();
        break;

      case 'toggleMute':
        this.callbacks.toggleMute();
        break;

      case 'prevSlide':
        this.callbacks.prevSlide();
        break;

      case 'nextSlide':
        this.callbacks.nextSlide();
        break;

      case 'toggleSlideMute':
        this.callbacks.toggleSlideMute();
        break;

      case 'syncSlidesToVideo':
        this.callbacks.syncSlidesToVideo();
        break;

      case 'syncVideoToSlides':
        this.callbacks.syncVideoToSlides();
        break;

      case 'toggleFullscreen':
        this.callbacks.toggleFullscreen();
        break;

      case 'seekToLivePosition':
        this.callbacks.seekToLivePosition();
        break;

      case 'hideNextPresentationOverlay':
        this.hideNextPresentationOverlay();
        break;

      case 'playNextPresentation':
        this.callbacks.playNextPresentation();
        break;

      case 'addBookmark':
        this.callbacks.addBookmark();
        break;

      case 'showTopicsAndTranscript':
        this.callbacks.showTopicsAndTranscript();
        break;

      case 'toggleDebugInfo':
        this.html.toggleDebugInfo();
        break;

      case 'syncSlidesVideoStream':
        this.callbacks.syncSlidesVideoStream();
        break;

      default:
        console.warn('SLP:', 'unhandled action', action);
        break;
    }
  }

  handleActionWithParam(action, param) {
    switch (action) {
      case 'volume':
        this.callbacks.setVolume(param);
        break;

      case 'startSeek':
        this.callbacks.startSeek(param);
        break;

      case 'updateSeek':
        this.callbacks.updateSeek(param);
        break;

      case 'endSeek':
        this.callbacks.endSeek(param);
        break;

      case 'setPlaybackRate':
        this.callbacks.setPlaybackRate(param);
        break;

      case 'setVideoQuality':
        this.callbacks.setVideoQuality(param);
        break;

      case 'setPlaybackServer':
        this.callbacks.setPlaybackServer(param);
        break;

      case 'setSubtitleTrack':
        this.callbacks.setSubtitleTrack(param);
        break;

      case 'setLiveSlidesVideoQuality':
        this.callbacks.setLiveSlidesVideoQuality(param);
        break;

      case 'setLiveSlidesPlaybackServer':
        this.callbacks.setLiveSlidesPlaybackServer(param);
        break;

      case 'setMode':
        this.mode = param;
        break;

      case 'setZoom':
        this.sizeCalc.zoom = parseFloat(param);
        break;

      case 'fullscreenChanged':
        this.sizeCalc.fullscreen = param;
        break;

      case 'seekTo':
        this.callbacks.seekTo(parseFloat(param));
        break;

      case 'toggleFavorite':
        this.html.togglePlaylistLoading('favorite', true);
        this.callbacks.togglePlaylist('favorite', param === 'true');
        break;

      case 'toggleWatchLater':
        this.html.togglePlaylistLoading('watch-later', true);
        this.callbacks.togglePlaylist('watch-later', param === 'true');
        break;

      case 'togglePlaylist': {
        const [name, add] = param.split(';');

        this.html.togglePlaylistLoading(name, true);
        this.callbacks.togglePlaylist(name, add === 'true');
        break;
      }
      case 'addPlaylist':
        this.callbacks.addPlaylist({ name: param, custom: true });
        break;

      case 'updateBookmark':
        this.html.toggleBookmarkLoading(param.id, true);
        this.callbacks.updateBookmark(param);
        break;

      case 'removeBookmark':
        this.html.toggleBookmarkLoading(param, true);
        this.callbacks.removeBookmark(param);
        break;

      case 'openReviewNote':
        this.callbacks.openReviewNote(parseInt(param, 10));
        break;

      case 'resolveReviewNote':
        this.callbacks.resolveReviewNote(param);
        break;

      case 'sendReviewNoteComment':
        this.callbacks.sendReviewNoteComment(param);
        break;

      case 'setSubtitlesSize':
        this.html.subtitlesSize = param;
        break;

      default:
        console.warn('SLP:', 'unhandled action with param', action, param);
        break;
    }
  }

  setAvailableSize(height) {
    log('VIEW', 'set available size', height);

    this.sizeCalc.setAvailableSize(height);
  }

  updateTime(currentTime, duration) {
    this.html.updateTime(currentTime, duration);
  }

  updateVolume(volume, muted) {
    this.html.toggleClass('muted', muted);
    this.html.updateVolumeIcon(volume, muted);
    this.html.updateVolumeBar(volume, muted);
  }

  updateSlideMuted(visible, muted) {
    this.html.toggleClass('slideMutable', visible);

    if (visible) {
      this.html.updateSlideMutedTitle(muted);
    }

    this.html.updateSlideMutedIcon(muted);
  }

  updateAvailablePlaybackRates(playbackRates) {
    this.html.updateAvailablePlaybackRates(playbackRates);
  }

  updateAvailableQualities(qualities) {
    this.html.updateAvailableQualities(qualities);
  }

  updateAvailableServers(servers) {
    this.html.updateAvailableServers(servers);
  }

  updateSubtitles(tracks) {
    this.html.updateAvailableSubtitles(tracks);
  }

  renderSubtitlesWord(lines) {
    this.html.renderSubtitlesWord(lines);
  }

  updateAvailableLiveSlideVideoQualities(qualities) {
    this.html.updateAvailableLiveSlideVideoQualities(qualities);
  }

  updateAvailableLiveSlideVideoServers(servers) {
    this.html.updateAvailableLiveSlideVideoServers(servers);
  }

  updateSlide(slideIndex, slideCount, slideData) {
    this.html.updateSlide(slideIndex, slideCount, slideData);
  }

  updatePointer(pointer) {
    this.html.updatePointer(pointer);
  }

  updatePlaylistsList(list, presentationPlaylists) {
    this.html.updatePlaylistsList(list, presentationPlaylists);
  }

  updatePlaylist(name, added) {
    this.html.updatePlaylist(name, added);
    this.html.togglePlaylistLoading(name, false);
  }

  updateBookmarksList(list) {
    this.html.updateBookmarksList(list);
  }

  updateBookmark(data, list) {
    this.html.updateBookmark(data, list);
    this.html.toggleBookmarkLoading(data.id, false);
  }

  removeBookmark(id, list) {
    this.html.removeBookmark(id, list);
  }

  showBookmarksPopup(id) {
    this.html.showBookmarksPopup(id);
  }

  updateReviewNotes(reviewNotes) {
    this.html.updateReviewNotes(reviewNotes);
  }

  openReviewNote(reviewNote) {
    this.html.openReviewNote(reviewNote);
  }

  toggleFullscreen() {
    this.html.toggleFullscreen();
  }

  showNextPresentationOverlay() {
    this.html.showNextPresentationOverlay();
  }

  hideNextPresentationOverlay() {
    this.html.hideNextPresentationOverlay();
  }

  hideOpenedOverlays() {
    this.html.hideOpenedOverlays();
  }

  toggleTopicsAndTranscriptLoading(loading) {
    this.html.toggleTopicsAndTranscriptLoading(loading);
  }

  updateDebugInfo(info) {
    this.html.updateDebugInfo(info);
  }

  updateSize(force, options) {
    this.sizeCalc.updateSize(force, options);
  }

  destroy() {
    if (this.props.slidesInSyncTimeout) {
      clearTimeout(this.props.slidesInSyncTimeout);
    }

    if (this.html) {
      this.html.destroy();
    }
  }

  set thumbnail(url) {
    this.html.thumbnail = url;
  }

  set clickToLoadThumbnail(url) {
    this.html.clickToLoadThumbnail = url;
  }

  set nextPresentationThumbnail(url) {
    this.html.nextPresentationThumbnail = url;
  }

  set realVideoRatio(ratio) {
    if (ratio === null || ratio <= 0) {
      return;
    }

    this.html.realVideoRatio = ratio;
    this.sizeCalc.realVideoRatio = ratio;
  }

  set displayVideoRatio(ratio) {
    if (ratio === null || ratio <= 0) {
      this.html.toggleClass('fakeVideoRatio', false);
      return;
    }

    this.html.toggleClass('fakeVideoRatio', true);

    this.html.displayVideoRatio = ratio;
    this.sizeCalc.displayVideoRatio = ratio;
  }

  set slidesRatio(ratio) {
    if (ratio <= 0) {
      return;
    }

    this.html.slidesRatio = ratio;
    this.sizeCalc.slidesRatio = ratio;
  }

  set loading(loading) {
    this.sizeCalc.loading = loading;
    this.html.toggleClass('loading', loading);
  }

  get mode() {
    return this.props.mode;
  }

  set mode(mode) {
    this.props.mode = mode;
    this.html.mode = mode;
    this.sizeCalc.mode = mode;
  }

  set offline(offline) {
    this.html.toggleClass('offline', offline);
  }

  set guest(guest) {
    this.html.toggleClass('guest', guest);
  }

  set live(live) {
    this.html.toggleClass('live', live);
  }

  set liveFinished(liveFinished) {
    this.html.toggleClass('liveFinished', liveFinished);
  }

  set ended(ended) {
    this.html.toggleClass('ended', ended);
  }

  set firstPlay(firstPlay) {
    this.html.toggleClass('firstPlay', firstPlay);
  }

  set firstPlayHack(firstPlayHack) {
    this.html.toggleClass('firstPlayHack', firstPlayHack);
  }

  set playing(playing) {
    this.html.toggleClass('playing', playing);
    this.html.updatePlayingIcon(playing);
  }

  set paused(paused) {
    this.html.toggleClass('paused', paused);
  }

  set seeking(seeking) {
    this.html.toggleClass('seeking', seeking);
  }

  set videoLoading(loading) {
    this.html.toggleClass('videoLoading', loading);
  }

  set liveSlideVideoLoading(loading) {
    this.html.toggleClass('liveSlideVideoLoading', loading);
  }

  set videoInLivePosition(inLivePosition) {
    this.html.toggleClass('inLivePosition', inLivePosition);
  }

  set slidesLoading(loading) {
    this.html.toggleClass('slidesLoading', loading);
  }

  set slidesInSync(inSync) {
    this.html.toggleClass('slidesNotInSync', !inSync);

    if (inSync) {
      this.html.toggleClass('syncedNow', true);
      this.props.slidesInSyncTimeout = setTimeout(() => {
        this.html.toggleClass('syncedNow', false);
        this.props.slidesInSyncTimeout = null;
      }, 1000);
    }
  }

  set hideSlides(hide) {
    this.html.toggleClass('hideSlides', hide);
    this.sizeCalc.hideSlides = hide;
  }

  get hideSlides() {
    return this.sizeCalc.hideSlides;
  }

  set quality(quality) {
    this.html.quality = quality;
  }

  set playbackRate(playbackRate) {
    this.html.playbackRate = playbackRate;
  }

  set playbackServerIndex(serverIndex) {
    this.html.playbackServerIndex = serverIndex;
  }

  set activeSubtitle(track) {
    this.html.activeSubtitle = track;
  }

  set liveSlideVideoQuality(quality) {
    this.html.liveSlideVideoQuality = quality;
  }

  set liveSlideVideoPlaybackServerIndex(serverIndex) {
    this.html.liveSlideVideoPlaybackServerIndex = serverIndex;
  }

  //

  get videoElement() {
    return this.html.videoContentElement;
  }

  get slidesElement() {
    return this.html.slidesContentElement;
  }

  get size() {
    return this.sizeCalc.size;
  }

  set zoom(ratio) {
    this.sizeCalc.zoom = ratio;
  }

  get zoom() {
    return this.sizeCalc.zoom;
  }

  get fullscreenActive() {
    return this.html.fullscreenActive;
  }

  set embed(value) {
    this.html.toggleClass('embed', value);
  }

  set hideTitle(value) {
    this.html.toggleClass('hideTitle', value);
  }

  set linkifyTitle(value) {
    this.html.linkifyTitle = value;
  }

  set linkifySlidesLiveLogo(value) {
    this.html.linkifySlidesLiveLogo = value;
  }

  set title(title) {
    this.html.title = title;
  }

  set link(link) {
    this.html.link = link;
  }

  set nextPresentationTitle(title) {
    this.html.nextPresentationTitle = title;
  }

  set notRecorded(notRecorded) {
    this.sizeCalc.message = notRecorded;
    this.sizeCalc.mode = notRecorded ? 'video' : this.props.mode;

    this.html.toggleClass('notRecorded', notRecorded);

    this.sizeCalc.updateSize(true);
  }

  set livestreamStarted(started) {
    this.sizeCalc.message = !started;
    this.sizeCalc.mode = started ? this.props.mode : 'video';

    this.html.toggleClass('liveStarted', started);
    this.html.toggleClass('liveNotStarted', !started);

    this.sizeCalc.updateSize(true);
  }

  set livestreamTerminated(finished) {
    this.sizeCalc.message = finished;

    this.html.toggleClass('liveTerminated', finished);

    this.sizeCalc.updateSize(true);
  }

  set topicsAndTranscriptAvailable(available) {
    this.html.toggleClass('topicsAndTranscriptAvailable', available);
  }

  set seekable(value) {
    this.html.toggleClass('notSeekable', !value);
  }

  set slidesLiveLogoVisible(visible) {
    this.html.toggleClass('slidesLiveLogo', visible);
  }

  set slidesVideoStreamDiff(diff) {
    this.html.slidesVideoStreamDiff = diff;
  }

  disableFullscreen() {
    this.html.disableFullscreen();
  }

  set reportIssueUrl(url) {
    this.html.reportIssueUrl = url;
  }
}
